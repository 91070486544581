import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import RestaurantCard from "../Restaurant/RestaurantCard";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";
import API_BASE_URL from "../../apiConfig";
import { calculateAverageRating } from "../Restaurant/calculateAverageRating";
import RestaurantCardSkeleton from "../../common-components/skeleton-loader/RestaurantSkeletonLoader";

const Home = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [restaurants, setRestaurants] = useState([]);
  const [showLoader, isShowLoader] = useState(false);
  const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";
  useEffect(() => {
    const fetchRestaurants = async () => {
      try {
        isShowLoader(true);
        const response = await fetch(
          `${API_BASE_URL}/restaurant/allRestaurants`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          const approvedRestaurants = data.filter(
            (restaurant) => restaurant.status === "Approved"
          );

          // Check for cuisine data for each restaurant
          const restaurantsWithCuisine = [];
          for (const restaurant of approvedRestaurants) {
            const cuisinesResponse = await fetch(
              `${API_BASE_URL}/cuisine/allCuisines/${restaurant.id}`,
              {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                },
              }
            );

            if (cuisinesResponse.ok) {
              const cuisineData = await cuisinesResponse.json();
              if (cuisineData.length > 0) {
                restaurantsWithCuisine.push(restaurant); // Only push restaurants that have cuisine data
              }
            } else {
              console.warn(
                `No cuisine data for restaurant ID: ${restaurant.id}`
              );
            }
          }

          // Fetch ratings for restaurants with cuisine
          const restaurantsWithRatings = await Promise.all(
            restaurantsWithCuisine.map(async (restaurant) => {
              const rating = await calculateAverageRating(restaurant.id);
              return {
                ...restaurant,
                averageRating: rating,
              };
            })
          );

          // Commenting out the filtering of null/undefined ratings
          // const filteredRestaurants = restaurantsWithRatings.filter(
          //   (restaurant) =>
          //     restaurant.averageRating !== null &&
          //     restaurant.averageRating !== undefined
          // );
          // Sort by rating (highest first)
          // filteredRestaurants.sort(
          //   (a, b) => (b.averageRating || 0) - (a.averageRating || 0)
          // );

          // Sort by rating (highest first)
          restaurantsWithRatings.sort(
            (a, b) => (b.averageRating || 0) - (a.averageRating || 0)
          );

          setRestaurants(restaurantsWithRatings.slice(0, 12)); // Display top 12 restaurants
        } else {
          console.error("Failed to fetch restaurants");
        }
      } catch (error) {
        console.error("Error fetching restaurants:", error);
      } finally {
        isShowLoader(false);
      }
    };

    fetchRestaurants();
  }, []);

  useEffect(() => {
    localStorage.removeItem("searchTerm"); // Clear search term when Home page mounts
  }, []);

  const handleSearchClick = () => {
    localStorage.setItem("searchTerm", searchTerm); // Store the new search term in local storage
    navigate("/restaurants"); // Navigate to AllRestaurantsPage
  };

  const handleRestaurantClick = (restaurant) => {
    if (restaurant.openOrClosed === true) {
      navigate(`/restaurant/${restaurant.name}/${restaurant.id}`);
    }
  };

  const cuisines = [
    {
      title: "Pakistani",
      image:
        "https://cdn.pixabay.com/photo/2024/10/06/19/35/asian-cuisine-9101015_640.jpg",
    },
    {
      title: "Italian",
      image:
        "https://cdn.pixabay.com/photo/2023/06/02/19/33/ai-generated-8036273_640.png",
    },

    {
      title: "Bengali",
      image:
        "https://cdn.pixabay.com/photo/2021/01/22/19/48/chicken-5940975_640.jpg",
    },
    {
      title: "Indian",
      image:
        "https://cdn.pixabay.com/photo/2024/11/08/09/42/spaghetti-9182962_640.jpg",
    },
    {
      title: "Mexican",
      image:
        "https://cdn.pixabay.com/photo/2019/07/30/00/59/salmon-dish-4371734_640.jpg",
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 600,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };
  //https://img.freepik.com/free-photo/wooden-table-outside-night-lit-up-by-background-lights_91128-3691.jpg?ga=GA1.1.512599471.1716211118&semt=ais_hybrid

  //https://media.istockphoto.com/id/914940152/photo/empty-wooden-table-top-with-blur-coffee-shop-or-restaurant-interior-background-panoramic-banner.jpg?s=612x612&w=0&k=20&c=mpoF5abC7ys4sTIYUuCemxp3MYFgvuRFVPGzNF8IKTI=

  return (
    <div className='w-full min-h-screen bg-gray-50 overflow-x-hidden'>
      {/* Announcement section */}

      {!isLoggedIn && (
        <div className='bg-gradient-to-r from-blue-900  to-purple-900 px-6 py-3 text-gray-100 shadow-md flex items-center justify-between mt-[90px]'>
          <p className='text-lg font-semibold text-center flex-1'>
            Are you a home chef or a foodie craving something new?
            <a
              href='/register'
              className='text-yellow-400 hover:underline font-bold mx-1 hover:text-yellow-500'
            >
              Join Restoura
            </a>
            today as a
            <a
              href='/register'
              className='text-yellow-400 hover:underline font-bold mx-1 hover:text-yellow-500'
            >
              Vendor
            </a>
            or
            <a
              href='/register'
              className='text-yellow-400 hover:underline font-bold mx-1 hover:text-yellow-500'
            >
              Customer
            </a>
          </p>
        </div>
      )}

      <section
        className="relative bg-[url('https://img.freepik.com/free-photo/wooden-table-outside-night-lit-up-by-background-lights_91128-3691.jpg?ga=GA1.1.512599471.1716211118&semt=ais_hybrid')] 
      bg-cover bg-center bg-no-repeat h-[80vh] flex items-center justify-center"
      >
        <div className='absolute inset-0 bg-black opacity-50'></div>
        {/* Dark overlay */}
        <div className='relative max-w-screen-xl px-4 pt-16 pb-6 sm:px-6 lg:px-16 text-white text-left'>
          {/* Heading */}
          <h1 className='text-4xl font-extrabold text-[#FFD700] sm:text-4xl lg:text-6xl leading-tight lg:leading-snug mb-8'>
            <span className='block mb-1'>Order Your</span>{" "}
            {/* Move "Order Your" slightly above */}
            <strong className='block font-extrabold text-[#FF6347]'>
              Favorite Homemade Food
            </strong>
          </h1>

          {/* Description Text */}
          <p className='mt-6 mb-4 max-w-lg text-lg sm:text-xl text-yellow-50'>
            At Restoura, we bring you the best homemade food from local chefs
            right to your doorstep.
          </p>

          {/* Search Section */}
          <div className='mt-5 flex flex-col sm:flex-row gap-4 items-start'>
            <input
              type='text'
              placeholder='Search your nearby restaurants'
              className='w-full sm:w-80 rounded-md bg-white px-6 py-4 text-sm font-medium text-gray-700 shadow-md focus:outline-none focus:ring-2 focus:ring-rose-500 transition duration-300'
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <button
              onClick={handleSearchClick}
              className='w-full sm:w-auto rounded-md bg-rose-600 px-16 py-4 text-sm font-medium text-white shadow-md hover:bg-rose-700 transition duration-300 transform hover:scale-105'
            >
              Search
            </button>
          </div>
        </div>
      </section>

      {/* Top Cuisines Section */}
      <section className='px-10 py-10 bg-gray-100'>
        <Slider {...settings}>
          {cuisines.map((cuisine, index) => (
            <div key={index} className='px-4'>
              <div className='relative group cursor-pointer transition duration-300 transform hover:scale-105'>
                <img
                  src={cuisine.image}
                  alt={cuisine.title}
                  className='rounded-lg shadow-lg h-64 w-full object-cover'
                />
              </div>
            </div>
          ))}
        </Slider>
      </section>

      {/* Handpicked Restaurants Section */}
      <section className='py-8 px-4 bg-white'>
        <div className='flex justify-between items-center mb-10'>
          <h2 className='text-3xl font-bold text-gray-800'>
            Top Handpicked Restaurants
          </h2>
          <div className='flex items-center'>
            <a
              href='/restaurants' // Link to the restaurants page
              className='group inline-block rounded-md bg-gradient-to-r from-cyan-500 via-teal-500 to-blue-500 p-[2px] hover:text-white focus:outline-none focus:ring active:text-opacity-75'
            >
              <span className='block rounded-md bg-white px-4 py-1 text-lg font-medium group-hover:bg-transparent'>
                Explore
              </span>
            </a>
          </div>
        </div>

        {showLoader ? (
          // Display skeleton loader while loading
          <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-10'>
            {Array.from({ length: 6 }).map((_, index) => (
              <RestaurantCardSkeleton key={index} />
            ))}
          </div>
        ) : restaurants.length > 0 ? (
          // Display restaurants when data is available
          <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-10'>
            {restaurants.map((restaurant, index) => (
              <div
                key={index}
                onClick={() => handleRestaurantClick(restaurant)}
                className={`cursor-pointer ${
                  !restaurant.openOrClosed
                    ? "pointer-events-none opacity-60"
                    : ""
                } transition duration-300 transform hover:scale-105`}
              >
                <RestaurantCard
                  restaurant={{
                    id: restaurant.id,
                    name: restaurant.name,
                    openOrClosed: restaurant.openOrClosed,
                    operatingHours: restaurant.operatingHours,
                    cuisineType: restaurant.cuisineType,
                    location: {
                      addressLine1: restaurant.addressLine1,
                      city: restaurant.city,
                      state: restaurant.state,
                    },
                  }}
                />
              </div>
            ))}
          </div>
        ) : (
          // Display message when no restaurants are found
          <div className='text-center text-lg font-medium text-gray-500'>
            <p>
              "Craving something delicious? Order from your favorite restaurant
              and leave a review to see it featured here."
            </p>
            <p>Start exploring and share your dining experience!</p>
          </div>
        )}
      </section>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default Home;

