import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import API_BASE_URL from "../../apiConfig";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const AdminLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const ALLOWED_EMAIL = "admin@restoura.com";
  const handleLogin = async (e) => {
    e.preventDefault();

    if (email === "" || password === "") {
      setError("Please fill in all fields.");
      return;
    }
    if (email !== ALLOWED_EMAIL) {
      setError("You are not authorized to log in!");
      return;
    }

    setIsLoading(true);

    const requestBody = {
      email: email,
      password: password,
      role: "customer",
    };

    try {
      const response = await fetch(`${API_BASE_URL}/auth/signin`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      const responseData = await response.json();

      if (!response.ok) {
        throw new Error(responseData.message || "Login failed");
      }

      const decodedToken = jwtDecode(responseData.refreshToken);
      const id = decodedToken.userId;
      const userEmail = decodedToken.sub;

      localStorage.setItem("isLoggedIn", true);
      localStorage.setItem("token", responseData.refreshToken);
      localStorage.setItem("email", userEmail);
      localStorage.setItem("userId", id);
      localStorage.setItem("role", "admin"); // Fixed as admin

      navigate(`/admin-profile/admin-approval`);

      window.location.reload();
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className='flex items-center justify-center bg-gray-900 py-10 min-h-screen'>
      <div className='bg-gray-800 p-8 rounded-lg shadow-lg w-full max-w-md'>
        <h2 className='text-2xl font-semibold text-center text-white mb-6'>
          Admin Login
        </h2>
        <form onSubmit={handleLogin} className='space-y-6'>
          {error && <p className='text-red-500'>{error}</p>}
          <div className='space-y-1'>
            <label className='block text-sm font-medium text-gray-300'>
              Email
            </label>
            <input
              type='email'
              className='w-full p-2 border border-gray-600 rounded-lg focus:outline-none focus:border-indigo-500 bg-gray-700 text-white'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className='space-y-1'>
            <label className='block text-sm font-medium text-gray-300'>
              Password
            </label>
            <div className='relative'>
              <input
                id='password'
                type={showPassword ? "text" : "password"}
                className='w-full p-2 border border-gray-600 rounded-lg focus:outline-none focus:border-indigo-500 bg-gray-700 text-white'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <span
                className='absolute inset-y-0 right-0 flex items-center pr-2 cursor-pointer text-white'
                onClick={togglePasswordVisibility}
              >
                <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
              </span>
            </div>
          </div>
          {/* <div className="space-y-1">
                        <label className="block text-sm font-medium text-gray-300">Role</label>
                        <input
                            type="text"
                            value="Admin"
                            readOnly
                            className="w-full p-2 border border-gray-600 rounded-lg bg-gray-700 text-white focus:outline-none cursor-not-allowed"
                        />
                    </div> */}

          <button
            type='submit'
            className={`w-full bg-indigo-600 text-white py-2 rounded-lg transition duration-300 ${
              isLoading
                ? "cursor-not-allowed opacity-50"
                : "hover:bg-indigo-700"
            }`}
            disabled={isLoading}
          >
            {isLoading ? "Logging in..." : "Login"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default AdminLogin;

