import React, { useState, useEffect } from "react";
import { FaStar, FaPen, FaEye } from "react-icons/fa";
import API_BASE_URL from "../../apiConfig";
import useUtilService from "../../services/useUtilService";

const OrdersHistory = () => {
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [orderToCancel, setOrderToCancel] = useState(null);
  const [rating, setRating] = useState(0);
  const [feedback, setFeedback] = useState("");
  const [statusFilter, setStatusFilter] = useState("All");
  const [showExistingReviewModal, setShowExistingReviewModal] = useState(false);
  const [existingReview, setExistingReview] = useState(null);
  const { showSuccessMessage, showErrorMessage } = useUtilService();

  const customerId = localStorage.getItem("userId");
  const token = localStorage.getItem("token");

  useEffect(() => {
    fetchOrders();
  }, []);

  const formatPickupTime = (pickupTime) => {
    const date = new Date(pickupTime);

    // Format date as YYYY-MM-DD
    const formattedDate = date.toLocaleDateString("en-CA"); // Canadian format for ISO-like YYYY-MM-DD

    // Format time as hh:mm AM/PM
    const options = { hour: "2-digit", minute: "2-digit", hour12: true };
    const formattedTime = date.toLocaleTimeString("en-US", options);

    return `${formattedDate}, ${formattedTime}`;
  };

  const fetchOrders = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/order/getAllOrders`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();

        // Filter orders by customerId and paymentStatus
        const filteredOrders = data
          .filter(
            (order) =>
              order.customerId === parseInt(customerId) &&
              order.paymentStatus === "Payment Received"
          )
          .map((order) => ({
            ...order,
            pickupTime: formatPickupTime(order.pickupTime), // Format the pickup time
          }));

        setOrders(filteredOrders);
      } else {
        console.error("Failed to fetch orders");
      }
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  const handleCancelOrder = async (orderId) => {
    try {
      const updateResponse = await fetch(
        `${API_BASE_URL}/order/updateOrderStatus/${orderId}?status=Canceled:Customer`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (updateResponse.ok) {
        setOrders(
          orders.map((o) =>
            o.orderId === orderId
              ? { ...o, orderStatus: "Canceled:Customer" }
              : o
          )
        );
        setShowCancelModal(false);
      } else {
        console.error("Failed to update order status");
      }
    } catch (error) {
      console.error("Error canceling order:", error);
    }
  };

  const handleReviewClick = (order) => {
    setSelectedOrder(order);
    setShowReviewModal(true);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "Pending":
        return "bg-yellow-500 text-white";
      case "Payment Received":
        return "bg-cyan-500 text-black";
      case "Order Placed":
        return "bg-green-500 text-white";
      case "Canceled:Customer":
        return "bg-red-500 text-white";
      case "Delivered":
        return "bg-blue-500 text-white";
      default:
        return "bg-gray-500 text-white";
    }
  };

  //fetch restaurant details using the order
  const handleOrderClick = async (order) => {
    setSelectedOrder(order); // Set the basic order details first
    setShowModal(true); // Open the modal

    try {
      const response = await fetch(
        `${API_BASE_URL}/restaurant/getRestaurant?restaurantId=${order.restaurantId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const restaurantData = await response.json();

        // Update selectedOrder with both restaurant name and pickup address
        setSelectedOrder({
          ...order,
          restaurantName: restaurantData.name,
          addressLine1: restaurantData.addressLine1,
          addressLine2: restaurantData.addressLine2,
          city: restaurantData.city,
          state: restaurantData.state,
          postalCode: restaurantData.postalCode,
          country: restaurantData.country,
          contactNo: restaurantData.contactNo,
          email: restaurantData.email,
        });
      } else {
        console.error("Failed to fetch restaurant details");
      }
    } catch (error) {
      console.error("Error fetching restaurant:", error);
    }
  };

  const fetchExistingReview = async (orderId) => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/order/getOrderById/${orderId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setExistingReview({
          rating: data.orderRating,
          text: data.orderReview,
        });
        setShowExistingReviewModal(true);
      } else {
        console.error("Failed to fetch existing review");
      }
    } catch (error) {
      console.error("Error fetching existing review:", error);
    }
  };

  //submit customer feedback
  const submitReview = async () => {
    try {
      const ratingDto = {
        orderRating: rating,
        orderReview: feedback,
      };

      const response = await fetch(
        `${API_BASE_URL}/order/updateRating/${selectedOrder.orderId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(ratingDto),
        }
      );

      if (response.ok) {
        showSuccessMessage("Thank you for your feedback!");
        setShowReviewModal(false);
        setRating(0); // Reset rating
        setFeedback(""); // Reset feedback
      } else {
        console.error("Failed to submit rating and review");
      }
    } catch (error) {
      console.error("Error submitting rating and review:", error);
    }
  };

  const filteredOrders =
    statusFilter === "All"
      ? orders
      : orders.filter((order) => order.orderStatus === statusFilter);

  return (
    <div className='flex flex-col flex-1 p-8 bg-white shadow-lg rounded-lg '>
      <div className='bg-gradient-to-r from-gray-700 via-gray-800 to-gray-900 py-6 shadow-lg rounded-t-lg'>
        <h2 className='text-3xl font-bold text-white tracking-wide mb-2 text-center'>
          Orders & History
        </h2>
        <p className='text-gray-300 text-center text-sm'>
          Track your past and current orders with ease
        </p>
      </div>

      <div className='flex justify-between items-center mt-6 mb-2'>
        <h3 className='text-lg font-semibold'>Filter by Order Status:</h3>
        <select
          value={statusFilter}
          onChange={(e) => setStatusFilter(e.target.value)}
          className='px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500'
        >
          <option value='All'>All</option>
          <option value='Pending'>Pending</option>
          <option value='Order Placed'>Order Placed</option>
          <option value='Canceled:Customer'>Canceled</option>
          <option value='Delivered'>Delivered</option>
        </select>
      </div>

      <div className='mt-2'></div>
      <p className='text-lg text-center font-semibold text-gray-700 bg-blue-100 border-l-4 border-blue-500 pl-3 py-2 rounded-md mb-4 shadow-sm'>
        Click on the rows to view order details.
      </p>

      {/* Order History Table */}
      <div className='overflow-x-auto'>
        <table className='min-w-full bg-white border border-gray-200 rounded-lg overflow-hidden'>
          <thead className='bg-gray-100'>
            <tr className='text-left'>
              <th className='py-3 px-4 border-b'>Orders</th>
              <th className='py-3 px-4 border-b'>Restaurant</th>
              <th className='py-3 px-4 border-b'>Date</th>
              <th className='py-3 px-4 border-b'>Time</th>
              <th className='py-3 px-4 border-b'>Order Status</th>
              <th className='py-3 px-4 border-b'>Payment Status</th>
              <th className='py-3 px-4 border-b'>Pickup time(Estimated) </th>
              <th className='py-3 px-4 border-b'>Actions</th>
              <th className='py-3 px-4 border-b'>Give Review</th>
            </tr>
          </thead>
          <tbody>
            {filteredOrders.map((order, index) => (
              <tr
                key={index}
                className='hover:bg-gray-50 cursor-pointer'
                onClick={() => handleOrderClick(order)}
              >
                <td className='py-4 px-4 border-b'>
                  {order.foodItemList.length > 0
                    ? order.foodItemList.map((item, index) => (
                        <span key={index}>
                          {item.cuisineName}
                          {index < order.foodItemList.length - 1 && ", "}
                        </span>
                      ))
                    : "N/A"}
                </td>

                <td className='py-4 px-4 border-b'>{order.restaurantName}</td>
                <td className='py-4 px-4 border-b'>{order.orderDate}</td>
                <td className='py-4 px-4 border-b'>{order.timeOfOrder}</td>
                <td className='py-4 px-4 border-b'>
                  <span
                    className={`px-3 py-1 text-sm font-medium rounded ${getStatusColor(
                      order.orderStatus
                    )}`}
                  >
                    {order.orderStatus}
                  </span>
                </td>
                <td className='py-4 px-4 border-b'>
                  {order.paymentStatus === "Payment Received" ? (
                    <span className='text-green-500 font-semibold'>Paid</span>
                  ) : (
                    <span className='text-red-500 font-semibold'>Not Paid</span>
                  )}
                </td>
                <td className='py-4 px-4 border-b'>{order.pickupTime}</td>
                <td className='py-4 px-4 border-b'>
                  {order.orderStatus === "Pending" ? (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        setOrderToCancel(order.orderId);
                        setShowCancelModal(true);
                      }}
                      className='px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition'
                    >
                      Cancel
                    </button>
                  ) : (
                    <button
                      disabled
                      className='px-4 py-2 bg-gray-300 text-gray-500 rounded-lg cursor-not-allowed'
                    >
                      Cancel
                    </button>
                  )}
                </td>
                <td className='py-4 px-4 border-b'>
                  {order.orderStatus === "Delivered" ? (
                    order.orderReview ? ( // Check if orderReview exists
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          fetchExistingReview(order.orderId);
                        }}
                        className='p-2 rounded-full bg-green-500 text-white hover:bg-green-600'
                      >
                        <FaEye />
                      </button>
                    ) : (
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleReviewClick(order);
                        }}
                        className={`p-2 rounded-full bg-blue-500 text-white hover:bg-blue-600 transition`}
                      >
                        <FaPen />
                      </button>
                    )
                  ) : (
                    <button
                      disabled
                      className='p-2 rounded-full bg-gray-300 text-gray-500 cursor-not-allowed'
                    >
                      <FaPen />
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Order Details Modal */}
      {showModal && selectedOrder && (
        <div className='fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50'>
          <div className='bg-white w-full max-w-md sm:max-w-lg max-h-[80vh] overflow-y-auto p-6 rounded-lg shadow-lg'>
            <div className='flex justify-between items-center mb-4'>
              <h3 className='text-2xl font-semibold text-gray-800'>
                Order Details
              </h3>
              <button
                onClick={() => setShowModal(false)}
                className='text-gray-400 hover:text-gray-600 transition'
              >
                <svg
                  className='w-6 h-6'
                  fill='none'
                  stroke='currentColor'
                  viewBox='0 0 24 24'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    d='M6 18L18 6M6 6l12 12'
                  ></path>
                </svg>
              </button>
            </div>

            {/* Status Section */}
            <div className='mb-4 p-4 bg-gray-50 rounded-lg border-l-4 border-blue-500'>
              <div className='flex justify-between items-center'>
                <span className='font-semibold text-gray-600'>
                  Order Status:
                </span>
                <span
                  className={`px-2 py-1 rounded text-sm font-medium ${
                    selectedOrder.orderStatus === "Pending"
                      ? "bg-yellow-200 text-yellow-800"
                      : selectedOrder.orderStatus === "Order Placed"
                      ? "bg-green-200 text-green-800"
                      : selectedOrder.orderStatus === "Delivered"
                      ? "bg-blue-200 text-blue-800"
                      : "bg-red-200 text-red-800"
                  }`}
                >
                  {selectedOrder.orderStatus}
                </span>
              </div>
              <div className='flex justify-between items-center mt-2'>
                <span className='font-semibold text-gray-600'>
                  Payment Status:
                </span>
                <span
                  className={`px-2 py-1 rounded text-sm font-medium ${
                    selectedOrder.paymentStatus === "Payment Received"
                      ? "bg-green-200 text-green-800"
                      : "bg-yellow-200 text-yellow-800"
                  }`}
                >
                  {selectedOrder.paymentStatus === "Payment Received"
                    ? "Paid"
                    : "Not Paid"}
                </span>
              </div>
            </div>
            {/* Contact Section */}
            <div className='mb-4 p-4 bg-blue-50 rounded-lg'>
              <h4 className='font-semibold text-lg text-gray-800 mb-2'>
                Contact Information
              </h4>
              <p className='text-gray-600'>
                <span className='block'>
                  Phone Number: {selectedOrder.contactNo || "N/A"}
                </span>
                <span className='block'>
                  Email: {selectedOrder.email || "N/A"}
                </span>
                <span className='block'>
                  Restaurant: {selectedOrder.restaurantName || "N/A"}
                </span>
              </p>
            </div>

            {/* Pickup Address Section */}
            <div className='mb-4 p-4 bg-blue-50 rounded-lg'>
              <h4 className='font-semibold text-lg text-gray-800 mb-2'>
                Pickup Address
              </h4>
              <p className='text-gray-600'>
                {" "}
                {`${selectedOrder.addressLine1 || ""}, ${
                  selectedOrder.addressLine2 || ""
                }, 
          ${selectedOrder.city || ""}, ${selectedOrder.state || ""}, 
          ${selectedOrder.postalCode || ""}, ${selectedOrder.country || ""}`}
              </p>
            </div>

            {/* Food Items Section */}
            <div className='mb-4 p-4 bg-green-50 rounded-lg'>
              <h4 className='font-semibold text-lg text-gray-800 mb-2'>
                Food Items
              </h4>
              <ul className='space-y-2'>
                {selectedOrder.foodItemList.map((item, index) => (
                  <li
                    key={index}
                    className='flex justify-between text-gray-600'
                  >
                    <span>
                      {item.cuisineName} (Qty: {item.quantity})
                    </span>
                    <span>${item.cuisinePrice}</span>
                  </li>
                ))}
              </ul>
            </div>

            {/* Additional Fields */}
            <div className='p-4 bg-yellow-50 rounded-lg'>
              <h4 className='font-semibold text-lg text-gray-800 mb-2'>
                Additional Details
              </h4>
              <div className='space-y-2 text-gray-600'>
                <div className='flex justify-between'>
                  <span className='font-semibold'>Estimated Pickup Time:</span>
                  <span>{selectedOrder.pickupTime}</span>
                </div>
                <div className='flex justify-between'>
                  <span className='font-semibold'>Order Date</span>
                  <span>{selectedOrder.orderDate}</span>
                </div>
                <div className='flex justify-between'>
                  <span className='font-semibold'>Time of Order</span>
                  <span>{selectedOrder.timeOfOrder}</span>
                </div>
                <div className='flex justify-between'>
                  <span className='font-semibold'>Total Price:</span>
                  <span>${selectedOrder.totalAmount}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Cancel Order Modal */}
      {showCancelModal && (
        <div className='fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50'>
          <div className='bg-white w-full max-w-md p-6 rounded-lg shadow-lg'>
            <h3 className='text-2xl font-semibold text-gray-800 mb-4'>
              Confirm Cancellation
            </h3>
            <p className='text-gray-600 mb-4'>
              Are you sure you want to cancel this order?
            </p>
            <div className='flex justify-end space-x-4'>
              <button
                onClick={() => setShowCancelModal(false)}
                className='px-4 py-2 bg-gray-300 text-gray-700 rounded-lg hover:bg-gray-400 transition'
              >
                No
              </button>
              <button
                onClick={() => handleCancelOrder(orderToCancel)}
                className='px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition'
              >
                Yes, Cancel Order
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Review Modal */}
      {showReviewModal && (
        <div className='fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50'>
          <div className='bg-white w-full max-w-md p-6 rounded-lg shadow-lg'>
            {/* Centered Title */}
            <h3 className='text-2xl font-semibold text-gray-800 mb-6 text-center'>
              Give Review
            </h3>

            {/* Star Rating with Text Feedback */}
            <div className='flex items-center justify-center space-x-2 mb-4'>
              {[...Array(5)].map((_, index) => (
                <FaStar
                  key={index}
                  className={`cursor-pointer ${
                    rating >= index + 1 ? "text-yellow-500" : "text-gray-300"
                  }`}
                  onClick={() => setRating(index + 1)}
                />
              ))}
              <span className='text-gray-700 text-sm font-medium'>
                {rating === 1
                  ? "Poor"
                  : rating === 2
                  ? "Fair"
                  : rating === 3
                  ? "Good"
                  : rating === 4
                  ? "Very Good"
                  : rating === 5
                  ? "Excellent"
                  : ""}
              </span>
            </div>

            {/* Feedback Textarea */}
            <label className='block text-gray-700 font-medium mb-1'>
              Feedback
            </label>
            <textarea
              placeholder='We Value Your Opinion – Share Your Experience!'
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
              className='w-full p-3 border border-gray-300 rounded-lg mb-6 focus:outline-none focus:ring-2 focus:ring-blue-500'
            />

            {/* Submit Button */}
            <div className='flex justify-end space-x-4'>
              <button
                onClick={() => setShowReviewModal(false)}
                className='px-4 py-2 bg-gray-300 text-gray-700 rounded-lg hover:bg-gray-400 transition'
              >
                Cancel
              </button>
              <button
                onClick={submitReview}
                className='px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition'
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}

      {showExistingReviewModal && existingReview && (
        <div className='fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50'>
          <div className='bg-white w-full max-w-md p-6 rounded-lg shadow-lg'>
            <h3 className='text-2xl font-semibold text-gray-800 mb-6'>
              Existing Review
            </h3>
            <div className='mb-4'>
              <span className='font-semibold'>Rating:</span>{" "}
              {existingReview.rating} {/* Display integer rating */}
            </div>
            <div className='mb-4'>
              <span className='font-semibold'>Review:</span>{" "}
              {existingReview.text} {/* Display review text */}
            </div>
            <div className='flex justify-end'>
              <button
                onClick={() => setShowExistingReviewModal(false)}
                className='px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition'
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrdersHistory;

