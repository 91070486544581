import React from "react";
import { Routes, Route } from "react-router-dom";
import ProfileNavigation from "./ProfileNavigation";
import MyProfile from "./MyProfile";
import { faUser } from "@fortawesome/free-solid-svg-icons";

const userId = localStorage.getItem("userId");

const vendor_menu = [
  {
    title: "My Profile",
    icon: faUser,
    path: `/vendor-profile/my-profile/${userId}`,
  },
];

const VendorProfile = () => {
  return (
    <div className='flex flex-col md:flex-row mt-20'>
      {/* Responsive Navigation */}
      <ProfileNavigation menu={vendor_menu} />

      {/* Main Content Area */}
      <div className='flex-1 p-4 md:p-8'>
        <Routes>
          <Route path='my-profile/:id' element={<MyProfile />} />
        </Routes>
      </div>
    </div>
  );
};

export default VendorProfile;

