import { useContext } from "react";
import { ApplicationContext } from "../context/ApplicationContext";


const useUtilService = () => {

    const { dispatch } = useContext(ApplicationContext);

    const showSuccessMessage = (value) => {
        dispatch({
            type: "SET_ALERT",
            payload: { msg: value, type: 'success' },
        });
    };

    const showErrorMessage = (value) => {
        dispatch({
            type: "SET_ALERT",
            payload: { msg: value, type: 'error' },
        });
    };

    return { showSuccessMessage, showErrorMessage };


};

export default useUtilService;