import React from "react";

const BecomeVendor = () => {
  return (
    <div className='mt-20 bg-gray-50'>
      {/* Hero Section */}
      <div
        className='relative bg-cover bg-center text-white px-6 sm:px-12 py-16'
        style={{
          backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.2)), url('https://cdn.pixabay.com/photo/2021/09/20/06/55/spaghetti-6639970_1280.jpg')`,
        }}
      >
        <div className='max-w-6xl mx-auto flex flex-col md:flex-row items-center'>
          <div className='text-center md:text-left'>
            <h1 className='text-4xl sm:text-5xl font-extrabold leading-tight mb-4'>
              Ready to Start Your Online Restaurant Business?
            </h1>
            <p className='text-lg sm:text-xl mb-6'>
              Transform your passion for cooking into a thriving business by
              sharing your cuisines with customers everywhere.
            </p>
            <a
              href='/register'
              className='inline-block px-8 py-4 bg-white text-teal-600 font-bold rounded-lg shadow-lg hover:bg-gray-100 transition duration-300'
            >
              Become a Vendor
            </a>
          </div>
        </div>
      </div>

      {/* Step-by-Step Details */}
      <div className='max-w-7xl mx-auto px-6 py-16 bg-gradient-to-br from-gray-50 to-teal-50'>
        <h2 className='text-3xl font-extrabold text-gray-800 text-center mb-8'>
          Get Started as a Vendor Today
        </h2>
        <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6'>
          {[
            {
              title: "1. Sign Up as a Vendor",
              icon: "🖊️",
              description: (
                <>
                  Create a vendor account by providing a valid email address,
                  phone number, and bank account details for direct deposit
                  payments. Sign up is quick and easy —
                  <a
                    href='/register'
                    className='text-teal-600 font-bold underline'
                  >
                    Sign Up Here
                  </a>
                  !
                </>
              ),
            },
            {
              title: "2. Choose Your Business Model",
              icon: "📊",
              description: (
                <>
                  Decide how to operate. Consider:
                  <ul className='list-disc list-inside'>
                    <li>Your working hours (weekdays, weekends).</li>
                    <li>The type of food you’ll serve.</li>
                    <li>
                      Grouping similar cuisines under one restaurant or creating
                      distinct brands.
                    </li>
                  </ul>
                </>
              ),
            },
            {
              title: "3. Create Your Restaurant & Kitchen Inspection",
              icon: "🍴",
              description: (
                <>
                  Create your restaurant(s) on the platform. Our admin team will
                  review your submission, approve it, and schedule a kitchen
                  inspection either in person or via video call.
                </>
              ),
            },

            {
              title: "4. Build Your Menu",
              icon: "📋",
              description: (
                <>
                  Add dishes with:
                  <ul className='list-disc list-inside'>
                    <li>Clear, original photos (avoid stock images).</li>
                    <li>Accurate and appealing descriptions.</li>
                  </ul>
                  A professional menu helps build trust and attracts customers.
                </>
              ),
            },
            {
              title: "5. Start Operating Your Business",
              icon: "🚀",
              description: (
                <>
                  Your restaurant goes live once approved. Respond to orders
                  promptly (within 2 minutes) to avoid cancellation. Keep your
                  menu updated and ensure unavailable items are removed.
                </>
              ),
            },
            {
              title: "6. Receive Payments",
              icon: "💵",
              description: (
                <>
                  Payments are processed weekly via direct deposit. For issues,
                  contact us at{" "}
                  <a
                    href='mailto:admin@restoura.com'
                    className='text-teal-600 font-bold underline'
                  >
                    admin@restoura.com
                  </a>
                  .
                </>
              ),
            },
            {
              title: "7. Contact Support",
              icon: "📞",
              description: (
                <>
                  For questions or assistance, reach out to our support team at{" "}
                  <a
                    href='mailto:support@restoura.com'
                    className='text-teal-600 font-bold underline'
                  >
                    support@restoura.com
                  </a>
                  .
                </>
              ),
            },
          ].map((step, index) => (
            <div
              key={index}
              className='p-6 bg-white shadow-lg rounded-lg hover:shadow-xl transition duration-300'
            >
              <div className='flex items-center mb-4'>
                <div className='text-4xl mr-4 text-teal-500'>{step.icon}</div>
                <h3 className='text-xl font-bold text-gray-800'>
                  {step.title}
                </h3>
              </div>
              <p className='text-gray-600 leading-relaxed'>
                {step.description}
              </p>
            </div>
          ))}
        </div>
      </div>

      {/* Vendor Benefits */}
      <div className='bg-gradient-to-b from-red-50 to-white py-12'>
        <h2 className='text-4xl font-extrabold text-center mb-8 text-gray-800'>
          Why Join Restoura?
        </h2>
        <div className='max-w-6xl mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6'>
          {[
            {
              title: "Expand Your Reach",
              description: "Grow your audience beyond your local neighborhood.",
              icon: "🌍",
            },
            {
              title: "Flexible Operations",
              description:
                "Set your own schedule and manage at your convenience.",
              icon: "⏰",
            },
            {
              title: "Dedicated Support",
              description:
                "Our team is here to help you every step of the way.",
              icon: "🤝",
            },
          ].map((benefit, index) => (
            <div
              key={index}
              className='p-6 bg-white shadow-md rounded-xl hover:shadow-2xl transition-transform transform hover:scale-105 duration-300 flex flex-col items-center text-center'
            >
              <div className='text-4xl mb-4'>{benefit.icon}</div>
              <h3 className='text-lg font-bold mb-2 text-gray-800'>
                {benefit.title}
              </h3>
              <p className='text-gray-600'>{benefit.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BecomeVendor;

