import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const PaymentSuccess = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userId } = location.state || {};
  const email = localStorage.getItem("email");

  // Fetch restaurant name from local storage
  const cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];

  // Clear cartItems from localStorage after fetching restaurantName and reload
  useEffect(() => {
    if (localStorage.getItem("cartItems")) {
      localStorage.removeItem("cartItems");
      window.location.reload(); // Trigger a page reload
    }
  }, []);

  return (
    <div className='flex items-center justify-center min-h-screen bg-gradient-to-br from-green-100 via-white to-green-100 px-4'>
      {/* Card Container */}
      <div className='bg-white shadow-2xl rounded-2xl p-8 max-w-lg w-full border-2 border-green-400'>
        {/* Success Message */}
        <h1 className='text-4xl font-extrabold text-green-700 mb-6 text-center'>
          Payment Successful!
        </h1>

        {/* Thank You Message */}
        <p className='text-lg font-semibold text-gray-800 text-center mb-6'>
          Thank you for your order!
        </p>

        {/* Restaurant Details */}
        <div className='bg-green-50 p-4 rounded-lg shadow-inner mb-6'>
          <p className='text-lg text-gray-700 mb-2'>
            <span className='font-semibold text-green-700'>Email:</span>{" "}
            {email || "Not provided"}
          </p>
        </div>

        {/* Button */}
        <button
          onClick={() => navigate(`/my-restoura/orders-history/${userId}`)}
          className='w-full mt-4 px-6 py-3 bg-green-600 text-white text-lg font-semibold rounded-lg shadow-lg hover:bg-green-700 focus:ring focus:ring-green-300 transition'
        >
          Track Your Order
        </button>
      </div>
    </div>
  );
};

export default PaymentSuccess;

