import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

const PaymentCancel = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userId } = location.state || {};
  const email = localStorage.getItem("email");

  return (
    <div className='flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-red-100 via-red-50 to-red-100'>
      <h1 className='text-5xl font-extrabold text-red-700 mb-8 shadow-md bg-white rounded-lg px-6 py-3'>
        Payment Canceled
      </h1>
      <div className='bg-white shadow-xl rounded-lg p-8 w-full max-w-md text-center border border-red-200'>
        <p className='text-2xl font-semibold text-gray-700 mb-4'>
          Your payment was not completed.
        </p>
        <div className='bg-red-50 p-4 rounded-lg shadow-inner'>
          <p className='text-gray-600 text-lg'>
            <span className='font-bold text-red-700'>Email: </span>
            {email || "Not provided"}
          </p>
        </div>
      </div>
      <button
        onClick={() => navigate(`/my-restoura/orders-history/${userId}`)}
        className='mt-8 px-8 py-4 bg-red-600 text-white font-semibold text-lg rounded-lg shadow-lg hover:bg-red-700 focus:ring focus:ring-red-300 transition'
      >
        See Your Orders
      </button>
    </div>
  );
};

export default PaymentCancel;

