import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import API_BASE_URL from "../../apiConfig";
import useUtilService from "../../services/useUtilService";
import { useParams } from "react-router-dom";

const MenuCard = ({
  id,
  restaurantEmail,
  cuisineName,
  description,
  price,
  availability,
  restaurantId,
  category,
  estimatedPreparationTime,
  isHalal,
  isVegetarian,
  isKosher,
  isGlutenFree,
  isMSGFree,
  isNutFree,
  isOrganic,
  isNonGMO,
  isSpicy,
}) => {
  const [imageUrl, setImageUrl] = useState("");
  const [userRole, setUserRole] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const { showSuccessMessage, showErrorMessage } = useUtilService();
  const { name: restaurantName } = useParams(); // restaurant name from the url

  useEffect(() => {
    const fetchUserRole = () => {
      const role = localStorage.getItem("role");
      setUserRole(role);
    };

    const fetchCuisineImage = async () => {
      try {
        const response = await fetch(
          `${API_BASE_URL}/cuisine/downloadImage/${id}`
        );
        if (response.ok) {
          const imageBlob = await response.blob();
          setImageUrl(URL.createObjectURL(imageBlob));
        } else {
          console.error("Failed to fetch cuisine image");
        }
      } catch (error) {
        console.error("Error fetching cuisine image:", error);
      }
    };

    fetchUserRole();
    fetchCuisineImage();
  }, [id]);

  // adding new item to cart
  const handleAddToCart = () => {
    if (localStorage.getItem("isLoggedIn") !== "true") {
      showErrorMessage("Please login first to add items to your cart.");
      return;
    }

    if (userRole === "vendor") return;

    let cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];

    const newItem = {
      restaurantId,
      restaurantName: decodeURIComponent(restaurantName), // Decode the URL-encoded restaurant name
      restaurantEmail,
      cuisineId: id,
      cuisineName,
      cuisinePrice: price,
      quantity: 1,
      readyTime: estimatedPreparationTime, // added recently??
    };

    // Check if the restaurantId in the cart matches the new item's restaurantId
    if (cartItems.length > 0 && cartItems[0].restaurantId !== restaurantId) {
      showErrorMessage("Please confirm your order from one restaurant first");
      return;
    }

    const existingItemIndex = cartItems.findIndex(
      (item) => item.restaurantId === restaurantId && item.cuisineId === id
    );

    if (existingItemIndex !== -1) {
      cartItems[existingItemIndex].quantity += 1;
    } else {
      cartItems.push(newItem);
    }

    localStorage.setItem("cartItems", JSON.stringify(cartItems));
    // Show success message and reload after a delay
    showSuccessMessage("Item successfully added to your cart!");

    setTimeout(() => {
      window.location.reload();
    }, 1500); // Delay of 1.5 seconds before reload
  };

  const handleModalOpen = () => setIsModalOpen(true);
  const handleModalClose = () => setIsModalOpen(false);
  const toggleExpanded = () => setIsExpanded((prev) => !prev);

  const dietaryTags = [
    { label: "Halal", value: isHalal },
    { label: "Vegetarian", value: isVegetarian },
    { label: "Kosher", value: isKosher },
    { label: "Gluten-Free", value: isGlutenFree },
    { label: "MSG-Free", value: isMSGFree },
    { label: "Nut-Free", value: isNutFree },
    { label: "Organic", value: isOrganic },
    { label: "Non-GMO", value: isNonGMO },
    { label: "Spicy", value: isSpicy },
  ].filter((tag) => tag.value);

  return (
    <div className='flex flex-col sm:flex-row items-start p-4 rounded-lg shadow-md hover:shadow-lg hover:bg-green-100 transition duration-200 bg-gray-50'>
      <div className='relative w-full sm:w-1/3 h-40'>
        <img
          src={imageUrl}
          alt={cuisineName}
          className='w-full h-full object-cover rounded-lg'
        />
        <Tooltip title='Add to Cart'>
          <button
            onClick={(e) => {
              e.stopPropagation();
              handleAddToCart();
            }}
            className={`absolute bottom-2 right-2 bg-white rounded-full shadow-md hover:bg-gray-200 flex items-center justify-center 
            ${
              availability === "out of stock" || userRole === "vendor"
                ? "cursor-not-allowed opacity-50"
                : ""
            }`}
            disabled={availability === "out of stock" || userRole === "vendor"}
            style={{ width: "35px", height: "35px" }}
          >
            <AddIcon className='text-green-500' />
          </button>
        </Tooltip>
      </div>

      <div className='w-full sm:w-2/3 sm:pl-4 mt-4 sm:mt-0'>
        <h2 className='text-2xl font-semibold text-gray-800 truncate hover:text-green-600 transition-colors duration-300'>
          {cuisineName}
        </h2>
        <p className='text-xl font-bold text-green-600 '>${price}</p>
        <p
          className={`text-sm font-medium ${
            availability === "out of stock" ? "text-red-500" : "text-green-600"
          } transition-colors duration-300`}
        >
          {availability === "out of stock" ? "Out of stock" : "In stock"}
        </p>
        <p className='text-sm text-gray-600 font-medium '>
          Prep Time:{" "}
          <span className='font-semibold text-gray-800'>
            {estimatedPreparationTime}
          </span>
        </p>
        <p className='text-sm text-gray-500 font-medium line-clamp-1'>
          {description}
        </p>
        <div className='flex items-center justify-between mt-2'>
          <button
            className='text-sm text-gray-500 hover:text-gray-800 transition-colors duration-200'
            onClick={toggleExpanded}
          >
            {isExpanded ? (
              <>
                Hide <ExpandLessIcon />
              </>
            ) : (
              <>
                Dietary Features <ExpandMoreIcon />
              </>
            )}
          </button>
          <button
            className='text-sm text-green-500 font-semibold hover:text-green-800'
            onClick={handleModalOpen}
          >
            See Details
          </button>
        </div>

        {isExpanded && (
          <div className='mt-2'>
            <div className='flex flex-wrap gap-2'>
              {dietaryTags.map((tag) => (
                <span
                  key={tag.label}
                  className='bg-green-100 text-green-800 text-xs font-semibold rounded-full px-2 py-1'
                >
                  {tag.label}
                </span>
              ))}
            </div>
          </div>
        )}
      </div>

      <Dialog
        open={isModalOpen}
        onClose={handleModalClose}
        fullWidth
        maxWidth='sm'
      >
        <DialogTitle className='whitespace-normal break-words'>
          {cuisineName}
          <IconButton
            aria-label='close'
            onClick={handleModalClose}
            style={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <img
            src={imageUrl}
            alt={cuisineName}
            className='w-full h-64 object-cover rounded-lg mb-4 '
          />
          <p className='text-sm font-medium text-gray-500 whitespace-normal break-words'>
            Category: {category}
          </p>
          <p className='text-lg font-semibold'>${price}</p>
          <p className='text-sm font-medium text-gray-500'>
            Availability: {availability ? "In stock" : "Out of stock"}
          </p>
          <h2 className='text-xl font-sm truncate'>
            Estimated Ready Time: {estimatedPreparationTime}
          </h2>
          <p className='text-gray-700 mb-2 whitespace-normal break-words'>
            {description}
          </p>
          <div className='flex flex-wrap gap-2 mt-2'>
            {dietaryTags
              .filter((tag) => tag.value)
              .map((tag) => (
                <span
                  key={tag.label}
                  className='bg-green-100 text-green-800 text-xs font-semibold rounded-full px-2 py-1'
                >
                  {tag.label}
                </span>
              ))}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleAddToCart}
            disabled={!availability || userRole === "vendor"}
            color='primary'
            variant='contained'
          >
            Add to Cart
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MenuCard;

