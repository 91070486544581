import React, { useState, useEffect } from "react";
import API_BASE_URL from "../../apiConfig";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEdit, faLock } from "@fortawesome/free-solid-svg-icons";
import useUtilService from "../../services/useUtilService";

const OrderManagement = () => {
  const [orders, setOrders] = useState([]);
  const [restaurantIds, setRestaurantIds] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const { showSuccessMessage, showErrorMessage } = useUtilService();
  const userId = localStorage.getItem("userId");
  const token = localStorage.getItem("token");

  useEffect(() => {
    fetchRestaurants();
  }, []);

  // Fetch all restaurants and filter by ownerId
  const fetchRestaurants = async () => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/restaurant/allRestaurants`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        const filteredRestaurantIds = data
          .filter((restaurant) => restaurant.ownerId === parseInt(userId))
          .map((restaurant) => restaurant.id);
        setRestaurantIds(filteredRestaurantIds);
        fetchOrders(filteredRestaurantIds);
      } else {
        console.error("Failed to fetch restaurants");
      }
    } catch (error) {
      console.error("Error fetching restaurants:", error);
    }
  };

  // Format the pickup time for display
  const formatPickupTime = (pickupTime) => {
    const date = new Date(pickupTime);

    // Format date as YYYY-MM-DD
    const formattedDate = date.toLocaleDateString("en-CA"); // Canadian format for ISO-like YYYY-MM-DD

    // Format time as hh:mm AM/PM
    const options = { hour: "2-digit", minute: "2-digit", hour12: true };
    const formattedTime = date.toLocaleTimeString("en-US", options);

    return `${formattedDate}, ${formattedTime}`;
  };

  // Fetch all orders and filter by restaurantIds also fetch customer details
  const fetchOrders = async (restaurantIds) => {
    try {
      const response = await fetch(`${API_BASE_URL}/order/getAllOrders`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const data = await response.json();

        // Filter orders by restaurant IDs and payment status
        const filteredOrders = data.filter(
          (order) =>
            restaurantIds.includes(order.restaurantId) &&
            order.paymentStatus === "Payment Received"
        );

        // Fetch customer details for each order and format pickup time
        const enrichedOrders = await Promise.all(
          filteredOrders.map(async (order) => {
            try {
              const customerResponse = await fetch(
                `${API_BASE_URL}/customers/${order.customerEmail}`,
                {
                  method: "GET",
                  headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                  },
                }
              );

              if (customerResponse.ok) {
                const customerData = await customerResponse.json();
                return {
                  ...order,
                  customer: customerData, // Enrich order with customer data
                  pickupTime: formatPickupTime(order.pickupTime), // Format pickup time
                };
              } else {
                console.error(
                  `Failed to fetch customer details for email: ${order.customerEmail}`
                );
                return {
                  ...order,
                  customer: null,
                  pickupTime: formatPickupTime(order.pickupTime), // Format pickup time even if customer details fetch fails
                };
              }
            } catch (error) {
              console.error(
                `Error fetching customer details for email: ${order.customerEmail}`,
                error
              );
              return {
                ...order,
                customer: null,
                pickupTime: formatPickupTime(order.pickupTime), // Format pickup time on error
              };
            }
          })
        );

        // Update the state with enriched orders
        setOrders(enrichedOrders);
      } else {
        console.error("Failed to fetch orders");
      }
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  // Handle status update with confirmation
  const handleStatusUpdate = async (newStatus) => {
    if (!selectedOrder) return;

    const isConfirmed = window.confirm(
      `Are you sure you want to change the status to "${newStatus}"?`
    );
    if (!isConfirmed) return;

    setIsUpdating(true); // Start loading
    try {
      const response = await fetch(
        `${API_BASE_URL}/order/updateOrderStatus/${selectedOrder.orderId}?status=${newStatus}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        // Update the status in the local state
        setOrders((prevOrders) =>
          prevOrders.map((order) =>
            order.orderId === selectedOrder.orderId
              ? { ...order, orderStatus: newStatus }
              : order
          )
        );
        setShowPopup(false); // Close the popup
        setSelectedOrder(null);

        // Show success message
        showSuccessMessage("Status updated successfully.");
      } else {
        const errorMessage = await response.text();
        console.error("Failed to update status:", errorMessage);
        showErrorMessage("Failed to update status. Please try again.");
      }
    } catch (error) {
      console.error("Error updating status:", error);
      showErrorMessage(
        "An error occurred while updating the status. Please try again."
      );
    } finally {
      setIsUpdating(false); // Stop loading
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "Pending":
        return "bg-yellow-500 text-white";
      case "Order Placed":
        return "bg-green-500 text-white";
      case "Canceled:Vendor":
        return "bg-red-500 text-white";
      case "Delivered":
        return "bg-blue-500 text-white";
      case "Payment Received":
        return "bg-green-600 text-white";
      default:
        return "bg-gray-500 text-white";
    }
  };

  const handleStatusChange = (order) => {
    setSelectedOrder(order);
    setShowPopup(true);
  };

  const [showFeeback, setShowFeedback] = useState(false);

  // Function to handle opening the popup and setting the selected order
  const handleViewFeedback = (order) => {
    setSelectedOrder(order);
    setShowFeedback(true);
  };

  // Function to handle closing the popup
  const handleCloseFeedback = () => {
    setShowFeedback(false);
  };

  // to view the customer details
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const handleCustomerClick = (customer) => {
    setSelectedCustomer(customer);
  };

  const closeCustomerDetails = () => {
    setSelectedCustomer(null);
  };

  return (
    <div className='flex flex-col flex-1 p-8 bg-gray-100 min-h-screen'>
      {/* Header Section */}
      <div className='bg-gradient-to-r from-blue-600 to-indigo-800 py-8 shadow-lg rounded-t-lg'>
        <h2 className='text-4xl font-bold text-white tracking-wide text-center'>
          Order Management
        </h2>
      </div>

      {/* Alerts */}
      <div className='mt-2'></div>
      <p className='text-lg text-center font-semibold text-gray-700 bg-red-100 border-l-4 border-red-500 pl-3 py-2 rounded-md mb-2 shadow-sm'>
        Please remember to update the order status promptly to ensure accurate
        tracking and customer satisfaction. ⌛
      </p>
      <p className='text-lg text-center font-semibold text-gray-700 bg-yellow-100 border-l-4 border-yellow-500 pl-3 py-2 rounded-md mb-2 shadow-sm'>
        Please ensure the order is prepared and ready for pickup within the
        required pickup time to avoid delays. ⏱️
      </p>

      {/* Order Details Table */}
      <div className='relative flex-1 overflow-hidden bg-white shadow-md rounded-lg'>
        <div className='overflow-x-auto max-w-full'>
          <table className='table-auto w-full bg-white border border-gray-300 rounded-lg'>
            <thead className='bg-gray-200 text-gray-700'>
              <tr>
                {[
                  "Customer Info",
                  "Ordered Items",
                  "Order Timestamp",

                  "Pickup Time (Estimated)",
                  "Total Price (+13%HST)",
                  "Customer Payment",
                  "Restaurant Name",
                  "Current Status",
                  "Update Status",
                  "Feedback",
                ].map((header) => (
                  <th
                    key={header}
                    className='py-3 px-4 text-left font-semibold border-b'
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {orders.map((order) => (
                <tr
                  key={order.orderId}
                  className='hover:bg-gray-50 transition duration-150'
                >
                  {/* Customer Name */}
                  <td className='py-4 px-4 border-b'>
                    <button
                      className='text-blue-500 hover:text-blue-700 focus:outline-none'
                      onClick={() =>
                        handleCustomerClick(order.customer || null)
                      }
                    >
                      {order.customer ? order.customer.name : "N/A"}
                    </button>
                  </td>
                  {/* Food Items */}
                  <td className='py-4 px-4 border-b'>
                    {order.foodItemList.map((item, index) => (
                      <div key={index}>
                        {item.cuisineName} x{item.quantity} ($
                        {(item.cuisinePrice * item.quantity).toFixed(2)})
                      </div>
                    ))}
                  </td>

                  {/* Order Date */}
                  <td className='py-4 px-4 border-b'>
                    {order.orderDate}, {order.timeOfOrder}
                  </td>
                  <td className='py-4 px-4 border-b'>{order.pickupTime}</td>
                  <td className='py-4 px-4 border-b'>
                    ${(order.totalAmount * 1.13).toFixed(2)}
                  </td>
                  <td className='py-4 px-4 border-b'>
                    {order.paymentStatus === "Payment Received" ? (
                      <span className='text-green-500 font-semibold'>Paid</span>
                    ) : (
                      <span className='text-red-500 font-semibold'>
                        Not Paid
                      </span>
                    )}
                  </td>

                  {/* Restaurant Name */}
                  <td className='py-4 px-4 border-b'>{order.restaurantName}</td>
                  {/* Current Status */}
                  <td className='py-4 px-4 border-b text-wrap'>
                    <span
                      className={`px-1 py-1 text-sm font-semibold ${getStatusColor(
                        order.orderStatus
                      )}`}
                    >
                      {order.orderStatus}
                    </span>
                  </td>

                  {/* Update Status */}
                  <td className='py-4 px-4 border-b'>
                    {["Pending", "Order Placed"].includes(order.orderStatus) ? (
                      <button
                        className='bg-gradient-to-r from-green-400 to-green-600 text-white font-semibold px-4 py-2 rounded-lg shadow-md hover:from-green-500 hover:to-green-700 focus:ring-2 focus:ring-green-300 focus:outline-none transition-transform transform hover:scale-105'
                        onClick={() => handleStatusChange(order)}
                        title='Update Status'
                      >
                        <FontAwesomeIcon
                          icon={faEdit}
                          className='w-4 h-4 mr-2'
                        />
                        Update
                      </button>
                    ) : (
                      <span className='text-gray-500'>
                        <FontAwesomeIcon icon={faLock} className='w-5 h-5' />
                      </span>
                    )}
                  </td>

                  {/* Feedback */}
                  <td className='py-4 px-4 border-b'>
                    {order.orderStatus === "Delivered" ? (
                      <button
                        className='text-blue-500 hover:text-blue-700 focus:outline-none'
                        onClick={() => handleViewFeedback(order)}
                        title='View Feedback'
                      >
                        <FontAwesomeIcon icon={faEye} />
                      </button>
                    ) : (
                      <span
                        className='text-gray-400 cursor-not-allowed'
                        title='Feedback available only after delivery'
                      >
                        <FontAwesomeIcon icon={faEye} />
                      </span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Modal for Customer Details */}
        {selectedCustomer && (
          <div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50'>
            <div className='bg-gradient-to-r from-white to-gray-100 rounded-xl p-6 shadow-2xl w-96'>
              <h2 className='text-2xl font-bold text-gray-800 mb-6 border-b pb-2'>
                Customer Details
              </h2>
              <div className='space-y-4'>
                <div className='flex justify-between'>
                  <span className='text-gray-600 font-medium'>Name:</span>
                  <span className='text-gray-900 font-semibold'>
                    {selectedCustomer.name}
                  </span>
                </div>
                <div className='flex justify-between'>
                  <span className='text-gray-600 font-medium'>Email:</span>
                  <span className='text-gray-900 font-semibold'>
                    {selectedCustomer.email}
                  </span>
                </div>
                <div className='flex justify-between'>
                  <span className='text-gray-600 font-medium'>Contact No:</span>
                  <span className='text-gray-900 font-semibold'>
                    {selectedCustomer.contactNo}
                  </span>
                </div>
              </div>
              <button
                className='mt-6 w-full py-2 bg-blue-600 text-white rounded-lg shadow-md hover:bg-blue-700 transition focus:outline-none focus:ring-2 focus:ring-blue-300'
                onClick={closeCustomerDetails}
              >
                Close
              </button>
            </div>
          </div>
        )}
      </div>

      {/* Popup for viewing feedback */}
      {showFeeback && (
        <div className='fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50'>
          <div className='bg-white p-6 sm:p-8 rounded-lg shadow-lg w-full max-w-md mx-4 sm:mx-0 sm:w-1/3'>
            <h3 className='text-xl font-semibold mb-4 text-center'>
              Order Feedback
            </h3>
            <div className='flex flex-col space-y-4'>
              <p className='text-gray-700'>
                <strong>Rating:</strong> {selectedOrder.orderRating}
              </p>
              <p className='text-gray-700'>
                <strong>Review:</strong> {selectedOrder.orderReview}
              </p>
              <button
                className='mt-4 px-4 py-2 bg-gray-500 text-white rounded-lg hover:bg-gray-600 transition w-full'
                onClick={handleCloseFeedback}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Popup for status update */}
      {showPopup && (
        <div className='fixed inset-0 bg-black bg-opacity-60 flex items-center justify-center z-50'>
          <div className='bg-white p-8 rounded-xl shadow-2xl w-full max-w-lg mx-4 sm:w-1/3 relative'>
            <h3 className='text-2xl font-bold mb-6 text-center text-gray-800'>
              Update Order Status
            </h3>
            <div className='flex flex-col space-y-4'>
              {selectedOrder.orderStatus === "Pending" && (
                <>
                  <button
                    className={`flex items-center justify-center px-5 py-3 bg-gradient-to-r from-green-400 to-green-600 text-white rounded-full shadow-md hover:shadow-lg transition-all duration-300 ${
                      isUpdating ? "cursor-not-allowed opacity-70" : ""
                    }`}
                    onClick={() => handleStatusUpdate("Order Placed")}
                    disabled={isUpdating}
                  >
                    {isUpdating ? (
                      <span className='loader w-5 h-5 border-2 border-t-2 border-white rounded-full animate-spin'></span>
                    ) : (
                      "Accept Order"
                    )}
                  </button>
                  <button
                    className={`flex items-center justify-center px-5 py-3 bg-gradient-to-r from-red-400 to-red-600 text-white rounded-full shadow-md hover:shadow-lg transition-all duration-300 ${
                      isUpdating ? "cursor-not-allowed opacity-70" : ""
                    }`}
                    onClick={() => handleStatusUpdate("Canceled:Vendor")}
                    disabled={isUpdating}
                  >
                    {isUpdating ? (
                      <span className='loader w-5 h-5 border-2 border-t-2 border-white rounded-full animate-spin'></span>
                    ) : (
                      "Cancel Order"
                    )}
                  </button>
                </>
              )}
              {selectedOrder.orderStatus === "Order Placed" && (
                <button
                  className={`flex items-center justify-center px-5 py-3 bg-gradient-to-r from-blue-400 to-blue-600 text-white rounded-full shadow-md hover:shadow-lg transition-all duration-300 ${
                    isUpdating ? "cursor-not-allowed opacity-70" : ""
                  }`}
                  onClick={() => handleStatusUpdate("Delivered")}
                  disabled={isUpdating}
                >
                  {isUpdating ? (
                    <span className='loader w-5 h-5 border-2 border-t-2 border-white rounded-full animate-spin'></span>
                  ) : (
                    "Delivered"
                  )}
                </button>
              )}
              <button
                className='mt-4 px-5 py-3 bg-gradient-to-r from-gray-400 to-gray-600 text-white rounded-full shadow-md hover:shadow-lg transition-all duration-300'
                onClick={() => setShowPopup(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderManagement;

