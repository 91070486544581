import React, { useEffect, useState } from "react";
import API_BASE_URL from "../../apiConfig";
import { useNavigate } from "react-router-dom";

const MyProfile = () => {
  const [profilePicture, setProfilePicture] = useState(null);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  // State variables for toggling visibility
  const [showLicense, setShowLicense] = useState(false);
  const [showTransit, setShowTransit] = useState(false);
  const [showAccount, setShowAccount] = useState(false);
  const [showInstitution, setShowInstitution] = useState(false);
  const [profileDetails, setProfileDetails] = useState({
    name: "",
    country: "",
    city: "",
    province: "",
    postalCode: "",
    streetNo: "",
    streetNo2: "",
    email: "",
    contactNo: "",
    licenseNumber: "",
    transitNumber: "",
    accountNumber: "",
    institutionNumber: "",
  });

  const token = localStorage.getItem("token");
  const userId = localStorage.getItem("userId");

  useEffect(() => {
    if (token && userId) {
      fetch(`${API_BASE_URL}/vendor/getVendor/${userId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to fetch vendor data");
          }
          return response.json();
        })
        .then((data) => {
          if (!data || !data.firstName) {
            // Trigger modal if data is incomplete
            setShowModal(true);
            setTimeout(() => {
              navigate(`/vendor-onboarding/${userId}`); // Redirect
            }, 2000);
          } else {
            setProfileDetails({
              name: data.firstName,
              email: data.email,
              contactNo: data.contactNo,
              country: data.country,
              city: data.city,
              province: data.province,
              streetNo: data.streetNo,
              streetNo2: data.streetNo2,
              postalCode: data.postalCode,
              licenseNumber: data.licenseNumber,
              transitNumber: data.transitNumber,
              accountNumber: data.accountNumber,
              institutionNumber: data.institutionNumber,
            });
          }
        })
        .catch((error) => {
          console.error("Error fetching vendor data:", error);
        });

      // Fetch Profile Picture
      fetch(`${API_BASE_URL}/vendor/downloadImage/${userId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to fetch profile image");
          }
          return response.blob();
        })
        .then((blob) => {
          const imageUrl = URL.createObjectURL(blob); // Create a URL for the image
          setProfilePicture(imageUrl);
        })
        .catch((error) => {
          console.error("Error fetching profile image:", error);
        });
    } else {
      console.error("Token or userId not found in local storage");
    }
  }, []);

  const handleEdit = () => {
    alert("Edit functionality to be implemented.");
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfilePicture(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // Function to handle toggling visibility of sensitive information
  const toggleVisibility = (field) => {
    if (field === "license") setShowLicense(!showLicense);
    if (field === "transit") setShowTransit(!showTransit);
    if (field === "account") setShowAccount(!showAccount);
    if (field === "institution") setShowInstitution(!showInstitution);
  };
  return (
    <div className='relative'>
      {/* Modal for Missing Information */}
      {showModal && (
        <div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50'>
          <div className='bg-white p-8 rounded-lg shadow-2xl w-96 text-center'>
            <div className='flex justify-center mb-4'>
              <svg
                className='w-12 h-12 text-red-600'
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                  d='M13.828 12l3.182-3.182a2 2 0 10-2.828-2.828L12 9.172 8.818 5.99a2 2 0 00-2.828 2.828L10.172 12l-3.182 3.182a2 2 0 102.828 2.828L12 14.828l3.182 3.182a2 2 0 002.828-2.828L13.828 12z'
                />
              </svg>
            </div>
            <h2 className='text-xl font-bold text-gray-800'>
              Missing Information
            </h2>
            <p className='text-gray-600 mt-2'>
              Redirecting to the onboarding page...
            </p>
            <div className='flex justify-center mt-4'>
              <div className='animate-spin rounded-full h-8 w-8 border-t-4 border-red-600 border-opacity-70'></div>
            </div>
          </div>
        </div>
      )}
      {/* Announcement Section */}
      <div className='mb-2 bg-gradient-to-r from-blue-500 via-teal-500 to-cyan-400 rounded-lg shadow-md p-4 flex items-center justify-between max-w-4xl mx-auto text-white'>
        <span className='text-lg font-semibold'>
          Dreaming of starting your own restaurant? Start your journey with us
          today!
        </span>
        <a
          href={`/vendor-restoura/create-new-restaurant/${userId}`}
          className='bg-yellow-400 text-gray-900 font-bold py-2 px-4 rounded-lg shadow hover:bg-yellow-300 transition-transform transform hover:scale-105'
        >
          Create Now
        </a>
      </div>

      <div className=' py-8 px-4 md:px-8'>
        {/* Header Section */}
        <div className='bg-white rounded-lg shadow p-6 max-w-4xl mx-auto'>
          <div className='flex flex-col md:flex-row justify-between items-center mb-6'>
            <div>
              <h2 className='text-2xl font-bold text-gray-800'>
                Vendor Profile
              </h2>
              <p className='text-gray-500'>Manage your profile information</p>
            </div>
            <div className='flex flex-col items-center mt-4 md:mt-0'>
              {profilePicture ? (
                <img
                  src={profilePicture}
                  alt='Profile'
                  className='w-32 h-32 rounded-full shadow-lg'
                />
              ) : (
                <div className='w-32 h-32 flex items-center justify-center bg-gray-200 rounded-full shadow-lg'>
                  <span className='text-gray-400'>No Image</span>
                </div>
              )}
            </div>
          </div>

          {/* Profile Sections */}
          <div className='grid grid-cols-1 md:grid-cols-2 gap-6'>
            {/* Identification Section */}
            <div className='bg-white border rounded-lg shadow-sm p-4'>
              <h3 className='text-lg font-semibold text-gray-700 mb-4'>
                Identification
              </h3>
              <table className='w-full text-sm'>
                <tbody>
                  {[
                    { label: "Name", value: profileDetails.name },
                    { label: "Email", value: profileDetails.email },
                    { label: "Contact No", value: profileDetails.contactNo },
                    {
                      label: "Driving License Number",
                      value: showLicense
                        ? profileDetails.licenseNumber
                        : "**** **** " + profileDetails.licenseNumber.slice(-4),
                      action: (
                        <button
                          className='text-blue-500 ml-2 text-sm'
                          onClick={() => toggleVisibility("license")}
                        >
                          {showLicense ? "Hide" : "View"}
                        </button>
                      ),
                    },
                  ].map(({ label, value, action }, idx) => (
                    <tr key={idx} className='border-b'>
                      <td className='font-medium text-gray-600 py-2'>
                        {label}:
                      </td>
                      <td className='py-2 text-gray-700 flex justify-between'>
                        {value}
                        {action}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* Address Information */}
            <div className='bg-white border rounded-lg shadow-sm p-4'>
              <h3 className='text-lg font-semibold text-gray-700 mb-4'>
                Address Information
              </h3>
              <table className='w-full text-sm'>
                <tbody>
                  {[
                    { label: "Country", value: profileDetails.country },
                    { label: "City", value: profileDetails.city },
                    { label: "Province", value: profileDetails.province },
                    { label: "Street No 1", value: profileDetails.streetNo },
                    { label: "Street No 2", value: profileDetails.streetNo2 },
                    { label: "Postal Code", value: profileDetails.postalCode },
                  ].map(({ label, value }, idx) => (
                    <tr key={idx} className='border-b'>
                      <td className='font-medium text-gray-600 py-2'>
                        {label}:
                      </td>
                      <td className='py-2 text-gray-700'>{value}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          {/* Payment Information */}
          <div className='mt-6 bg-white border rounded-lg shadow-sm p-4'>
            <h3 className='text-lg font-semibold text-gray-700 mb-4'>
              Payment Information
            </h3>
            <table className='w-full text-sm'>
              <tbody>
                {[
                  {
                    label: "Institution Number",
                    value: showInstitution
                      ? profileDetails.institutionNumber
                      : "** " + profileDetails.institutionNumber.slice(-2),
                    action: (
                      <button
                        className='text-blue-500 ml-2 text-sm'
                        onClick={() => toggleVisibility("institution")}
                      >
                        {showInstitution ? "Hide" : "View"}
                      </button>
                    ),
                  },
                  {
                    label: "Transit Number",
                    value: showTransit
                      ? profileDetails.transitNumber
                      : "*** " + profileDetails.transitNumber.slice(-2),
                    action: (
                      <button
                        className='text-blue-500 ml-2 text-sm'
                        onClick={() => toggleVisibility("transit")}
                      >
                        {showTransit ? "Hide" : "View"}
                      </button>
                    ),
                  },
                  {
                    label: "Account Number",
                    value: showAccount
                      ? profileDetails.accountNumber
                      : "**** " + profileDetails.accountNumber.slice(-3),
                    action: (
                      <button
                        className='text-blue-500 ml-2 text-sm'
                        onClick={() => toggleVisibility("account")}
                      >
                        {showAccount ? "Hide" : "View"}
                      </button>
                    ),
                  },
                ].map(({ label, value, action }, idx) => (
                  <tr key={idx} className='border-b'>
                    <td className='font-medium text-gray-600 py-2'>{label}:</td>
                    <td className='py-2 text-gray-700 flex justify-between'>
                      {value}
                      {action}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Edit Profile Button */}
          <div className='mt-6 text-center'>
            <button
              onClick={handleEdit}
              className='px-6 py-2 bg-cyan-600 text-white rounded-lg shadow-md hover:bg-cyan-700 transition'
            >
              Edit Profile
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyProfile;

