import React from "react";

const VendorTermsV1 = () => {
  const lastModifiedDate = "12/03/2024";

  const terms = [
    {
      title: "Food Preparation Standards",
      content:
        "You agree to maintain the highest standards in every aspect of food preparation, from sourcing groceries to selecting ingredients. The integrity of your menu as described on the Restoura platform must be preserved, and any changes to the menu must be promptly updated on the platform.",
    },
    {
      title: "Hygiene and Cleanliness",
      content:
        "You are responsible for ensuring that your kitchen, washroom, and overall premises meet the required hygiene and cleanliness standards. As a home-based restaurant, you must be particularly vigilant in maintaining a safe and sanitary environment for food preparation and handling.",
    },
    {
      title: "Best Cooking Practices",
      content:
        "You agree to use appropriate utensils and follow best practices in cooking, including safe food handling, storage, and preparation methods, to ensure the quality and safety of the food you provide.",
    },
    {
      title: "Inspection and Audits",
      content:
        "You agree to allow Restoura's audit team or relevant government agencies to inspect your kitchen and premises for hygiene compliance at any time during operating hours, without prior notice.",
    },
    {
      title: "Professional Conduct",
      content:
        "You agree to interact with customers and the Restoura team in a professional manner. This includes communication, delivery, and any issues that may arise.",
    },
    {
      title: "Timely Order Acknowledgement and Delivery",
      content:
        "You commit to promptly acknowledging customer orders and delivering them within the time frame specified in the order. If an issue arises that may delay the delivery, you agree to notify both the customer and the Restoura Business Operations Center (BOC) as soon as possible, providing a clear explanation.",
    },
    {
      title: "Order Management and Cancellation",
      content:
        "You agree that if an order is not acknowledged within two minutes of being placed, it will be automatically canceled. Repeated order cancellations due to failure to acknowledge orders may result in the suspension or removal of your restaurant from the platform.",
    },
    {
      title: "Kosher and Halal Requirements",
      content:
        "If your restaurant offers kosher or halal options, you agree to comply with specific food preparation, utensil usage, and hygiene standards required for such orders. This includes keeping separate utensils and preparation areas if necessary.",
    },
    {
      title: "Managing Order Volume",
      content:
        "You agree to manage your order queue effectively and stop accepting new orders if you are unable to fulfill existing orders on time. Overwhelming yourself with too many orders may result in operational difficulties and potential suspension from the platform.",
    },
    {
      title: "Menu Accuracy",
      content:
        "You agree to ensure that the food served to customers matches the descriptions on your menu in terms of portion size, ingredients, freshness, and overall quality.",
    },

    {
      title: "Prohibited Items",
      content:
        "Vendors are strictly prohibited from selling any narcotics, controlled substances, or any other illegal items on the Restoura platform. Any vendor found attempting to sell such items will have their account immediately suspended or terminated, and legal action may be taken if necessary. It is the responsibility of the vendor to ensure that all menu offered for sale comply with local laws and regulations.",
    },
    {
      title: "Alcohol Policy",
      content:
        "Restoura does not allow the sale of alcohol or related products on the platform. Vendors may use alcohol as an ingredient in cooking, but must fully disclose the use of alcohol in the relevant menu items. Any menu that includes alcohol as part of the recipe must clearly indicate this to customers, ensuring transparency.",
    },
    {
      title: "Non-Food Items Policy",
      content:
        "Vendors are strictly prohibited from selling non-food items on the Restoura platform. Only food-related products that are compliant with health and safety regulations are allowed. Vendors must ensure that all items listed for sale are food-related and meet platform guidelines.",
    },
    {
      title: "Food Packaging",
      content:
        "You are responsible for using proper packaging to ensure that food reaches the customer in a safe and presentable condition. Packaging must be appropriate for the type of food being delivered and should maintain the temperature and freshness of the meal.",
    },
    {
      title: "Delivery Timeliness",
      content:
        "You agree to respect your stated delivery times. Any discrepancies or delays must be communicated to the customer in advance, and the customer must be notified of any significant delays in delivery.",
    },
    {
      title: "Data Collection and Use",
      content:
        "You agree to the collection and processing of your personal data by Restoura for business purposes, including payment processing, customer service, and performance tracking.",
    },
    {
      title: "Communication",
      content:
        "You agree to be available for communication via phone, email, app notifications, and in-person meetings as needed for the purpose of conducting business with Restoura.",
    },
    {
      title: "Operational Area",
      content:
        "As of now, Restoura operations are limited to the Toronto, Vaughan, Mississauga, Brampton - GTA areas. You agree to only accept orders within these areas unless otherwise notified.",
    },
    {
      title: "Compliance with Local Laws",
      content:
        "You agree to comply with all applicable local, provincial, and federal laws regarding food safety, health regulations, and business operations. This includes obtaining any necessary permits or licenses required for operating a home-based food business if becomes necessary in future.",
    },
    {
      title: "Dispute Resolution",
      content:
        "In the event of any dispute, claim, or disagreement arising out of or relating to these terms and conditions, or the relationship between you (the vendor) and Restoura, both parties agree to first attempt to resolve the issue amicably through informal discussions. If the dispute cannot be resolved through informal means, both parties agree to engage in mediation with a neutral third party before pursuing legal action. If mediation is unsuccessful, the parties may resort to arbitration or litigation, with the jurisdiction and venue to be determined by Restoura. Both parties agree to bear their own costs for mediation or arbitration, unless otherwise agreed upon.",
    },
    {
      title: "Termination and Suspension",
      content:
        "Restoura reserves the right to suspend or terminate your vendor account at any time if these terms and conditions are not adhered to, or if any legal or operational violations occur.",
    },
    {
      title: "Right to Modify Terms",
      content:
        "Restoura reserves the right to modify, update, or add to these terms and conditions at any time, as business needs or legal requirements arise. Any changes will be communicated to you in advance via email or through the platform, and your continued use of the platform and provision of services after such modifications will constitute your acceptance of the revised terms. If you do not agree to the changes, you may terminate your vendor account at any time. ",
    },
    {
      title: "Platform Fees for Insurance and Legal Coverage",
      content:
        "As part of the platform's operational costs, a portion of the platform fees charged on each order will be allocated toward maintaining insurance coverage for vendors and the platform, as well as to cover legal fees associated with any disputes or compliance requirements. This fee allocation is intended to help ensure a safe and secure business environment for both vendors and customers. By agreeing to these terms, you acknowledge that Restoura may use part of the platform fees for these purposes. The exact percentage or amount allocated will be outlined in the vendor dashboard or can be provided upon request.",
    },
  ];

  return (
    <div className='min-h-screen bg-gray-50 '>
      {/* Header */}
      <header className='bg-white shadow-md'>
        <div className='max-w-4xl mx-auto px-4 sm:px-6 py-2 text-center'>
          <h1 className='text-3xl md:text-4xl font-bold text-gray-800'>
            Vendor Terms & Conditions
          </h1>

          <p className='text-gray-600 mt-2'>
            Thank you for your interest in joining Restoura. Please read the
            terms carefully.
          </p>
          <p className='text-sm text-gray-500 mt-2'>
            Last Modified: {lastModifiedDate}
          </p>
        </div>
      </header>

      {/* Terms Content */}
      <main className='max-w-4xl mx-auto px-4 sm:px-6 py-8'>
        {terms.map((term, index) => (
          <section key={index} className='mb-8'>
            <h2 className='text-xl font-bold text-gray-800'>{term.title}</h2>
            <p className='mt-4 text-gray-600'>{term.content}</p>
          </section>
        ))}
      </main>

      {/* Footer */}
      <footer className='bg-white py-4 mt-8 border-t'>
        <div className='max-w-4xl mx-auto px-4 text-center'>
          <p className='text-sm text-gray-500'>
            © {new Date().getFullYear()} Restoura. All rights reserved.
          </p>
        </div>
      </footer>
    </div>
  );
};

export default VendorTermsV1;

