import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import API_BASE_URL from "../../apiConfig";
import useUtilService from "../../services/useUtilService";
import Modal from "../../common-components/modal/Modal";
import TermsContent from "../static_pages/VendorTermsV1";

const VendorOnboarding = () => {
  const navigate = useNavigate();
  const [vendorDetails, setVendorDetails] = useState({
    firstName: "",
    lastName: "",
    email: localStorage.getItem("email") || "",
    dateOfBirth: "",
    sex: "",
    contactNo: "",
    occupation: "",
    // Address Information
    streetNo: "",
    streetNo2: "",
    city: "",
    postalCode: "",
    province: "",
    country: "",
    // Identification
    licenseNumber: "", // License Number
    transitNumber: "", // Transit Number
    accountNumber: "", // Account Number
    institutionNumber: "", // Institution Number

    terms: false,
  });

  const [modalOpen, setModalOpen] = useState(false);
  const { showSuccessMessage, showErrorMessage } = useUtilService();
  const [image, setImage] = useState(null);
  const [drivingLicense, setDrivingLicense] = useState(null);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (name === "dateOfBirth") {
      const today = new Date();
      const birthDate = new Date(value);
      const age = today.getFullYear() - birthDate.getFullYear();
      const monthDiff = today.getMonth() - birthDate.getMonth();
      const isAgeValid =
        age > 16 ||
        (age === 16 &&
          monthDiff >= 0 &&
          today.getDate() >= birthDate.getDate());

      if (!isAgeValid) {
        showErrorMessage("You must be at least 16 years old.");
        return;
      }
    }

    setVendorDetails({
      ...vendorDetails,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading

    // Fetch userId and token from local storage
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");

    if (!vendorDetails.email || !userId || !token) {
      showErrorMessage("Vendor information missing");
      setLoading(false); // Reset loading if there's an error
      return;
    }

    try {
      // Submit vendor details
      const response = await fetch(
        `${API_BASE_URL}/vendor/addVendor/${userId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(vendorDetails),
        }
      );

      if (response.ok) {
        // Upload the image
        if (image) {
          const formData = new FormData();
          formData.append("image", image);

          const imageUploadResponse = await fetch(
            `${API_BASE_URL}/vendor/uploadImage/${userId}`,
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${token}`,
              },
              body: formData,
            }
          );

          if (!imageUploadResponse.ok) {
            console.error(
              "Failed to upload image:",
              imageUploadResponse.statusText
            );
            showErrorMessage("Failed to upload the image. Please try again.");
            setLoading(false); // Reset loading if there's an error
            return;
          }
        }

        // Upload the driving license file
        if (drivingLicense) {
          const licenseFormData = new FormData();
          licenseFormData.append("file", drivingLicense);

          const licenseUploadResponse = await fetch(
            `${API_BASE_URL}/vendor/uploadIdentificationFile/${userId}`,
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${token}`,
              },
              body: licenseFormData,
            }
          );

          if (!licenseUploadResponse.ok) {
            console.error(
              "Failed to upload driving license:",
              licenseUploadResponse.statusText
            );
            showErrorMessage(
              "Failed to upload the driving license. Please try again."
            );
            setLoading(false); // Reset loading if there's an error
            return;
          }
        }

        showSuccessMessage(
          "Congratulations! Your profile has been created successfully."
        );
        localStorage.setItem("isLoggedIn", true);
        navigate(`/vendor-profile/my-profile/${userId}`);
        // Refresh the page after navigation
        window.location.reload();
      } else {
        console.error("Failed to submit vendor details:", response.statusText);
        showErrorMessage("Failed to submit your profile. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      showErrorMessage(
        "An error occurred while submitting your profile. Please try again."
      );
    } finally {
      setLoading(false); // Reset loading when done
    }
  };

  // State for handling expansion/collapse
  const [expandedSections, setExpandedSections] = useState({
    personalInfo: true,
    addressInfo: false,
    identification: false,
    terms: false,
  });

  const toggleSection = (section) => {
    setExpandedSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  const requiredFields = [
    "firstName",
    "lastName",
    "email",
    "dateOfBirth",
    "sex",
    "contactNo",
    "country",
    "city",
    "streetNo",
    "licenseNumber",
    "transitNumber",
    "accountNumber",
    "institutionNumber",
    "terms",
  ];

  const isFormValid = requiredFields.every((field) => vendorDetails[field]);

  return (
    <div className='bg-white p-4 rounded-lg shadow-md max-w-3xl mx-auto mt-24 mb-10'>
      {/* Header */}
      <div className='bg-blue-500 py-4 rounded-lg'>
        <h2 className='text-3xl font-bold text-white mb-6 text-center '>
          Vendor Onboarding
        </h2>
      </div>

      {/* Form Content */}
      <div className='mt-8'>
        {/* Personal Information Section */}
        <div className='mb-6'>
          <div
            className='flex justify-between items-center bg-gray-100 border border-gray-300 rounded-lg p-4 shadow-md cursor-pointer hover:bg-gray-200'
            onClick={() => toggleSection("personalInfo")}
          >
            <h3 className='text-xl font-semibold text-gray-800'>
              Personal Information
            </h3>
            <button
              className='text-lg font-bold text-gray-600 focus:outline-none hover:text-gray-800'
              aria-label='Toggle Personal Information'
            >
              {expandedSections.personalInfo ? "-" : "+"}
            </button>
          </div>
          {expandedSections.personalInfo && (
            <div className='p-4 bg-white border border-gray-300 rounded-lg shadow-md'>
              {/* Email (Read-Only) */}
              <div className='mb-6'>
                <input
                  type='email'
                  name='email'
                  value={vendorDetails.email}
                  readOnly
                  className='mt-2 p-3 block w-full border rounded-md bg-gray-100 cursor-not-allowed text-gray-600'
                />
              </div>

              {/* Name Fields */}
              <div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
                <div className='mb-4'>
                  <div className='flex justify-between items-center'>
                    <span className='text-gray-800 font-medium'>
                      First name
                    </span>
                    <span className='text-red-500'>*</span>
                  </div>
                  <input
                    type='text'
                    name='firstName'
                    value={vendorDetails.firstName}
                    onChange={handleChange}
                    className='mt-2 p-3 block w-full border rounded-md focus:ring-blue-500 focus:border-blue-500'
                    required
                  />
                </div>
                <div className='mb-4'>
                  <div className='flex justify-between items-center'>
                    <span className='text-gray-800 font-medium'>Last name</span>
                    <span className='text-red-500'>*</span>
                  </div>
                  <input
                    type='text'
                    name='lastName'
                    value={vendorDetails.lastName}
                    onChange={handleChange}
                    className='mt-2 p-3 block w-full border rounded-md focus:ring-blue-500 focus:border-blue-500'
                    required
                  />
                </div>
              </div>

              {/* Gender and Age Fields */}
              <div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
                <div>
                  <div className='flex justify-between items-center'>
                    <span className='text-gray-800 font-medium'>Gender</span>
                    <span className='text-red-500'>*</span>
                  </div>
                  <select
                    name='sex'
                    value={vendorDetails.sex}
                    onChange={handleChange}
                    className='mt-2 p-3 block w-full border rounded-md focus:ring-blue-500 focus:border-blue-500'
                    required
                  >
                    <option value='' disabled>
                      Select gender...
                    </option>
                    <option value='Male'>Male</option>
                    <option value='Female'>Female</option>
                    <option value='Other'>Other</option>
                  </select>
                </div>
                <div>
                  <div className='flex justify-between items-center'>
                    <span className='text-gray-800 font-medium'>
                      Date of Birth
                    </span>
                    <span className='text-red-500'>*</span>
                  </div>
                  <input
                    type='date'
                    name='dateOfBirth'
                    value={vendorDetails.dateOfBirth}
                    onChange={handleChange}
                    className='mt-2 p-3 block w-full border rounded-md focus:ring-blue-500 focus:border-blue-500'
                    required
                  />
                </div>
              </div>

              {/* Contact and Occupation */}
              <div className='grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4'>
                <div>
                  <label className='block text-gray-800 font-medium'>
                    Contact Number <span className='text-red-500'>*</span>
                  </label>
                  <input
                    type='tel'
                    name='contactNo'
                    value={vendorDetails.contactNo}
                    onChange={handleChange}
                    className='mt-2 p-3 block w-full border rounded-md focus:ring-blue-500 focus:border-blue-500'
                    required
                  />
                </div>
                <div>
                  <div className='flex justify-between items-center'>
                    <span className='text-gray-800 font-medium'>
                      Occupation
                    </span>
                    <span className='text-gray-600 font-medium'>
                      (optional)
                    </span>
                  </div>
                  <input
                    type='text'
                    name='occupation'
                    value={vendorDetails.occupation}
                    onChange={handleChange}
                    className='mt-2 p-3 block w-full border rounded-md focus:ring-blue-500 focus:border-blue-500'
                  />
                </div>
              </div>

              {/* Image Upload */}
              <div className='mt-4'>
                <label className='block text-gray-800 font-medium'>
                  Profile Image (Optional)
                </label>
                <input
                  type='file'
                  name='image'
                  onChange={handleImageChange}
                  className='mt-2 block w-full border rounded-md p-3 focus:ring-blue-500 focus:border-blue-500'
                />
              </div>
            </div>
          )}
        </div>

        {/* Address Information Section */}
        <div className='mb-6'>
          <div
            className='flex justify-between items-center bg-gray-200 p-4 rounded-md shadow-sm cursor-pointer'
            onClick={() => toggleSection("addressInfo")}
          >
            <h3 className='text-xl font-semibold text-gray-800'>
              Address Information
            </h3>
            <button
              className='text-lg font-bold text-gray-600 hover:text-gray-800 focus:outline-none'
              aria-label='Toggle Address Information'
            >
              {expandedSections.addressInfo ? "-" : "+"}
            </button>
          </div>
          {expandedSections.addressInfo && (
            <div className='bg-white p-4 border-t border-gray-300 rounded-b-md shadow-md'>
              {/* Street Numbers */}
              <div className='grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4'>
                <div>
                  <label className='block text-lg font-medium text-gray-700'>
                    <span className='text-red-500'>*</span> Street No 1
                  </label>
                  <input
                    type='text'
                    name='streetNo'
                    value={vendorDetails.streetNo}
                    onChange={handleChange}
                    className='mt-2 p-3 block w-full border border-gray-300 rounded-md focus:ring focus:ring-gray-200'
                    required
                  />
                </div>
                <div>
                  <label className='block text-lg font-medium text-gray-700'>
                    Street No 2 (Optional)
                  </label>
                  <input
                    type='text'
                    name='streetNo2'
                    value={vendorDetails.streetNo2}
                    onChange={handleChange}
                    className='mt-2 p-3 block w-full border border-gray-300 rounded-md focus:ring focus:ring-gray-200'
                  />
                </div>
              </div>
              {/* Province and Postal Code */}
              <div className='grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4'>
                <div>
                  <label className='block text-lg font-medium text-gray-700'>
                    Postal Code (Optional)
                  </label>
                  <input
                    type='text'
                    name='postalCode'
                    value={vendorDetails.postalCode}
                    onChange={handleChange}
                    className='mt-2 p-3 block w-full border border-gray-300 rounded-md focus:ring focus:ring-gray-200'
                  />
                </div>
                <div>
                  <label className='block text-lg font-medium text-gray-700'>
                    Province (Optional)
                  </label>
                  <input
                    type='text'
                    name='province'
                    value={vendorDetails.province}
                    onChange={handleChange}
                    className='mt-2 p-3 block w-full border border-gray-300 rounded-md focus:ring focus:ring-gray-200'
                  />
                </div>
              </div>
              {/* Country and City in One Row */}
              <div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
                <div>
                  <label className='block text-lg font-medium text-gray-700'>
                    <span className='text-red-500'>*</span> Country
                  </label>
                  <input
                    type='text'
                    name='country'
                    value={vendorDetails.country}
                    onChange={handleChange}
                    className='mt-2 p-3 block w-full border border-gray-300 rounded-md focus:ring focus:ring-gray-200'
                    required
                  />
                </div>
                <div>
                  <label className='block text-lg font-medium text-gray-700'>
                    <span className='text-red-500'>*</span> City
                  </label>
                  <input
                    type='text'
                    name='city'
                    value={vendorDetails.city}
                    onChange={handleChange}
                    className='mt-2 p-3 block w-full border border-gray-300 rounded-md focus:ring focus:ring-gray-200'
                    required
                  />
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Identification Section */}
        <div className='mb-6'>
          <div
            className='flex justify-between items-center bg-gray-100 border border-gray-300 rounded-lg p-4 shadow-md cursor-pointer hover:bg-gray-200'
            onClick={() => toggleSection("identification")}
          >
            <h3 className='text-xl font-semibold text-gray-800'>
              Identification
            </h3>
            <button
              className='text-lg font-bold text-gray-600 focus:outline-none hover:text-gray-800'
              aria-label='Toggle Identification Section'
            >
              {expandedSections.identification ? "-" : "+"}
            </button>
          </div>
          {expandedSections.identification && (
            <div className='p-4 bg-white border border-gray-300 rounded-lg shadow-md'>
              {/* License Number */}
              <div className='mb-4'>
                <div className='flex justify-between items-center'>
                  <span className='text-gray-800 font-medium'>
                    Driving License Number
                  </span>
                  <span className='text-red-500'>*</span>
                </div>
                <input
                  type='text'
                  name='licenseNumber'
                  value={vendorDetails.licenseNumber}
                  onChange={handleChange}
                  className='mt-2 p-3 block w-full border rounded-md focus:ring-blue-500 focus:border-blue-500'
                  placeholder='Enter your driving license number'
                  required
                />
              </div>

              {/* Driving License File Upload */}
              <div className='mb-4'>
                <div className='flex justify-between items-center'>
                  <span className='text-gray-800 font-medium'>
                    Upload Driving License
                  </span>
                  <span className='text-red-500'>*</span>
                </div>
                <p className='text-sm text-gray-500 mt-1'>
                  Please ensure the file includes both the front and back sides
                  of your driving license.
                </p>

                <input
                  type='file'
                  accept='.pdf,.jpg,.jpeg,.png,.doc,.docx'
                  onChange={(e) => setDrivingLicense(e.target.files[0])}
                  className='mt-2 p-3 block w-full border rounded-md focus:ring-blue-500 focus:border-blue-500'
                  required
                />

                <p className='text-sm text-gray-500 mt-1'>
                  Accepted file types: PDF, DOC, DOCX, JPG, JPEG, PNG.
                </p>
              </div>

              {/* Account Number */}
              <div className='mt-4'>
                <div className='flex justify-between items-center'>
                  <span className='text-gray-800 font-medium'>
                    Bank Account Number
                  </span>
                  <span className='text-red-500'>*</span>
                </div>
                <input
                  type='text'
                  name='accountNumber'
                  value={vendorDetails.accountNumber}
                  onChange={handleChange}
                  className='mt-2 p-3 block w-full border rounded-md focus:ring-blue-500 focus:border-blue-500'
                  placeholder='Enter your account number'
                  required
                />
              </div>

              {/* Transit Number and Institution Number */}
              <div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
                <div>
                  <div className='flex justify-between items-center'>
                    <span className='text-gray-800 font-medium'>
                      Bank Transit Number
                    </span>
                    <span className='text-red-500'>*</span>
                  </div>
                  <input
                    type='text'
                    name='transitNumber'
                    value={vendorDetails.transitNumber}
                    onChange={handleChange}
                    className='mt-2 p-3 block w-full border rounded-md focus:ring-blue-500 focus:border-blue-500'
                    placeholder='e.g., 12345'
                    required
                  />
                  <p className='text-sm text-gray-500 mt-1'>
                    A five-digit number identifying the branch.
                  </p>
                </div>
                <div>
                  <div className='flex justify-between items-center'>
                    <span className='text-gray-800 font-medium'>
                      Institution Number
                    </span>
                    <span className='text-red-500'>*</span>
                  </div>
                  <input
                    type='text'
                    name='institutionNumber'
                    value={vendorDetails.institutionNumber}
                    onChange={handleChange}
                    className='mt-2 p-3 block w-full border rounded-md focus:ring-blue-500 focus:border-blue-500'
                    placeholder='e.g., 123'
                    required
                  />
                  <p className='text-sm text-gray-500 mt-1'>
                    A three-digit number identifying the institution.
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Terms and Conditions Section */}
        <div className='mb-6'>
          <div
            className='flex justify-between items-center bg-gray-200 p-4 rounded-md shadow-sm cursor-pointer'
            onClick={() => toggleSection("terms")}
          >
            <h3 className='text-xl font-semibold text-gray-800'>
              Terms and Conditions
            </h3>
            <button
              className='text-lg font-bold text-gray-600 hover:text-gray-800 focus:outline-none'
              aria-label='Toggle Terms and Conditions'
            >
              {expandedSections.terms ? "-" : "+"}
            </button>
          </div>
          {expandedSections.terms && (
            <div className='bg-white p-4 border-t border-gray-300 rounded-b-md shadow-md'>
              <p className='text-gray-700'>
                Please read and accept the terms and conditions before
                proceeding.
              </p>
              <div className='mt-4 flex items-center'>
                <input
                  type='checkbox'
                  id='terms'
                  name='terms'
                  checked={vendorDetails.terms}
                  onChange={handleChange}
                  className='w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring focus:ring-blue-200 focus:outline-none'
                />
                <label
                  htmlFor='terms'
                  className='ml-2 text-sm font-medium text-gray-700'
                >
                  I have read and agree to the terms and conditions
                </label>
              </div>
              <button
                className='mt-4 text-blue-500 underline hover:text-blue-700'
                onClick={() => setModalOpen(true)}
              >
                Read Terms and Conditions
              </button>
            </div>
          )}
          {/* Modal Component */}
          <Modal
            isOpen={modalOpen}
            onClose={() => setModalOpen(false)}
            title='Terms and Conditions'
          >
            <TermsContent />
          </Modal>
        </div>

        {/* Create Profile Button */}
        <div className='mt-8'>
          <button
            onClick={handleSubmit}
            disabled={loading || !isFormValid}
            className={`w-full py-3 px-4 rounded-md text-white font-medium transition-colors ${
              loading || !isFormValid
                ? "bg-gray-400 cursor-not-allowed"
                : "bg-blue-600 hover:bg-blue-700"
            }`}
          >
            {loading ? (
              <span className='flex items-center justify-center'>
                <svg
                  className='animate-spin h-5 w-5 text-white mr-2'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                >
                  <circle
                    className='opacity-25'
                    cx='12'
                    cy='12'
                    r='10'
                    stroke='currentColor'
                    strokeWidth='4'
                  ></circle>
                  <path
                    className='opacity-75'
                    fill='currentColor'
                    d='M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z'
                  ></path>
                </svg>
                Processing...
              </span>
            ) : (
              "Create Profile"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default VendorOnboarding;

