import React from "react";
import VendorTermsV1 from "./VendorTermsV1";

function VendorTerms() {
  return (
    <div className='mt-24'>
      <VendorTermsV1 />
    </div>
  );
}

export default VendorTerms;

