import { useReducer } from 'react';

const initialState = {
    alert: { msg: '' },
};

const appReducerFunction = (state, action) => {
    const { type, payload } = action;
    switch (type) {
        case "SET_ALERT":
            return {
                ...state,
                alert: payload ?? { msg: '' },
            };
        default:
            console.error('No reducer found for action: ', action);
            return state;
    }
};

export const useAppReducer = () => {
    const [state, dispatch] = useReducer(appReducerFunction, initialState);

    return { state, dispatch };
};