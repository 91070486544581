import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import API_BASE_URL from "../../apiConfig";

const CustomerDetails = () => {
  const [customer, setCustomer] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCustomerDetails = async () => {
      try {
        const email = localStorage.getItem("email");
        const token = localStorage.getItem("token");

        if (!email || !token) {
          throw new Error("Authentication details missing.");
        }

        const response = await axios.get(`${API_BASE_URL}/customers/${email}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data) {
          setCustomer(response.data);
        } else {
          throw new Error("No user data found.");
        }
      } catch (error) {
        console.error("Failed to fetch customer details", error);
        setError("Failed to fetch customer details.");
        setShowModal(true);

        const userId = localStorage.getItem("userId");

        setTimeout(() => {
          navigate(`/customer-onboarding/${userId}`);
          setTimeout(() => {
            window.location.reload(); // Reload the page after navigation
          }, 500); // Slight delay to ensure navigation is complete
        }, 2000); // Redirect after 1 second
      } finally {
        setLoading(false);
      }
    };

    fetchCustomerDetails();
  }, [navigate]);

  const handleEdit = () => {
    alert("Edit functionality to be implemented.");
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {showModal && (
        <div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50'>
          <div className='bg-white p-8 rounded-lg shadow-2xl w-96 text-center'>
            {/* Error Icon */}
            <div className='flex justify-center mb-4'>
              <svg
                className='w-12 h-12 text-red-600'
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                  d='M13.828 12l3.182-3.182a2 2 0 10-2.828-2.828L12 9.172 8.818 5.99a2 2 0 00-2.828 2.828L10.172 12l-3.182 3.182a2 2 0 102.828 2.828L12 14.828l3.182 3.182a2 2 0 002.828-2.828L13.828 12z'
                />
              </svg>
            </div>
            {/* Modal Content */}
            <h2 className='text-xl font-bold text-gray-800'>
              Missing Information
            </h2>
            <p className='text-gray-600 mt-2'>
              Redirecting to the onboarding page...
            </p>
            {/* Spinner */}
            <div className='flex justify-center mt-4'>
              <div className='animate-spin rounded-full h-8 w-8 border-t-4 border-red-600 border-opacity-70'></div>
            </div>
          </div>
        </div>
      )}

      {!showModal && error && <div>Error: {error}</div>}

      {!showModal && customer && (
        <div className='flex flex-col space-y-6 p-6 bg-gray-100 rounded-lg shadow-lg max-w-4xl mx-auto'>
          {/* Profile header section */}
          <div className='flex justify-between items-center'>
            <div className='flex flex-col space-y-2'>
              <h2 className='text-2xl font-semibold text-gray-800'>
                Customer Profile
              </h2>
              <p className='text-gray-600'>Manage your profile information</p>
            </div>
          </div>

          {/* Profile details section */}
          <div className='grid grid-cols-1 md:grid-cols-2 gap-6'>
            <div className='bg-white border rounded-lg shadow-md p-4'>
              <table className='w-full text-left'>
                <tbody>
                  <tr>
                    <td className='font-semibold text-gray-700 py-2'>Name:</td>
                    <td className='text-gray-800'>{customer.name}</td>
                  </tr>
                  <tr>
                    <td className='font-semibold text-gray-700 py-2'>Email:</td>
                    <td className='text-gray-800'>{customer.email}</td>
                  </tr>
                  <tr>
                    <td className='font-semibold text-gray-700 py-2'>Age:</td>
                    <td className='text-gray-800'>{customer.age}</td>
                  </tr>
                  <tr>
                    <td className='font-semibold text-gray-700 py-2'>
                      Gender:
                    </td>
                    <td className='text-gray-800'>{customer.sex}</td>
                  </tr>
                  <tr>
                    <td className='font-semibold text-gray-700 py-2'>Phone:</td>
                    <td className='text-gray-800'>{customer.contactNo}</td>
                  </tr>
                  <tr>
                    <td className='font-semibold text-gray-700 py-2'>
                      Occupation:
                    </td>
                    <td className='text-gray-800'>{customer.occupation}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            {/* Addresses section */}
            <div className='bg-white border rounded-lg shadow-md p-4'>
              <h3 className='font-semibold text-gray-700 mb-4'>Addresses</h3>
              {customer.addresses && customer.addresses.length > 0 ? (
                <table className='w-full text-left'>
                  <tbody>
                    {customer.addresses.map((address, index) => (
                      <React.Fragment key={index}>
                        <tr>
                          <td className='font-semibold text-gray-700 py-2'>
                            Address Line 1:
                          </td>
                          <td className='text-gray-800'>
                            {address.addressLine1}
                          </td>
                        </tr>
                        <tr>
                          <td className='font-semibold text-gray-700 py-2'>
                            Address Line 2:
                          </td>
                          <td className='text-gray-800'>
                            {address.addressLine2}
                          </td>
                        </tr>
                        <tr>
                          <td className='font-semibold text-gray-700 py-2'>
                            City:
                          </td>
                          <td className='text-gray-800'>{address.city}</td>
                        </tr>
                        <tr>
                          <td className='font-semibold text-gray-700 py-2'>
                            State:
                          </td>
                          <td className='text-gray-800'>{address.state}</td>
                        </tr>
                        <tr>
                          <td className='font-semibold text-gray-700 py-2'>
                            Postal Code:
                          </td>
                          <td className='text-gray-800'>
                            {address.postalCode}
                          </td>
                        </tr>
                        <tr>
                          <td className='font-semibold text-gray-700 py-2'>
                            Country:
                          </td>
                          <td className='text-gray-800'>{address.country}</td>
                        </tr>
                        {index < customer.addresses.length - 1 && (
                          <tr>
                            <td colSpan='2'>
                              <hr className='my-4' />
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p className='text-gray-600'>No addresses found.</p>
              )}
            </div>
          </div>

          <button
            onClick={handleEdit}
            className='self-center py-2 px-6 bg-cyan-600 text-white rounded-lg hover:bg-cyan-700 transition'
          >
            Edit Profile
          </button>
        </div>
      )}
    </>
  );
};

export default CustomerDetails;

