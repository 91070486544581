import { createContext } from 'react';

const initialValue = {
    state: {
        alert: { msg: '' },
    },
    dispatch: () => ({}),
};


export const ApplicationContext = createContext(initialValue);