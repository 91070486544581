import React, { useState, useEffect } from "react";
import API_BASE_URL from "../../apiConfig";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CartItem = ({ item, onAdd, onRemove }) => {
  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    const fetchCuisineImage = async () => {
      const token = localStorage.getItem("token");

      try {
        const response = await fetch(
          `${API_BASE_URL}/cuisine/downloadImage/${item.cuisineId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.ok) {
          const imageBlob = await response.blob();
          const imageObjectUrl = URL.createObjectURL(imageBlob);
          setImageUrl(imageObjectUrl); // Set the image URL in state
        } else {
          console.error("Failed to fetch image");
        }
      } catch (error) {
        console.error("Error fetching cuisine image:", error);
      }
    };

    fetchCuisineImage();
  }, [item.cuisineId]);

  return (
    <div className='flex flex-col md:flex-row items-center justify-between p-6 bg-white rounded-lg shadow-lg border border-gray-200 hover:shadow-xl transition duration-300'>
      {/* Left Section: Image and Details */}
      <div className='flex items-center space-x-6 w-full md:w-auto'>
        {imageUrl ? (
          <img
            src={imageUrl}
            alt={item.cuisineName}
            className='w-24 h-24 md:w-32 md:h-32 object-cover rounded-lg border border-gray-300'
          />
        ) : (
          <div className='w-24 h-24 md:w-32 md:h-32 bg-gray-200 rounded-lg flex items-center justify-center'>
            <span className='text-gray-500'>No Image</span>
          </div>
        )}
        <div className='flex flex-col space-y-2'>
          <h2 className='text-lg md:text-xl font-semibold text-gray-900'>
            {item.cuisineName}
          </h2>
          <p className='text-sm md:text-md text-gray-700'>
            Price:{" "}
            <span className='font-bold text-gray-900'>
              ${item.cuisinePrice}
            </span>
          </p>
          <div className='flex items-center space-x-3'>
            <p className='text-sm md:text-md text-gray-700'>Quantity:</p>
            <span className='px-3 py-1 bg-blue-100 text-blue-700 text-sm font-medium rounded-lg'>
              {item.quantity}
            </span>
          </div>
          <p className='text-sm md:text-md text-gray-700'>
            Prep Time:{" "}
            <span className='font-semibold text-gray-900'>
              {item.readyTime}
            </span>
          </p>
        </div>
      </div>

      {/* Right Section: Action Buttons */}
      <div className='flex space-x-4 mt-4 md:mt-0 md:ml-4 w-full md:w-auto justify-center'>
        <button
          onClick={() => onAdd(item.cuisineId)}
          className='w-10 h-10 md:w-12 md:h-12 text-lg bg-gradient-to-r from-green-400 to-green-600 text-white rounded-full flex items-center justify-center shadow-lg hover:shadow-2xl hover:scale-110 transition-transform'
        >
          +
        </button>
        <button
          onClick={() => onRemove(item.cuisineId)}
          className={`w-10 h-10 md:w-12 md:h-12 text-lg text-white rounded-full flex items-center justify-center shadow-lg hover:shadow-2xl hover:scale-110 transition-transform ${
            item.quantity === 1
              ? "bg-gradient-to-r from-red-400 to-red-600"
              : "bg-gradient-to-r from-red-300 to-red-500"
          }`}
        >
          {item.quantity === 1 ? <FontAwesomeIcon icon={faTrashAlt} /> : "-"}
        </button>
      </div>
    </div>
  );
};

export default CartItem;

