const Modal = ({ isOpen, onClose, title, children }) => {
  if (!isOpen) return null;
  return (
    <div className='fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50'>
      <div className='bg-white w-11/12 md:w-1/2 p-6 rounded-lg shadow-lg relative'>
        <h2 className='text-xl font-bold mb-4'>{title}</h2>
        <button
          className='absolute top-4 right-4 text-gray-600 hover:text-gray-800'
          onClick={onClose}
        >
          ✖
        </button>
        <div className='overflow-y-auto max-h-96'>{children}</div>
      </div>
    </div>
  );
};

export default Modal;
