import React from "react";
import { Routes, Route } from "react-router-dom";
import ProfileNavigation from "./ProfileNavigation";
import OrderManagement from "./OrderManagement";
import MenuManagement from "./MenuManagement";
import RestaurantManagement from "./RestaurantManagement";
// import ReportsAndAnalytics from './ReportsAndAnalytics'; faEnvelopeOpen
// import OffersAndPromo from './OffersAndPromo'; faMagnifyingGlassDollar

import {
  faShoppingBag,
  faBowlFood,
  faStore,
} from "@fortawesome/free-solid-svg-icons";

const userId = localStorage.getItem("userId");

const vendor_menu = [
  {
    title: "Restaurant Management",
    icon: faStore,
    path: `/vendor-restoura/my-restaurants/${userId}`,
  },
  {
    title: "Order Management",
    icon: faBowlFood,
    path: `/vendor-restoura/order-management/${userId}`,
  },
  {
    title: "Menu Management",
    icon: faShoppingBag,
    path: `/vendor-restoura/menu-management/${userId}`,
  },
  // { title: "Offers/Promo", icon: faEnvelopeOpen, path: `/vendor-restoura/offers-promo/${userId}` },
  // { title: "Report & Analytics", icon: faMagnifyingGlassDollar, path: `/vendor-restoura/report-analytics/${userId}` }
];

const VendorRestoura = () => {
  return (
    <div className='flex flex-col md:flex-row mt-12'>
      <ProfileNavigation menu={vendor_menu} />
      <div className='flex-1 p-4 md:p-8'>
        <Routes>
          <Route path='my-restaurants/:id' element={<RestaurantManagement />} />
          <Route path='order-management/:id' element={<OrderManagement />} />
          <Route path='menu-management/:id' element={<MenuManagement />} />
          {/* <Route path="report-analytics/:id" element={<ReportsAndAnalytics />} />
          <Route path="offers-promo/:id" element={<OffersAndPromo />} /> */}
        </Routes>
      </div>
    </div>
  );
};

export default VendorRestoura;

