import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEyeSlash,
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import API_BASE_URL from "../../apiConfig";
import useUtilService from "../../services/useUtilService";

const Register = () => {
  const [email, setEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [role, setRole] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const [passwordMatchError, setPasswordMatchError] = useState("");
  const [passwordLengthError, setPasswordLengthError] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const { showSuccessMessage, showErrorMessage } = useUtilService();
  const navigate = useNavigate();

  const MIN_PASSWORD_LENGTH = 8;

  const handleRegister = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError("");
    setPasswordMatchError("");
    setPasswordLengthError("");

    if (
      email === "" ||
      password === "" ||
      confirmPassword === "" ||
      role === "" ||
      userName === ""
    ) {
      setError("Please fill in all fields.");
      setIsLoading(false);
      return;
    }

    if (password.length < MIN_PASSWORD_LENGTH) {
      setPasswordLengthError(
        `Password must be at least ${MIN_PASSWORD_LENGTH} characters long.`
      );
      setIsLoading(false);
      return;
    }

    if (password !== confirmPassword) {
      setPasswordMatchError("Passwords do not match.");
      setIsLoading(false);
      return;
    }

    const requestBody = {
      email: email,
      password: password,
      role: role,
      userName: userName,
    };

    try {
      const response = await fetch(`${API_BASE_URL}/auth/signup`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (response.status === 400) {
        const errorMessage = await response.text();
        if (errorMessage === "Account already exists") {
          showSuccessMessage("Account already verified, please log in.");
          navigate("/login");
        } else {
          setError(errorMessage);
        }
        return;
      }

      if (response.status === 201) {
        const message = await response.text();
        if (message.includes("Account Updated")) {
          showSuccessMessage(message);
          navigate("/login");
        } else {
          localStorage.setItem("email", email);
          localStorage.setItem("role", role);
          navigate("/user-verification");
        }
      }
    } catch (error) {
      console.error("Registration error:", error.message);
      showErrorMessage(
        "An error occurred during registration. Please try again."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  return (
    <div className='flex items-center justify-center bg-gray-100 py-10 mt-16'>
      <div className='bg-white p-8 rounded-lg shadow-lg w-full max-w-md'>
        <h2 className='text-2xl font-semibold text-center mb-6'>
          Join Restoura Today!
        </h2>
        <form onSubmit={handleRegister} className='space-y-6'>
          {error && <p className='text-red-500'>{error}</p>}
          <div className='space-y-1'>
            <label className='block text-sm font-medium text-gray-700'>
              Email
            </label>
            <input
              type='email'
              className='w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className='space-y-1'>
            <label className='block text-sm font-medium text-gray-700'>
              Username
            </label>
            <input
              type='text'
              className='w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500'
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              required
            />
          </div>
          <div className='space-y-1'>
            <label className='block text-sm font-medium text-gray-700'>
              Password
            </label>
            <div className='relative'>
              <input
                id='password'
                type={passwordVisible ? "text" : "password"}
                className='w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <span
                className='absolute inset-y-0 right-0 flex items-center pr-2 cursor-pointer'
                onClick={togglePasswordVisibility}
              >
                <FontAwesomeIcon icon={passwordVisible ? faEye : faEyeSlash} />
              </span>
            </div>
            {password.length > 0 && (
              <p
                className={`text-sm mt-1 ${
                  password.length >= MIN_PASSWORD_LENGTH
                    ? "text-green-600"
                    : "text-red-500"
                }`}
              >
                {password.length >= MIN_PASSWORD_LENGTH ? (
                  <>
                    <FontAwesomeIcon icon={faCheckCircle} className='mr-1' />
                    Password meets the length requirement.
                  </>
                ) : (
                  <>
                    <FontAwesomeIcon icon={faTimesCircle} className='mr-1' />
                    Password must be at least {MIN_PASSWORD_LENGTH} characters
                    long.
                  </>
                )}
              </p>
            )}
            {passwordLengthError && (
              <p className='text-red-500 text-sm'>{passwordLengthError}</p>
            )}
          </div>

          <div className='space-y-1'>
            <label className='block text-sm font-medium text-gray-700'>
              Confirm Password
            </label>
            <div className='relative'>
              <input
                type={confirmPasswordVisible ? "text" : "password"}
                className='w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500'
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
              <span
                className='absolute inset-y-0 right-0 flex items-center pr-2 cursor-pointer'
                onClick={toggleConfirmPasswordVisibility}
              >
                <FontAwesomeIcon
                  icon={confirmPasswordVisible ? faEye : faEyeSlash}
                />
              </span>
            </div>
            {passwordMatchError && (
              <p className='text-red-500 text-sm'>{passwordMatchError}</p>
            )}
          </div>

          <div className='space-y-1'>
            <label className='block text-sm font-medium text-gray-700'>
              Role
            </label>
            <select
              className='w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500'
              value={role}
              onChange={(e) => setRole(e.target.value)}
              required
            >
              <option value='' disabled>
                Select Role
              </option>
              <option value='customer'>Customer</option>
              <option value='vendor'>Vendor</option>
            </select>
          </div>

          <button
            type='submit'
            className={`w-full bg-indigo-600 text-white py-2 rounded-lg flex items-center justify-center gap-2 transition duration-300 ${
              isLoading
                ? "cursor-not-allowed opacity-50"
                : "hover:bg-indigo-700"
            }`}
            disabled={isLoading}
            onClick={!isLoading ? handleRegister : undefined}
          >
            {isLoading && (
              <svg
                className='animate-spin h-5 w-5 text-white'
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
              >
                <circle
                  className='opacity-25'
                  cx='12'
                  cy='12'
                  r='10'
                  stroke='currentColor'
                  strokeWidth='4'
                ></circle>
                <path
                  className='opacity-75'
                  fill='currentColor'
                  d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.963 7.963 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                ></path>
              </svg>
            )}
            {isLoading ? "Registering..." : "Register"}
          </button>

          <div className='text-center mt-4'>
            <p>
              Already have an account?{" "}
              <a href='/login' className='text-indigo-600 hover:underline'>
                Login
              </a>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Register;

