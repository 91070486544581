import React from "react";
import { Routes, Route } from "react-router-dom";
import AdminProfileNavigation from "./AdminProfileNavigation";
import AdminApproval from "./AdminApproval";
import ReportsAndAnalytics from "./ReportsAndAnalytics";
import Transactions from "./Transactions";

const AdminProfile = () => {
  return (
    <div className='flex flex-col md:flex-row mt-20'>
      {" "}
      {/* Responsive layout */}
      <AdminProfileNavigation />
      <div className='flex-1 p-4 md:p-8'>
        {" "}
        {/* Adjust padding for mobile and desktop */}
        <Routes>
          <Route path='admin-approval' element={<AdminApproval />} />
          <Route path='transaction-history' element={<Transactions />} />
          <Route path='report-analytics' element={<ReportsAndAnalytics />} />
        </Routes>
      </div>
    </div>
  );
};

export default AdminProfile;

