import React, { useEffect, useState } from "react";
import API_BASE_URL from "../../apiConfig";

const AdminRestaurantApproval = () => {
  const [restaurants, setRestaurants] = useState([]);
  const [filteredRestaurants, setFilteredRestaurants] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedRestaurant, setSelectedRestaurant] = useState(null);
  const [isRestaurantModalOpen, setIsRestaurantModalOpen] = useState(false);
  const [ownerDetails, setOwnerDetails] = useState(null);
  const [isOwnerModalOpen, setIsOwnerModalOpen] = useState(false);
  const token = localStorage.getItem("token");

  useEffect(() => {
    fetchRestaurants(); // Fetch the restaurants when the component loads
  }, []);

  // Function to fetch all restaurants
  const fetchRestaurants = async () => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/restaurant/allRestaurants`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setRestaurants(data);
        setFilteredRestaurants(data); // Initialize filtered list
      } else {
        console.error("Failed to fetch restaurants");
      }
    } catch (error) {
      console.error("Error fetching restaurants:", error);
    }
  };

  // Function to fetch owner details by ownerId
  const fetchOwnerDetails = async (ownerId) => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/vendor/getVendor/${ownerId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const data = await response.json();

        // Fetch the file using the file download endpoint
        const fileResponse = await fetch(
          `${API_BASE_URL}/vendor/downloadIdentificationFile/${ownerId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (fileResponse.ok) {
          const fileBlob = await fileResponse.blob();

          // Create a URL for the file blob
          const fileUrl = URL.createObjectURL(fileBlob);

          // Add the file URL to the owner details
          data.fileUrl = fileUrl;
        } else {
          console.error("Failed to fetch the identification file");
          data.fileUrl = null;
        }

        setOwnerDetails(data);
        setIsOwnerModalOpen(true); // Open owner modal
      } else {
        console.error("Failed to fetch owner details");
      }
    } catch (error) {
      console.error("Error fetching owner details:", error);
    }
  };

  // Function to handle approval or rejection of a restaurant
  const handleApproval = async (restaurant, action) => {
    const confirmation = window.confirm(
      `Are you sure you want to ${action} this restaurant?`
    );
    if (!confirmation) return;

    const newStatus = action === "approve" ? "Approved" : "Rejected";

    try {
      const response = await fetch(
        `${API_BASE_URL}/restaurant/updateStatus/${restaurant.id}?status=${newStatus}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        alert(`Restaurant ${newStatus.toLowerCase()} successfully`);
        fetchRestaurants(); // Refresh the restaurant list after status change
      } else {
        console.error("Failed to update status");
      }
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  // Function to handle status filter
  const handleStatusFilter = (status) => {
    setSelectedStatus(status);
    if (status === "") {
      setFilteredRestaurants(restaurants); // Reset to full list
    } else {
      setFilteredRestaurants(
        restaurants.filter((restaurant) => restaurant.status === status)
      );
    }
  };

  // Function to handle details button click (open restaurant modal)
  const handleShowRestaurantDetails = (restaurant) => {
    setSelectedRestaurant(restaurant);
    setIsRestaurantModalOpen(true);
  };

  // Function to close the restaurant modal
  const handleCloseRestaurantModal = () => {
    setIsRestaurantModalOpen(false);
    setSelectedRestaurant(null);
  };

  // Function to close the owner modal
  const handleCloseOwnerModal = () => {
    setIsOwnerModalOpen(false);
    setOwnerDetails(null);
  };

  return (
    <div className='p-6'>
      <h2 className='text-3xl font-bold mb-6 text-center'>
        Admin Restaurant Approval
      </h2>

      {/* Filter radio buttons */}
      <div className='mb-6'>
        <label className='font-semibold text-lg mr-6'>Filter by status:</label>
        <div className='inline-flex space-x-6 flex-wrap'>
          <label className='flex items-center space-x-2'>
            <input
              type='radio'
              name='status'
              value=''
              checked={selectedStatus === ""}
              onChange={() => handleStatusFilter("")}
              className='form-radio h-4 w-4 text-blue-600'
            />
            <span className='text-gray-700'>All</span>
          </label>
          <label className='flex items-center space-x-2'>
            <input
              type='radio'
              name='status'
              value='Waiting for Approval'
              checked={selectedStatus === "Waiting for Approval"}
              onChange={() => handleStatusFilter("Waiting for Approval")}
              className='form-radio h-4 w-4 text-blue-600'
            />
            <span className='text-gray-700'>Waiting for Approval</span>
          </label>
          <label className='flex items-center space-x-2'>
            <input
              type='radio'
              name='status'
              value='Approved'
              checked={selectedStatus === "Approved"}
              onChange={() => handleStatusFilter("Approved")}
              className='form-radio h-4 w-4 text-blue-600'
            />
            <span className='text-gray-700'>Approved</span>
          </label>
          <label className='flex items-center space-x-2'>
            <input
              type='radio'
              name='status'
              value='Rejected'
              checked={selectedStatus === "Rejected"}
              onChange={() => handleStatusFilter("Rejected")}
              className='form-radio h-4 w-4 text-blue-600'
            />
            <span className='text-gray-700'>Rejected</span>
          </label>
        </div>
      </div>

      {/* Table */}
      <div className='overflow-x-auto shadow-md rounded-lg'>
        <table className='min-w-full bg-white'>
          <thead className='bg-gray-200'>
            <tr className='text-left text-sm font-semibold text-gray-700'>
              <th className='py-3 px-6'>Restaurant ID</th>
              <th className='py-3 px-6'>Restaurant Name</th>
              <th className='py-3 px-6'>Status</th>
              <th className='py-3 px-6'>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredRestaurants.map((restaurant) => (
              <tr key={restaurant.id} className='border-b hover:bg-gray-100'>
                <td className='py-3 px-6'>{restaurant.id}</td>
                <td className='py-3 px-6'>{restaurant.name}</td>
                <td className='py-3 px-6'>
                  <span
                    className={`px-3 py-1 rounded-full text-sm font-medium ${
                      restaurant.status === "Approved"
                        ? "bg-green-200 text-green-800"
                        : restaurant.status === "Waiting for Approval"
                        ? "bg-yellow-200 text-yellow-800"
                        : "bg-red-200 text-red-800"
                    }`}
                  >
                    {restaurant.status}
                  </span>
                </td>
                <td className='py-3 px-6'>
                  {/* Details Button */}
                  <button
                    onClick={() => handleShowRestaurantDetails(restaurant)}
                    className='bg-gray-500 text-white px-4 py-2 rounded-lg shadow hover:bg-gray-600 hover:shadow-md transition-all duration-300 ease-in-out mr-3'
                  >
                    Restaurant Details
                  </button>

                  {/* View Owner Button */}
                  <button
                    onClick={() => fetchOwnerDetails(restaurant.ownerId)}
                    className='bg-blue-500 text-white px-4 py-2 rounded-lg shadow hover:bg-blue-600 hover:shadow-md transition-all duration-300 ease-in-out mr-3'
                  >
                    Owner Details
                  </button>

                  {/* Conditional Buttons for Approval */}
                  {restaurant.status === "Waiting for Approval" && (
                    <>
                      {/* Approve Button */}
                      <button
                        onClick={() => handleApproval(restaurant, "approve")}
                        className='bg-green-500 text-white px-4 py-2 rounded-lg shadow hover:bg-green-600 hover:shadow-md transition-all duration-300 ease-in-out mr-3'
                      >
                        Approve
                      </button>

                      {/* Reject Button */}
                      <button
                        onClick={() => handleApproval(restaurant, "reject")}
                        className='bg-red-500 text-white px-4 py-2 rounded-lg shadow hover:bg-red-600 hover:shadow-md transition-all duration-300 ease-in-out'
                      >
                        Reject
                      </button>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Restaurant Details Modal */}
      {isRestaurantModalOpen && selectedRestaurant && (
        <div className='fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50'>
          <div className='relative bg-white rounded-lg shadow-xl max-w-3xl w-full md:h-[80vh] lg:h-[70vh] xl:h-[60vh] h-auto overflow-y-auto p-6 md:p-8'>
            {/* Close Button */}
            <button
              onClick={handleCloseRestaurantModal}
              className='absolute top-4 right-4 text-gray-500 hover:text-gray-800 focus:outline-none'
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='h-6 w-6'
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth={2}
                  d='M6 18L18 6M6 6l12 12'
                />
              </svg>
            </button>

            <h3 className='text-3xl font-bold text-center mb-8 text-gray-800'>
              Restaurant Details
            </h3>

            {/* General Information Section */}
            <div className='mb-6'>
              <h4 className='text-xl font-semibold text-gray-700 border-b pb-2 mb-4'>
                General Information
              </h4>
              <p className='text-sm mb-2'>
                <strong>ID:</strong> {selectedRestaurant.id}
              </p>
              <p className='text-sm mb-2'>
                <strong>Name:</strong> {selectedRestaurant.name}
              </p>
              <p className='text-sm mb-2'>
                <strong>Cuisine Type:</strong> {selectedRestaurant.cuisineType}
              </p>
              <p className='text-sm mb-2'>
                <strong>Description:</strong>{" "}
                {selectedRestaurant.description || "N/A"}
              </p>
              <p className='text-sm mb-2'>
                <strong>Status:</strong> {selectedRestaurant.status}
              </p>
              <p className='text-sm mb-2'>
                <strong>Operating Hours:</strong>{" "}
                {selectedRestaurant.operatingHours || "N/A"}
              </p>
              <p className='text-sm mb-2'>
                <strong>Open Now:</strong>{" "}
                {selectedRestaurant.openOrClosed ? "Yes" : "No"}
              </p>
            </div>

            {/* Contact Information Section */}
            <div className='mb-6'>
              <h4 className='text-xl font-semibold text-gray-700 border-b pb-2 mb-4'>
                Contact Information
              </h4>
              <p className='text-sm mb-2'>
                <strong>Email:</strong> {selectedRestaurant.email}
              </p>
              <p className='text-sm mb-2'>
                <strong>Contact No:</strong>{" "}
                {selectedRestaurant.contactNo || "N/A"}
              </p>
            </div>

            {/* Address Section */}
            <div className='mb-6'>
              <h4 className='text-xl font-semibold text-gray-700 border-b pb-2 mb-4'>
                Address
              </h4>
              <p className='text-sm mb-2'>{selectedRestaurant.addressLine1}</p>
              {selectedRestaurant.addressLine2 && (
                <p className='text-sm mb-2'>
                  {selectedRestaurant.addressLine2}
                </p>
              )}
              <p className='text-sm mb-2'>
                {selectedRestaurant.city}, {selectedRestaurant.state},{" "}
                {selectedRestaurant.postalCode}
              </p>
              <p className='text-sm mb-2'>{selectedRestaurant.country}</p>
              {selectedRestaurant.placeName && (
                <p className='text-sm mb-2'>
                  <strong>Place Name:</strong> {selectedRestaurant.placeName}
                </p>
              )}
            </div>

            {/* Services Section */}
            <div>
              <h4 className='text-xl font-semibold text-gray-700 border-b pb-2 mb-4'>
                Services
              </h4>
              <p className='text-sm mb-2'>
                <strong>Party Orders Available:</strong>{" "}
                {selectedRestaurant.partyOrderAvailable ? "Yes" : "No"}
              </p>
              <p className='text-sm mb-2'>
                <strong>Off-Hour Delivery:</strong>{" "}
                {selectedRestaurant.offHourDeliveryAvailable ? "Yes" : "No"}
              </p>
            </div>
          </div>
        </div>
      )}

      {/* Owner Details Modal */}
      {isOwnerModalOpen && ownerDetails && (
        <div className='fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50'>
          <div className='bg-white p-6 rounded-lg max-w-3xl w-full max-h-[80vh] overflow-y-auto shadow-lg relative'>
            {/* Close Button */}
            <button
              onClick={handleCloseOwnerModal}
              className='absolute top-4 right-4 text-gray-500 hover:text-gray-800 transition'
              aria-label='Close'
            >
              ✖
            </button>

            {/* Modal Header */}
            <h3 className='text-3xl font-semibold mb-6 text-center text-gray-800'>
              Owner Details
            </h3>

            {/* Personal Information Section */}
            <div className='mb-6 p-4 bg-white rounded-lg shadow-sm'>
              <h4 className='text-xl font-semibold text-gray-700 mb-4 border-b pb-2'>
                Personal Information
              </h4>
              <p className='text-sm text-gray-600 mb-2'>
                <strong>Name:</strong> {ownerDetails.firstName}{" "}
                {ownerDetails.lastName}
              </p>
              <p className='text-sm text-gray-600 mb-2'>
                <strong>Email:</strong> {ownerDetails.email}
              </p>
              <p className='text-sm text-gray-600 mb-2'>
                <strong>Contact No:</strong> {ownerDetails.contactNo}
              </p>
              <p className='text-sm text-gray-600 mb-2'>
                <strong>Gender:</strong> {ownerDetails.sex}
              </p>
              <p className='text-sm text-gray-600 mb-2'>
                <strong>Occupation:</strong> {ownerDetails.occupation}
              </p>
              <p className='text-sm text-gray-600 mb-2'>
                <strong>Date of Birth:</strong> {ownerDetails.dateOfBirth}
              </p>
            </div>

            {/* Address Information Section */}
            <div className='mb-6 p-4 bg-white rounded-lg shadow-sm'>
              <h4 className='text-xl font-semibold text-gray-700 mb-4 border-b pb-2'>
                Address Information
              </h4>
              <p className='text-sm text-gray-600 mb-2'>
                <strong>Street Address 1:</strong> {ownerDetails.streetNo}
              </p>
              {ownerDetails.streetNo2 && (
                <p className='text-sm text-gray-600 mb-2'>
                  <strong>Street Address 2:</strong> {ownerDetails.streetNo2}
                </p>
              )}
              <p className='text-sm text-gray-600 mb-2'>
                <strong>City:</strong> {ownerDetails.city}
              </p>
              <p className='text-sm text-gray-600 mb-2'>
                <strong>Postal Code:</strong> {ownerDetails.postalCode}
              </p>
              <p className='text-sm text-gray-600 mb-2'>
                <strong>Province:</strong> {ownerDetails.province}
              </p>
              <p className='text-sm text-gray-600 mb-2'>
                <strong>Country:</strong> {ownerDetails.country}
              </p>
            </div>

            {/* Identification Information Section */}
            <div className='mb-6 p-4 bg-white rounded-lg shadow-sm'>
              <h4 className='text-xl font-semibold text-gray-700 mb-4 border-b pb-2'>
                Identification Information
              </h4>
              <p className='text-sm text-gray-600 mb-2'>
                <strong>License Number:</strong> {ownerDetails.licenseNumber}
              </p>
              <p className='text-sm text-gray-600 mb-2'>
                <strong>Transit Number:</strong> {ownerDetails.transitNumber}
              </p>
              <p className='text-sm text-gray-600 mb-2'>
                <strong>Account Number:</strong> {ownerDetails.accountNumber}
              </p>
              <p className='text-sm text-gray-600 mb-2'>
                <strong>Institution Number:</strong>{" "}
                {ownerDetails.institutionNumber}
              </p>

              {/* Display and Download the Identification File */}
              {ownerDetails.fileUrl && (
                <div className='mt-4'>
                  <strong className='text-gray-700'>
                    Identification File:
                  </strong>
                  <div className='mt-2'>
                    {/* Display File Based on Type */}
                    {/\.(jpg|jpeg|png)$/i.test(ownerDetails.fileUrl) ? (
                      // Image Preview
                      <img
                        src={ownerDetails.fileUrl}
                        alt='Identification File'
                        className='max-w-full h-auto rounded-md shadow-md'
                      />
                    ) : /\.(pdf)$/i.test(ownerDetails.fileUrl) ? (
                      // PDF Preview
                      <iframe
                        src={ownerDetails.fileUrl}
                        title='Identification File'
                        className='w-full h-64 border rounded-md'
                        allowfullscreen='true'
                        sandbox='allow-scripts allow-same-origin'
                      ></iframe>
                    ) : (
                      // Unsupported File Type Handling
                      <div className='text-gray-500 text-sm flex items-center gap-2'>
                        <span className='inline-block p-2 bg-gray-200 rounded-md'>
                          📄
                        </span>
                        <span>
                          Unable to preview this file. Please download to view
                          it.
                        </span>
                      </div>
                    )}

                    {/* Download Link */}
                    <a
                      href={ownerDetails.fileUrl}
                      download
                      className='block mt-3 text-blue-500 underline hover:text-blue-700'
                    >
                      Download File
                    </a>
                  </div>
                </div>
              )}
            </div>

            {/* Terms and Conditions Section */}
            <div className='mb-6 p-4 bg-white rounded-lg shadow-sm'>
              <h4 className='text-xl font-semibold text-gray-700 mb-4 border-b pb-2'>
                Terms & Conditions
              </h4>
              <p className='text-sm text-gray-600 mb-2'>
                <strong>Accepted Terms:</strong>{" "}
                {ownerDetails.terms ? "Yes" : "No"}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminRestaurantApproval;

