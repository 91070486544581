import React from "react";
import { Link } from "react-router-dom";
import { FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className='bg-gray-900 text-gray-300 py-10'>
      <div className='container mx-auto px-6'>
        <div className='flex flex-wrap justify-between'>
          {/* Helpful Links */}
          <div className='w-full sm:w-1/2 md:w-1/3 mb-6'>
            <h3 className='text-lg font-bold mb-4 text-white'>Helpful Links</h3>
            <ul className='space-y-2'>
              <li>
                <Link
                  to='/about-us'
                  className='hover:underline transition duration-300 hover:text-purple-400'
                >
                  About Us
                </Link>
              </li>
              <li>
                <Link
                  to='/privacy-policy'
                  className='hover:underline transition duration-300 hover:text-purple-400'
                >
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link
                  to='/cookie-policy'
                  className='hover:underline transition duration-300 hover:text-purple-400'
                >
                  Cookie Policy
                </Link>
              </li>
              <ul className='space-y-2'>
                <li>
                  <Link
                    to='/vendor-terms'
                    className='hover:underline transition duration-300 hover:text-purple-400'
                  >
                    Vendor Agreement
                  </Link>
                </li>
                <li>
                  <Link
                    to='/customer-terms'
                    className='hover:underline transition duration-300 hover:text-purple-400'
                  >
                    Customer Agreement
                  </Link>
                </li>
              </ul>
            </ul>
          </div>

          {/* Contact Info */}
          <div className='w-full sm:w-1/2 md:w-1/3 mb-6'>
            <h3 className='text-lg font-bold mb-4 text-white'>Contact Us</h3>
            <p className='flex items-center mb-2'>
              <FaEnvelope className='mr-2 text-purple-400' />
              <a
                href='mailto:support@restoura.com'
                className='hover:underline transition duration-300 hover:text-purple-400'
              >
                support@restoura.com
              </a>
            </p>
            <p className='flex items-center'>
              <FaMapMarkerAlt className='mr-2 text-purple-400' />
              Ontario, Canada
            </p>
          </div>
        </div>

        {/* Footer Bottom */}
        <div className='mt-8 border-t border-gray-700 pt-4 text-center'>
          <p className='text-sm'>&copy; 2024 Restoura. All rights reserved.</p>
          <p className='text-sm'>
            Developed by{" "}
            <a
              href='https://peytosoft.com/'
              target='_blank'
              rel='noopener noreferrer'
              className='hover:underline text-purple-400 font-semibold'
            >
              Peytosoft
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

