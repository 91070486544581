import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import RestaurantCard from "./RestaurantCard";
import API_BASE_URL from "../../apiConfig";
import RestaurantCardSkeleton from "../../common-components/skeleton-loader/RestaurantSkeletonLoader";

const AllRestaurantsPage = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState({
    cuisine: "",
    openOrClosed: "",
    city: "",
  });
  const [restaurants, setRestaurants] = useState([]);
  const [showLoader, isShowLoader] = useState(false);

  const navigate = useNavigate();

  // Fetch the search term from localStorage on initial load
  useEffect(() => {
    const savedSearchTerm = localStorage.getItem("searchTerm");
    if (savedSearchTerm) {
      setSearchTerm(savedSearchTerm);
    }

    // Clear search term when component unmounts or if the user navigates away
    return () => {
      localStorage.removeItem("searchTerm"); // Clear search term when leaving AllRestaurantsPage
    };
  }, []);

  useEffect(() => {
    const fetchRestaurants = async () => {
      try {
        isShowLoader(true);
        const response = await fetch(
          `${API_BASE_URL}/restaurant/allRestaurants`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          const approvedRestaurants = data.filter(
            (restaurant) => restaurant.status === "Approved"
          );

          // Check for cuisine data for each restaurant
          const restaurantsWithCuisine = [];
          for (const restaurant of approvedRestaurants) {
            const cuisinesResponse = await fetch(
              `${API_BASE_URL}/cuisine/allCuisines/${restaurant.id}`,
              {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                },
              }
            );

            if (cuisinesResponse.ok) {
              const cuisineData = await cuisinesResponse.json();
              if (cuisineData.length > 0) {
                restaurantsWithCuisine.push(restaurant); // Only push restaurants that have cuisine data
              }
            } else {
              console.warn(
                `No cuisine data for restaurant ID: ${restaurant.id}`
              );
            }
          }
          setRestaurants(restaurantsWithCuisine); // Update the state with filtered restaurants
        } else {
          console.error("Failed to fetch restaurants");
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        isShowLoader(false);
      }
    };

    // Fetch restaurants and check their cuisine data
    fetchRestaurants();
  }, []);

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    localStorage.setItem("searchTerm", value); // Update search term in local storage
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilter((prevFilter) => ({
      ...prevFilter,
      [name]: value,
    }));
  };

  const handleRestaurantClick = (restaurant) => {
    if (restaurant.openOrClosed === true) {
      navigate(`/restaurant/${restaurant.name}/${restaurant.id}`);
    }
  };

  // Filtering logic
  const filteredRestaurants = restaurants.filter((restaurant) => {
    const searchTermLower = searchTerm.toLowerCase();
    const restaurantName = restaurant.name ? restaurant.name.toLowerCase() : "";
    const cuisineName = restaurant.cuisineType
      ? restaurant.cuisineType.toLowerCase()
      : "";
    const restaurantAddress = restaurant.addressLine1
      ? restaurant.addressLine1.toLowerCase()
      : "";
    const restaurantCity = restaurant.city ? restaurant.city.toLowerCase() : "";
    const restaurantState = restaurant.state
      ? restaurant.state.toLowerCase()
      : "";
    const restaurantCuisine = restaurant.cuisineType
      ? restaurant.cuisineType.toLowerCase()
      : "";

    const matchesSearchTerm =
      restaurantName.includes(searchTermLower) ||
      cuisineName.includes(searchTermLower) ||
      restaurantAddress.includes(searchTermLower) ||
      restaurantCity.includes(searchTermLower) ||
      restaurantState.includes(searchTermLower);

    const matchesCuisine =
      !filter.cuisine || restaurantCuisine === filter.cuisine.toLowerCase();
    const matchesOpenStatus =
      !filter.openOrClosed ||
      restaurant.openOrClosed === (filter.openOrClosed === "true");
    const matchesCity =
      !filter.city || restaurantCity === filter.city.toLowerCase();

    return (
      matchesSearchTerm && matchesCuisine && matchesOpenStatus && matchesCity
    );
  });

  return (
    <div className='w-full mt-20 min-h-screen bg-gray-50 overflow-x-hidden'>
      <div className='max-w-screen-xl mx-auto px-4 py-12'>
        <div className='flex flex-col md:flex-row gap-8'>
          {/* Filter option */}
          <div className='w-full md:w-1/4 lg:w-1/5 p-6 max-h-[calc(100vh-4rem)] overflow-y-auto'>
            <h2 className='text-xl font-semibold mb-4 text-gray-800'>
              Filters
            </h2>

            <div className='flex flex-col gap-4'>
              {/* Filter by Open/Closed Status */}
              <select
                name='openOrClosed'
                value={filter.openOrClosed}
                onChange={handleFilterChange}
                className='w-full rounded-lg bg-gray-100 px-4 py-3 text-sm font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-green-400'
              >
                <option value=''>Status</option>
                <option value='true'>Open</option>
                <option value='false'>Closed</option>
              </select>
              {/* Filter by Cuisine */}
              <select
                name='cuisine'
                value={filter.cuisine}
                onChange={handleFilterChange}
                className='w-full rounded-lg bg-gray-100 px-4 py-3 text-sm font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-purple-400'
              >
                <option value=''>Cuisines</option>
                {[...new Set(restaurants.map((r) => r.cuisineType))].map(
                  (cuisine, index) => (
                    <option key={index} value={cuisine}>
                      {cuisine}
                    </option>
                  )
                )}
              </select>

              {/* Filter by City */}
              <select
                name='city'
                value={filter.city}
                onChange={handleFilterChange}
                className='w-full rounded-lg bg-gray-100 px-4 py-3 text-sm font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-400'
              >
                <option value=''>Cities</option>
                {[...new Set(restaurants.map((r) => r.city))].map(
                  (city, index) => (
                    <option key={index} value={city}>
                      {city}
                    </option>
                  )
                )}
              </select>
            </div>
          </div>

          <div className='w-full md:w-3/4 lg:w-4/5'>
            <div className='mb-6'>
              <input
                type='text'
                placeholder="Search by restaurant's address, dishes, cuisines"
                className='w-full rounded-xl bg-gray-100 px-4 py-3 text-sm font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-purple-400'
                value={searchTerm} // Set the search term from state
                onChange={handleSearchChange}
              />
            </div>

            <h2 className='text-2xl font-semibold mb-4 text-gray-800'>
              Our Restaurants
            </h2>
            <div className='grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6'>
              {showLoader
                ? Array.from({ length: 6 }).map((_, index) => (
                    <RestaurantCardSkeleton key={index} />
                  ))
                : filteredRestaurants.map((restaurant, index) => (
                    <div
                      key={index}
                      className={`rounded-lg overflow-hidden shadow-lg cursor-pointer transform transition-transform duration-300 hover:scale-105 ${
                        restaurant.openOrClosed !== true
                          ? "pointer-events-none opacity-50"
                          : ""
                      }`}
                      onClick={() => handleRestaurantClick(restaurant)}
                    >
                      <RestaurantCard
                        restaurant={{
                          id: restaurant.id,
                          name: restaurant.name,
                          openOrClosed: restaurant.openOrClosed,
                          operatingHours: restaurant.operatingHours,
                          cuisineType: restaurant.cuisineType,
                          location: {
                            addressLine1: restaurant.addressLine1,
                            city: restaurant.city,
                            state: restaurant.state,
                          },
                        }}
                      />
                    </div>
                  ))}
              {filteredRestaurants.length === 0 && !showLoader && (
                <div className='col-span-3 text-center text-gray-500'>
                  Currently no available restaurants to display... Please stay
                  with us
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllRestaurantsPage;

