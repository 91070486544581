import React, { useState, useEffect } from "react";
import API_BASE_URL from "../../apiConfig";

const Transactions = () => {
  const [transactions, setTransactions] = useState([]);
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [filter, setFilter] = useState("");
  const [restaurantFilter, setRestaurantFilter] = useState("");
  const [uniqueRestaurants, setUniqueRestaurants] = useState([]);

  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/order/getAllOrders`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        const data = await response.json();
        // Filter orders with paymentStatus === "Payment Received"
        const receivedPayments = data.filter(
          (order) => order.paymentStatus === "Payment Received"
        );
        setTransactions(receivedPayments);
        setFilteredTransactions(receivedPayments);

        // Extract unique restaurant names for dropdown
        const restaurants = [
          ...new Set(receivedPayments.map((order) => order.restaurantName)),
        ];
        setUniqueRestaurants(restaurants);
      } catch (error) {
        console.error("Error fetching transactions:", error);
      }
    };

    fetchTransactions();
  }, [token]);

  useEffect(() => {
    // Filter transactions based on both search and dropdown
    const filtered = transactions.filter((transaction) => {
      const matchesSearch = transaction.restaurantName
        .toLowerCase()
        .includes(filter.toLowerCase());
      const matchesDropdown =
        restaurantFilter === "" ||
        transaction.restaurantName === restaurantFilter;
      return matchesSearch && matchesDropdown;
    });
    setFilteredTransactions(filtered);
  }, [filter, restaurantFilter, transactions]);

  return (
    <div className='p-4 sm:p-6 bg-gray-100 min-h-screen'>
      {/* Header */}
      <h1 className='text-2xl font-semibold text-gray-700 mb-6'>
        Transactions
      </h1>

      {/* Filter Section */}
      <div className='mb-6 flex flex-col lg:flex-row gap-4'>
        {/* Search */}
        <input
          type='text'
          placeholder='Search by restaurant name'
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          className='p-3 border border-gray-300 rounded-lg w-full lg:w-1/2 shadow-sm focus:ring focus:ring-blue-200'
        />

        {/* Dropdown Filter */}
        <select
          value={restaurantFilter}
          onChange={(e) => setRestaurantFilter(e.target.value)}
          className='p-3 border border-gray-300 rounded-lg w-full lg:w-1/2 shadow-sm focus:ring focus:ring-blue-200'
        >
          <option value=''>All Restaurants</option>
          {uniqueRestaurants.map((name) => (
            <option key={name} value={name}>
              {name}
            </option>
          ))}
        </select>
      </div>

      {/* Transactions Table */}
      <div className='bg-white shadow-lg rounded-lg overflow-x-auto'>
        <table className='w-full text-left border-collapse'>
          <thead className='bg-blue-100 text-blue-800 text-sm uppercase'>
            <tr>
              <th className='px-4 py-3'>Order ID</th>
              <th className='px-4 py-3'>Restaurant</th>
              <th className='px-4 py-3'>Vendor Email</th>
              <th className='px-4 py-3'>Customer Email</th>
              <th className='px-4 py-3'>Order Date</th>
              <th className='px-4 py-3'>Order Status</th>
              <th className='px-4 py-3'>Total Payment</th>
              <th className='px-4 py-3'>Vendor's Amount(-5%)</th>
              <th className='px-4 py-3'>Payment Status</th>
              <th className='px-4 py-3'>Order Review</th>
              <th className='px-4 py-3'>Vendor Paid?</th>
              <th className='px-4 py-3'>Upload File</th>
            </tr>
          </thead>
          <tbody>
            {filteredTransactions.length > 0 ? (
              filteredTransactions.map((transaction) => (
                <tr
                  key={transaction.orderId}
                  className='border-t hover:bg-gray-50 text-sm'
                >
                  <td className='px-4 py-3'>{transaction.orderId}</td>
                  <td className='px-4 py-3'>{transaction.restaurantName}</td>
                  <td className='px-4 py-3'>{transaction.vendorEmail}</td>
                  <td className='px-4 py-3'>{transaction.customerEmail}</td>
                  <td className='px-4 py-3'>{transaction.orderDate}</td>
                  <td className='px-4 py-3'>{transaction.orderStatus}</td>
                  <td className='px-4 py-3'>
                    $
                    {(
                      transaction.totalAmount +
                      transaction.totalAmount * 0.13 +
                      transaction.totalAmount * 0.05
                    ).toFixed(2)}
                  </td>
                  <td className='px-4 py-3'>
                    $
                    {(
                      transaction.totalAmount +
                      transaction.totalAmount * 0.13 -
                      transaction.totalAmount * 0.05
                    ).toFixed(2)}
                  </td>
                  <td className='px-4 py-3'>{transaction.paymentStatus}</td>
                  <td className='px-4 py-3'>
                    {transaction.orderReview || "No review"}
                  </td>
                  <td className='px-4 py-3'>-</td>
                  <td className='px-4 py-3'>-</td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan='12'
                  className='px-4 py-3 text-center text-gray-500'
                >
                  No transactions found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Transactions;

