import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className='bg-gray-50 min-h-screen flex flex-col mt-24'>
      {/* Header */}
      <header className='bg-gradient-to-r from-blue-500 to-indigo-600 text-white py-6 shadow-md'>
        <div className='max-w-6xl mx-auto px-4'>
          <h1 className='text-3xl font-bold'>Restoura Privacy Policy</h1>
          <p className='text-sm mt-1'>Last modified: 12/03/2024</p>
        </div>
      </header>

      {/* Main Content */}
      <main className='flex-1 max-w-6xl mx-auto px-4 py-8 text-gray-800'>
        <p className='text-lg'>
          <span className='text-2xl font-bold block mb-4'>
            At Restoura, we are committed to protecting your privacy.
          </span>
          We ensure that your personal information is handled in a safe and
          responsible manner. This Privacy Policy outlines how we collect, use,
          store, and protect your personal data, as well as your rights
          regarding your information. This Privacy Policy applies to all users
          of the Restoura platform, including both customers and vendors.
        </p>
        <p className='mt-6'>
          By using the Restoura platform, you agree to the terms of this Privacy
          Policy. If you do not agree with this policy, please do not use the
          platform.
        </p>

        <p className='text-lg mt-8'>
          <span className='text-2xl font-bold block mb-4'>
            1. Information We Collect
          </span>
          We collect various types of personal data depending on whether you are
          a customer or a vendor.
        </p>
        <p className='mt-4'>
          <span className='font-bold'>For Customers:</span>
        </p>
        <ul className='list-disc ml-6 mt-2'>
          <li>
            <span className='font-bold'>Personal Information:</span> Name, email
            address, phone number, shipping address.
          </li>
          <li>
            <span className='font-bold'>Payment Information:</span> Credit card
            or payment account details.
          </li>
          <li>
            <span className='font-bold'>Order Information:</span> Order history,
            delivery preferences, and special instructions.
          </li>
          <li>
            <span className='font-bold'>Device Information:</span> IP address,
            browser type, operating system, and device information.
          </li>
          <li>
            <span className='font-bold'>Marketing Preferences:</span> Consent
            for marketing communications, if applicable.
          </li>
        </ul>

        <p className='mt-6'>
          <span className='font-bold'>For Vendors:</span>
        </p>
        <ul className='list-disc ml-6 mt-2'>
          <li>
            <span className='font-bold'>Business Information:</span> Business
            name, contact information, tax identification number (if
            applicable).
          </li>
          <li>
            <span className='font-bold'>Payment Information:</span> Payment
            details for processing orders and disbursements.
          </li>
          <li>
            <span className='font-bold'>Order History:</span> Data related to
            orders processed, customer interactions, and delivery details.
          </li>
          <li>
            <span className='font-bold'>Performance Data:</span> Metrics related
            to vendor performance, such as order acceptance rate and delivery
            times.
          </li>
        </ul>

        <p className='text-lg mt-8'>
          <span className='text-2xl font-bold block mb-4'>
            2. How We Collect Your Information
          </span>
          We collect personal information from various sources:
        </p>
        <ul className='list-disc ml-6 mt-2'>
          <li>
            <span className='font-bold'>Directly from you:</span> When you
            register for an account, place an order (for customers), or sign up
            as a vendor, we collect your information directly from you.
          </li>
          <li>
            <span className='font-bold'>Through your use of the platform:</span>{" "}
            We collect data related to your activity on the platform, including
            browsing behavior, order history, and communication.
          </li>
          <li>
            <span className='font-bold'>
              Cookies and Tracking Technologies:
            </span>{" "}
            We use cookies and similar technologies to enhance your experience
            and collect usage data (see our{" "}
            <a
              href='/cookie-policy'
              className='text-blue-500 underline hover:text-blue-700'
            >
              Cookie Policy
            </a>{" "}
            for more details).
          </li>
        </ul>

        <p className='text-lg mt-8'>
          <span className='text-2xl font-bold block mb-4'>
            3. How We Use Your Information
          </span>
          We use your personal data for the following purposes:
        </p>
        <p className='mt-4'>
          <span className='font-bold'>For Customers:</span>
        </p>
        <ul className='list-disc ml-6 mt-2'>
          <li>
            <span className='font-bold'>To Process Orders:</span> To complete
            and fulfill your orders, including payment processing and delivery.
          </li>
          <li>
            <span className='font-bold'>To Improve Services:</span> To enhance
            your experience on the platform, improve our services, and
            personalize content.
          </li>
          <li>
            <span className='font-bold'>To Communicate with You:</span> To send
            order confirmations, updates, and customer service communications.
            If you consent, we may send marketing communications about
            promotions and new offerings.
          </li>
          <li>
            <span className='font-bold'>
              For Legal and Regulatory Purposes:
            </span>{" "}
            To comply with applicable laws, regulations, and legal obligations.
          </li>
        </ul>
        <p className='mt-6'>
          <span className='font-bold'>For Vendors:</span>
        </p>
        <ul className='list-disc ml-6 mt-2'>
          <li>
            <span className='font-bold'>To Facilitate Transactions:</span> To
            process orders and payments and ensure vendors are paid for their
            services.
          </li>
          <li>
            <span className='font-bold'>To Manage Business Operations:</span> To
            monitor and improve the performance of vendors on the platform,
            including order fulfillment, delivery times, and customer feedback.
          </li>
          <li>
            <span className='font-bold'>For Legal Compliance:</span> To comply
            with tax, accounting, and other legal obligations related to the
            vendor’s business operations.
          </li>
          <li>
            <span className='font-bold'>To Communicate with Vendors:</span> To
            provide updates, operational support, and business communications.
          </li>
        </ul>

        <p className='text-lg mt-8'>
          <span className='text-2xl font-bold block mb-4'>
            4. Sharing Your Information
          </span>
          We do not sell your personal data. However, we may share your
          information with third parties in the following circumstances:
        </p>
        <ul className='list-disc ml-6 mt-2'>
          <li>
            <span className='font-bold'>With Service Providers:</span> We share
            personal data with third-party service providers who assist with
            payment processing, order fulfillment, delivery services, and
            customer support.
          </li>
          <li>
            <span className='font-bold'>For Legal Reasons:</span> We may
            disclose your information to comply with legal obligations, enforce
            our agreements, protect our rights, and ensure the safety of our
            users.
          </li>
          <li>
            <span className='font-bold'>With Your Consent:</span> If you
            consent, we may share your data for additional purposes, such as
            marketing or other promotional activities.
          </li>
        </ul>
        <p className='mt-6'>
          <span className='font-bold'>For Customers:</span> We may share your
          order information and delivery details with vendors and delivery
          service providers to fulfill your orders.
        </p>
        <p className='mt-6'>
          <span className='font-bold'>For Vendors:</span> We may share
          business-related information, including order details and payment
          data, with vendors to facilitate the operation of the platform and
          ensure payments are processed correctly.
        </p>

        <p className='text-lg mt-8'>
          <span className='text-2xl font-bold block mb-4'>
            5. Data Security
          </span>
          We take reasonable measures to protect your personal data from
          unauthorized access, alteration, disclosure, or destruction. These
          measures include the use of encryption, secure payment gateways, and
          access control procedures. However, please note that no method of data
          transmission over the Internet or electronic storage is 100% secure,
          and we cannot guarantee absolute security.
        </p>

        <p className='text-lg mt-8'>
          <span className='text-2xl font-bold block mb-4'>
            6. Data Retention
          </span>
          We retain your personal data only for as long as necessary to fulfill
          the purposes for which it was collected, including for legal,
          accounting, or reporting requirements. For example:
        </p>
        <p className='mt-4'>
          <span className='font-bold'>For Customers:</span> We retain order
          history and payment information for a reasonable period to assist with
          customer service and regulatory requirements.
        </p>
        <p className='mt-4'>
          <span className='font-bold'>For Vendors:</span> We retain
          business-related data for purposes such as financial transactions, tax
          reporting, and dispute resolution.
        </p>

        <p className='text-lg mt-8'>
          <span className='text-2xl font-bold block mb-4'>7. Your Rights</span>
          You have certain rights regarding your personal data. Depending on
          your jurisdiction, these rights may include:
        </p>
        <ul className='list-disc ml-6 mt-2'>
          <li>
            <span className='font-bold'>Access:</span> You can request access to
            the personal data we hold about you.
          </li>
          <li>
            <span className='font-bold'>Correction:</span> You can request
            corrections to any inaccurate or incomplete personal data.
          </li>
          <li>
            <span className='font-bold'>Deletion:</span> You can request that we
            delete your personal data, subject to certain exceptions (e.g.,
            legal obligations).
          </li>
          <li>
            <span className='font-bold'>Objection:</span> You can object to the
            processing of your personal data for certain purposes, including
            marketing.
          </li>
          <li>
            <span className='font-bold'>Portability:</span> You may request a
            copy of your personal data in a commonly used, machine-readable
            format.
          </li>
          <li>
            <span className='font-bold'>Withdrawal of Consent:</span> You may
            withdraw any consent you have provided for processing your personal
            data.
          </li>
        </ul>
        <p className='mt-6'>
          To exercise these rights, please contact us at{" "}
          <a
            href='mailto:support@restoura.com'
            className='text-blue-500 underline'
          >
            support@restoura.com
          </a>
          .
        </p>

        <p className='text-lg mt-8'>
          <span className='text-2xl font-bold block mb-4'>
            8. Cookies and Tracking Technologies
          </span>
          We use cookies and similar tracking technologies to enhance your
          experience and analyze usage of the platform. Cookies are small text
          files stored on your device that help us remember your preferences,
          improve site functionality, and provide analytics.
        </p>
        <p className='mt-4'>
          For more information on how we use cookies, please refer to our{" "}
          <a href='/cookie-policy' className='text-blue-500 underline'>
            Cookie Policy
          </a>
          .
        </p>

        <p className='text-lg mt-8'>
          <span className='text-2xl font-bold block mb-4'>
            9. Changes to This Privacy Policy
          </span>
          We may update this Privacy Policy from time to time. If we make
          significant changes, we will notify you by posting a notice on the
          platform or sending you an email (if you have an account). The updated
          Privacy Policy will be effective as of the date indicated at the top
          of the document.
        </p>

        <p className='text-lg mt-8'>
          <span className='text-2xl font-bold block mb-4'>10. Contact Us</span>
          If you have any questions or concerns about this Privacy Policy or how
          we handle your personal data, please contact us at:
        </p>
        <ul className='mt-4 ml-6'>
          <li>
            <span className='font-bold'>Restoura</span>
          </li>
          <li>
            Email:{" "}
            <a
              href='mailto:insert-email@restoura.com'
              className='text-blue-500 underline'
            >
              support@restoura.com
            </a>
          </li>
          {/* <li>Phone: [Insert Phone Number]</li> */}
          <li>Address: ontario, Canada</li>
        </ul>

        <p className='text-lg mt-8'>
          <span className='text-xl font-bold block mb-4'>Conclusion</span>
          By using the Restoura platform, you consent to the collection and use
          of your personal data as outlined in this Privacy Policy. If you have
          any questions or require clarification, please don’t hesitate to
          contact us.
        </p>
      </main>

      {/* Footer */}
      <footer className='bg-white py-4 mt-8 border-t'>
        <div className='max-w-6xl mx-auto px-4 text-center'>
          <p className='text-sm text-gray-500'>
            Last Updated: December 03, 2024 | © Restoura. All rights reserved.
          </p>
        </div>
      </footer>
    </div>
  );
};

export default PrivacyPolicy;

