import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import API_BASE_URL from "../../apiConfig";
import useUtilService from "../../services/useUtilService";
import Modal from "../../common-components/modal/Modal";
import CustomerTerms from "../static_pages/CustomerTermsV1";

const CustomerOnboarding = () => {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);

  const { showSuccessMessage, showErrorMessage } = useUtilService();
  const [loading, setLoading] = useState(false);
  const userId = localStorage.getItem("userId");
  const [customerDetails, setCustomerDetails] = useState({
    name: "",
    email: localStorage.getItem("email") || "",
    age: "",
    occupation: "",
    sex: "",
    contactNo: "",
    terms: false,
    addresses: [
      {
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        postalCode: "",
        country: "",
      },
    ],
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setCustomerDetails({
      ...customerDetails,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleAddressChange = (index, e) => {
    const { name, value } = e.target;
    const updatedAddresses = [...customerDetails.addresses];
    updatedAddresses[index] = {
      ...updatedAddresses[index],
      [name]: value,
    };
    setCustomerDetails({
      ...customerDetails,
      addresses: updatedAddresses,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const token = localStorage.getItem("token");

    if (!customerDetails.email || !token) {
      alert("Error: Email or Token is missing from local storage.");
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/customers`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(customerDetails),
      });

      if (response.ok) {
        showSuccessMessage(
          "Congratulations! Your profile has been created successfully."
        );
        localStorage.setItem("isLoggedIn", true);
        navigate(`/customer-profile/my-profile/${userId}`);
        // Refresh the page after navigation
        window.location.reload();
      } else {
        console.error(
          "Failed to submit customer details:",
          response.statusText
        );
        showErrorMessage("Failed to create the customer. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      showErrorMessage(
        "An error occurred while creating the customer. Please try again."
      );
    } finally {
      setLoading(false); // Reset loading when done
    }
  };

  return (
    <div className='bg-white mt-24 p-8 rounded-lg shadow-md max-w-3xl mx-auto'>
      <div className='bg-blue-500 py-4 rounded-lg'>
        <h2 className='text-3xl font-bold text-white mb-6 text-center'>
          Customer Onboarding
        </h2>
      </div>

      <form onSubmit={handleSubmit} className='space-y-6'>
        {/* Separator */}
        <div className='my-8 text-center'>
          <hr className='border-gray-300' />
          <p className='text-lg font-semibold text-gray-700 my-4'>
            Personal Information
          </p>
          <hr className='border-gray-300' />
        </div>
        <div className='p-4 bg-white border border-gray-300 rounded-md shadow-sm hover:shadow-lg '>
          {/* Basic Information Form */}
          <div className='mb-4'>
            <label className='block mb-1'>
              <span className='text-red-500'>*</span>Name:
            </label>
            <input
              type='text'
              name='name'
              value={customerDetails.name}
              onChange={handleChange}
              className='w-full p-2 border border-gray-300 rounded-md'
              required
            />
          </div>
          <div className='mb-4'>
            <label className='block mb-1'>Email:</label>
            <input
              type='email'
              name='email'
              value={customerDetails.email}
              onChange={handleChange}
              className='w-full p-2 border border-gray-300 rounded-md'
              readOnly
            />
          </div>
          <div className='mb-4'>
            <label className='block mb-1'>
              <span className='text-red-500'>*</span>Age:
            </label>
            <input
              type='number'
              name='age'
              value={customerDetails.age}
              onChange={handleChange}
              className='w-full p-2 border border-gray-300 rounded-md'
              required
            />
          </div>
          <div className='mb-4'>
            <label className='block mb-1'>
              <span className='text-red-500'>*</span>Gender:
            </label>
            <select
              name='sex'
              value={customerDetails.sex}
              onChange={handleChange}
              className='w-full p-2 border border-gray-300 rounded-md'
              required
            >
              <option value=''>Select</option>
              <option value='Male'>Male</option>
              <option value='Female'>Female</option>
              <option value='Other'>Other</option>
            </select>
          </div>
          <div className='mb-4'>
            <label className='block mb-1'>
              <span className='text-red-500'>*</span>Contact Number:
            </label>
            <input
              type='text'
              name='contactNo'
              value={customerDetails.contactNo}
              onChange={handleChange}
              className='w-full p-2 border border-gray-300 rounded-md'
              required
            />
          </div>
          <div className='mb-4'>
            <label className='block mb-1'>
              Occupation <span className='text-gray-500'>(Optional)</span>:
            </label>
            <input
              type='text'
              name='occupation'
              value={customerDetails.occupation}
              onChange={handleChange}
              className='w-full p-2 border border-gray-300 rounded-md'
            />
          </div>
        </div>

        {/* Separator */}
        <div className='my-8 text-center'>
          <hr className='border-gray-300' />
          <p className='text-lg font-semibold text-gray-700 my-4'>
            Address Information
          </p>
          <hr className='border-gray-300' />
        </div>

        {/* Address Information Form */}
        {customerDetails.addresses.map((address, index) => (
          <div
            key={index}
            className='p-4 bg-white border border-gray-300 rounded-md shadow-sm hover:shadow-lg'
          >
            <div>
              <label className='block mb-1'>
                <span className='text-red-500'>*</span>Address Line 1:
              </label>
              <input
                type='text'
                name='addressLine1'
                value={address.addressLine1}
                onChange={(e) => handleAddressChange(index, e)}
                className='w-full p-2 border border-gray-300 rounded-md'
                required
              />
            </div>

            <div>
              <label className='block mb-1'>
                Address Line 2 <span className='text-gray-500'>(Optional)</span>
                :
              </label>
              <input
                type='text'
                name='addressLine2'
                value={address.addressLine2}
                onChange={(e) => handleAddressChange(index, e)}
                className='w-full p-2 border border-gray-300 rounded-md'
              />
            </div>

            <div>
              <label className='block mb-1'>
                <span className='text-red-500'>*</span>Country:
              </label>
              <input
                type='text'
                name='country'
                value={address.country}
                onChange={(e) => handleAddressChange(index, e)}
                className='w-full p-2 border border-gray-300 rounded-md'
                required
              />
            </div>

            <div>
              <label className='block mb-1'>
                <span className='text-red-500'>*</span>City:
              </label>
              <input
                type='text'
                name='city'
                value={address.city}
                onChange={(e) => handleAddressChange(index, e)}
                className='w-full p-2 border border-gray-300 rounded-md'
                required
              />
            </div>

            <div>
              <label className='block mb-1'>
                <span className='text-red-500'>*</span>State:
              </label>
              <input
                type='text'
                name='state'
                value={address.state}
                onChange={(e) => handleAddressChange(index, e)}
                className='w-full p-2 border border-gray-300 rounded-md'
                required
              />
            </div>

            <div>
              <label className='block mb-1'>
                Postal Code <span className='text-gray-500'>(Optional)</span>:
              </label>
              <input
                type='text'
                name='postalCode'
                value={address.postalCode}
                onChange={(e) => handleAddressChange(index, e)}
                className='w-full p-2 border border-gray-300 rounded-md'
              />
            </div>
          </div>
        ))}

        {/* terms and conditions */}
        <div className='p-4 bg-white border border-gray-300 rounded-md shadow-sm hover:shadow-lg'>
          <p className='text-gray-700'>
            Please read and accept the terms and conditions before proceeding.
          </p>
          <div className='mt-4 flex items-center'>
            <input
              type='checkbox'
              id='terms'
              name='terms'
              checked={customerDetails.terms}
              onChange={handleChange}
              className='w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring focus:ring-blue-200 focus:outline-none'
            />
            <label
              htmlFor='terms'
              className='ml-2 text-sm font-medium text-gray-700'
            >
              I have read and agree to the terms and conditions
            </label>
          </div>
          <button
            type='button'
            className='mt-4 text-blue-500 underline hover:text-blue-700'
            onClick={() => setModalOpen(true)}
          >
            Read Terms and Conditions
          </button>
        </div>
        <Modal
          isOpen={modalOpen}
          onClose={() => setModalOpen(false)}
          title='Terms and Conditions'
        >
          <CustomerTerms />
        </Modal>
        <button
          type='submit'
          className={`w-full py-3 px-4 bg-blue-600 text-white font-semibold rounded-lg shadow-md flex items-center justify-center gap-2 transition duration-300 ${
            loading || !customerDetails.terms
              ? "opacity-50 cursor-not-allowed"
              : "hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
          }`}
          disabled={loading || !customerDetails.terms}
        >
          {loading && (
            <svg
              className='animate-spin h-5 w-5 text-white'
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
            >
              <circle
                className='opacity-25'
                cx='12'
                cy='12'
                r='10'
                stroke='currentColor'
                strokeWidth='4'
              ></circle>
              <path
                className='opacity-75'
                fill='currentColor'
                d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.963 7.963 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
              ></path>
            </svg>
          )}
          {loading ? "Creating Profile..." : "Create Profile"}
        </button>
      </form>
    </div>
  );
};

export default CustomerOnboarding;

