import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const SkeletonLoader = () => {
  return (
    <div className='flex p-4 bg-white rounded-lg shadow-md w-full max-w-2xl'>
      {/* Image Skeleton */}
      <div className='w-1/3'>
        <Skeleton height={120} borderRadius={8} />
      </div>

      {/* Content Skeleton */}
      <div className='w-2/3 pl-4 space-y-2'>
        {/* Cuisine Name */}
        <Skeleton height={20} width='60%' />
        {/* Price */}
        <Skeleton height={16} width='40%' />
        {/* Availability */}
        <Skeleton height={16} width='50%' />
        {/* Prep Time */}
        <Skeleton height={16} width='30%' />
      </div>
    </div>
  );
};

export default SkeletonLoader;

