import React from "react";

const AboutUs = () => {
  return (
    <div className='bg-gray-50 mt-20'>
      {/* Hero Section */}
      <section className='bg-gradient-to-b from-white to-gray-100'>
        <div className='mx-auto max-w-screen-xl px-4 py-12 sm:px-6 lg:px-8 text-center'>
          <h2 className='text-5xl font-extrabold text-gray-900 sm:text-6xl leading-tight'>
            Welcome to <span className='text-rose-600'>Restoura</span>
          </h2>
          <p className='mt-6 text-xl text-gray-600 max-w-3xl mx-auto'>
            At Restoura, we are committed to connecting local chefs with food
            lovers seeking authentic homemade dishes. What began as a small
            initiative to connect food lovers with native cuisines has grown
            into a thriving platform empowering chefs and delighting customers.
            Every dish tells a story, and at Restoura, we’re here to celebrate
            them.
          </p>
        </div>
      </section>

      <section className='py-20 bg-gradient-to-b from-white to-gray-100'>
        <div className='mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8'>
          {/* Header */}
          <div className='text-center mb-16'>
            <h2 className='text-5xl font-extrabold text-gray-900'>
              Our Vision & Mission
            </h2>
            <p className='mt-6 text-lg text-gray-600 max-w-4xl mx-auto'>
              We aim to revolutionize the homemade food experience by creating a
              bridge between passionate local chefs and food enthusiasts. We are
              dedicated to delivering authentic, fresh, and heartwarming meals
              that tell a story.
            </p>
          </div>

          <div className='mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8'>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-8'>
              <div className='bg-rose-50 p-8 rounded-lg shadow-lg text-center'>
                <h3 className='text-2xl font-semibold text-rose-600'>Vision</h3>
                <p className='mt-4 text-gray-700'>
                  To be the go-to platform for homemade meals, bridging cultures
                  and communities through food.
                </p>
              </div>
              <div className='bg-blue-50 p-8 rounded-lg shadow-lg text-center'>
                <h3 className='text-2xl font-semibold text-blue-600'>
                  Mission
                </h3>
                <p className='mt-4 text-gray-700'>
                  We strive to empower chefs to grow their culinary business
                  while providing customers with the freshest, most authentic
                  dishes from around the world.
                </p>
              </div>
            </div>
          </div>

          {/* Additional Content */}
          <div className='mt-16 text-center'>
            <h3 className='text-4xl font-bold text-gray-900'>
              Why Choose Restoura?
            </h3>
            <p className='mt-6 text-lg text-gray-600 max-w-3xl mx-auto'>
              We bring passion, authenticity, and community to every plate.
              Whether you're a chef looking to share your recipes or a customer
              craving homemade delicacies, Restoura is your trusted partner in
              culinary excellence.
            </p>
            <div className='mt-10 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8'>
              <div className='bg-green-50 p-6 rounded-lg shadow-md text-center'>
                <h4 className='text-2xl font-semibold text-green-600'>
                  Authenticity
                </h4>
                <p className='mt-3 text-gray-700'>
                  Enjoy a variety of native dishes, carefully crafted by local
                  chefs, to bring the flavors of home.
                </p>
              </div>
              <div className='bg-yellow-50 p-6 rounded-lg shadow-md text-center'>
                <h4 className='text-2xl font-semibold text-yellow-600'>
                  Diversity
                </h4>
                <p className='mt-3 text-gray-700'>
                  Experience cuisines from around the world, all on one
                  platform.
                </p>
              </div>
              <div className='bg-purple-50 p-6 rounded-lg shadow-md text-center'>
                <h4 className='text-2xl font-semibold text-purple-600'>
                  Community Support
                </h4>
                <p className='mt-3 text-gray-700'>
                  Empowering local chefs and fostering community growth.
                </p>
              </div>
              <div className='bg-teal-50 p-6 rounded-lg shadow-md text-center'>
                <h4 className='text-2xl font-semibold text-teal-600'>
                  Halal-Friendly Meals
                </h4>
                <p className='mt-3 text-gray-700'>
                  We strive to ensure Halal standards, offering you peace of
                  mind with every bite.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Customer Testimonials Section */}
      {/* <section className="bg-gray-50 py-16">
        <div className="text-center">
          <h2 className="text-4xl font-bold text-gray-900">What Our Customers Say</h2>
          <p className="mt-4 text-lg text-gray-600">Hear from our happy customers and chefs</p>
        </div>
        <div className="mx-auto mt-12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-screen-xl px-4">
          {[
            {
              text: "Restoura allows me to indulge in the taste of home, even from across the world. I can't recommend it enough!",
              name: "Jane Doe",
              role: "Customer",
              imgSrc: "https://randomuser.me/api/portraits/women/32.jpg"
            },
            {
              text: "As a chef, Restoura has been instrumental in allowing me to grow my food business. The platform is fantastic!",
              name: "John Smith",
              role: "Chef",
              imgSrc: "https://randomuser.me/api/portraits/men/32.jpg"
            },
          ].map((testimonial, index) => (
            <article key={index} className="bg-white p-8 rounded-lg shadow-lg text-center transition hover:shadow-xl">
              <p className="text-gray-600">{testimonial.text}</p>
              <footer className="mt-6 flex flex-col items-center">
                <img src={testimonial.imgSrc} alt={testimonial.name} className="h-12 w-12 rounded-full object-cover" />
                <div className="text-sm mt-3">
                  <p className="text-gray-900 font-medium">{testimonial.name}</p>
                  <p className="text-gray-500">{testimonial.role}</p>
                </div>
              </footer>
            </article>
          ))}
        </div>
      </section> */}

      {/* Meet Our Chefs Section */}
      {/* <section className="bg-white py-16">
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold text-gray-900">Meet Our Chefs</h2>
          <p className="text-lg text-gray-600">The culinary artists behind every dish</p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-screen-xl mx-auto px-4">
          {[
            {
              imgSrc: "https://images.unsplash.com/photo-1588515724527-074a7a56616c?auto=format&fit=crop&w=1500&q=80",
              name: "Sarah Lee",
              specialization: "Halal / Authentic Cuisine"
            },
            {
              imgSrc: "https://images.unsplash.com/photo-1661956602116-aa6865609028?auto=format&fit=crop&w=764&q=80",
              name: "Mike Chang",
              specialization: "Asian Fusion"
            },
          ].map((chef, index) => (
            <a key={index} href="/null" className="group relative block overflow-hidden rounded-lg shadow-lg">
              <img src={chef.imgSrc} alt={chef.name} className="object-cover w-full h-64 transition duration-500 group-hover:opacity-80" />
              <div className="absolute inset-0 bg-gradient-to-t from-gray-900/60 to-transparent flex items-end p-4">
                <div className="text-white">
                  <p className="text-lg font-medium">{chef.name}</p>
                  <p className="text-sm">{chef.specialization}</p>
                </div>
              </div>
            </a>
          ))}
        </div>
      </section> */}

      {/* Call to Action Section */}
      <section className='py-16 bg-gradient-to-r from-blue-500 to-purple-500 text-white'>
        <div className='max-w-screen-xl mx-auto text-center'>
          <h2 className='text-4xl font-bold'>
            Ready to Savor Authentic Homemade Meals?
          </h2>
          <p className='mt-4 text-lg'>
            Join our community of food lovers and enjoy fresh, authentic meals
            prepared by talented chefs near you.
          </p>
          <a
            href='/becomeavendor'
            className='inline-block mt-6 px-8 py-3 bg-white text-purple-500 rounded-lg font-semibold hover:bg-gray-50 transition'
          >
            Get Started Today
          </a>
        </div>
      </section>
    </div>
  );
};

export default AboutUs;

