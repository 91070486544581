import { Route, Routes } from "react-router-dom";

import Home from "./Components/Home/Home";
import AboutUs from "./Components/Home/AboutUs";
import UnderDevelopment from "./Components/Home/UnderDevelopment";

import AllRestaurantsPage from "./Components/Restaurant/AllRestaurantsPage";
import RestaurantDetails from "./Components/Restaurant/RestaurantDetails";
import CartPage from "./Components/Cart/CartPage";

import VendorOnboarding from "./Components/Vendor/VendorOnboarding";
import VendorProfile from "./Components/Vendor/VendorProfile";
import VendorRestoura from "./Components/Vendor/VendorRestoura";
import RestaurantEdit from "./Components/Vendor/EditRestaurantInfo";
import AddNewRestaurant from "./Components/Vendor/AddNewRestaurant";
import EditMenu from "./Components/Vendor/EditMenu";

import CustomerOnboarding from "./Components/Customer/CustomerOnbaording";
import MyRestoura from "./Components/Customer/MyRestoura";
import Profile from "./Components/Customer/Profile";

import AdminProfile from "./Components/Admin/AdminProfile";

import Login from "./Components/Auth/Login";
import Register from "./Components/Auth/Register";
import VerifyUser from "./Components/Auth/VerifyUser";
import ResetPassword from "./Components/Auth/ResetPassword";
import AdminLogin from "./Components/Auth/LoginAdmin";

import PaymentSuccess from "./Components/Cart/PaymentSuccess";
import PaymentCancel from "./Components/Cart/PaymentCancel";

import BecomeVendor from "./Components/static_pages/BecomeVendor";
import VendorTerms from "./Components/static_pages/VendorTermsPage";
import CustomerTerms from "./Components/static_pages/CustomerTermsPage";
import PrivacyPolicy from "./Components/static_pages/PrivacyPolicy";
import CookiePolicy from "./Components/static_pages/CookiePolicy";

export default function RoutesHandler() {
  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/about-us' element={<AboutUs />} />
      <Route path='/restaurants' element={<AllRestaurantsPage />} />
      <Route path='/restaurant/:name/:id' element={<RestaurantDetails />} />
      <Route path='/development-in-progress' element={<UnderDevelopment />} />

      <Route path='/customer-onboarding/:id' element={<CustomerOnboarding />} />
      <Route path='/customer-profile/*' element={<Profile />} />
      <Route path='/my-restoura/*' element={<MyRestoura />} />
      <Route path='/cart/:id' element={<CartPage />} />

      <Route path='/vendor-onboarding/:id' element={<VendorOnboarding />} />
      <Route path='/vendor-profile/*' element={<VendorProfile />} />
      <Route path='/vendor-restoura/*' element={<VendorRestoura />} />
      <Route
        path='/vendor-restoura/create-new-restaurant/:id'
        element={<AddNewRestaurant />}
      />
      <Route
        path='/restaurant_edit_info/:restaurantName/:restaurantId'
        element={<RestaurantEdit />}
      />
      <Route path='/edit-menu/:cuisineName/:id' element={<EditMenu />} />

      <Route path='/admin-panel/login' element={<AdminLogin />} />
      <Route path='/admin-profile/*' element={<AdminProfile />} />

      <Route path='/login' element={<Login />} />
      <Route path='/register' element={<Register />} />
      <Route path='/user-verification' element={<VerifyUser />} />
      <Route path='/forgot-password' element={<ResetPassword />} />

      <Route path='/payment-success' element={<PaymentSuccess />} />
      <Route path='/payment-cancel' element={<PaymentCancel />} />

      <Route path='/becomeavendor' element={<BecomeVendor />} />
      <Route path='/vendor-terms' element={<VendorTerms />} />
      <Route path='/customer-terms' element={<CustomerTerms />} />
      <Route path='/privacy-policy' element={<PrivacyPolicy />} />
      <Route path='/cookie-policy' element={<CookiePolicy />} />
    </Routes>
  );
}

