import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import API_BASE_URL from "../../apiConfig";
import useUtilService from "../../services/useUtilService";

const EditMenu = () => {
  const { id } = useParams();
  const [menuItem, setMenuItem] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const bearerToken = localStorage.getItem("token");
  const { showSuccessMessage, showErrorMessage } = useUtilService();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchMenuItemDetails(id);
  }, [id]);

  const fetchMenuItemDetails = async (menuId) => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/cuisine/getCuisineById/${menuId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${bearerToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Error fetching menu details: ${response.status}`);
      }

      const data = await response.json();

      // Parse the estimatedPreparationTime field if it exists
      let days = 0,
        hours = 0,
        minutes = 0;

      if (data.estimatedPreparationTime) {
        const timeParts = data.estimatedPreparationTime.split(" ");
        for (let i = 0; i < timeParts.length; i += 2) {
          const value = parseInt(timeParts[i]);
          const unit = timeParts[i + 1];
          if (unit.includes("day")) days = value;
          if (unit.includes("hr")) hours = value;
          if (unit.includes("min")) minutes = value;
        }
      }

      // Update state with parsed values
      setMenuItem({
        ...data,
        days,
        hours,
        minutes,
      });
    } catch (error) {
      console.error("Error fetching menu details:", error);
      showErrorMessage("Error fetching menu details");
    }
  };

  const handleInputChange = (field, value) => {
    setMenuItem((prev) => {
      const updatedItem = { ...prev, [field]: value };

      // Handle changes to days, hours, and minutes
      if (["days", "hours", "minutes"].includes(field)) {
        const {
          days = 0,
          hours = 0,
          minutes = 0,
        } = {
          ...prev,
          ...updatedItem,
        };

        const parts = [];
        if (days > 0) parts.push(`${days} ${days === 1 ? "day" : "days"}`);
        if (hours > 0) parts.push(`${hours} ${hours === 1 ? "hr" : "hrs"}`);
        if (minutes > 0)
          parts.push(`${minutes} ${minutes === 1 ? "min" : "mins"}`);

        updatedItem.estimatedPreparationTime = parts.join(" ");
      }

      return updatedItem;
    });
  };

  const handleImageUploadChange = (e) => {
    setImageFile(e.target.files[0]);
  };

  const handleUpdateMenu = async () => {
    setIsSubmitting(true);

    try {
      // Upload the image first (if there is a new image file)
      if (imageFile) {
        const formData = new FormData();
        formData.append("image", imageFile); // Use the correct field name as per your API

        const imageUploadResponse = await fetch(
          `${API_BASE_URL}/cuisine/uploadImage/${id}`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${bearerToken}`,
            },
            body: formData,
          }
        );

        if (!imageUploadResponse.ok) {
          const errorText = await imageUploadResponse.text();
          throw new Error(`Failed to upload image: ${errorText}`);
        }
      }

      const payload = { ...menuItem };
      const response = await fetch(
        `${API_BASE_URL}/cuisine/updateCuisine/${id}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${bearerToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Error updating menu: ${errorText}`);
      }

      // Display success message and perform any necessary actions
      showSuccessMessage("Menu updated successfully!");
      setTimeout(() => {
        const restaurantId = localStorage.getItem("restaurant_id");
        if (restaurantId) {
          navigate(`/vendor-restoura/menu-management/${restaurantId}`); // Navigate using the restaurant_id
          localStorage.removeItem("restaurant_id"); // Remove the restaurant_id from localStorage
        } else {
          console.error("No restaurant_id found");
        }
      }, 2000);
    } catch (error) {
      console.error("Error updating menu:", error);
      showErrorMessage("Failed to update menu.");
    } finally {
      setIsSubmitting(false);
      setImageFile(null);
    }
  };

  const handleConfirm = () => {
    setShowConfirmation(false);
    handleUpdateMenu();
  };

  const handleCancel = () => {
    setShowConfirmation(false);
  };

  return (
    <div className='overflow-x-auto p-8 min-h-screen mt-20 max-w-5xl mx-auto px-12'>
      {/* Header Section */}
      <div className='bg-gradient-to-r bg-gray-600 py-8 shadow-lg rounded-t-lg text-center'>
        <h2 className='text-4xl font-bold text-white tracking-wide'>
          Update Your Menu
        </h2>
      </div>

      {/* Menu Item Input Form */}

      <div className='bg-white p-6 sm:p-8 rounded-lg shadow-lg'>
        {/* Basic Information Section */}
        <div className='grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6 mb-6'>
          {/* Cuisine Name */}
          <div>
            <label
              htmlFor='cuisineName'
              className='block text-sm font-medium text-gray-700 mb-1'
            >
              Cuisine Name*
            </label>
            <input
              id='cuisineName'
              type='text'
              placeholder='Cuisine Name*'
              value={menuItem.cuisineName}
              onChange={(e) => handleInputChange("cuisineName", e.target.value)}
              className='w-full p-3 border rounded-lg focus:ring focus:ring-blue-300 transition duration-200'
              required
            />
          </div>

          {/* Category */}
          <div>
            <label
              htmlFor='category'
              className='block text-sm font-medium text-gray-700 mb-1'
            >
              Category*
            </label>
            <input
              id='category'
              type='text'
              placeholder='Category*'
              value={menuItem.category}
              onChange={(e) => handleInputChange("category", e.target.value)}
              className='w-full p-3 border rounded-lg focus:ring focus:ring-blue-300 transition duration-200'
              required
            />
          </div>

          {/* Price */}
          <div>
            <label
              htmlFor='price'
              className='block text-sm font-medium text-gray-700 mb-1'
            >
              Price in CAD*
            </label>
            <input
              type='number'
              placeholder='Price in CAD*'
              value={menuItem.price}
              onChange={(e) =>
                handleInputChange("price", parseInt(e.target.value) || "")
              }
              onInput={(e) => {
                // Remove non-numeric characters
                e.target.value = e.target.value.replace(/[^0-9]/g, "");
              }}
              className='w-full p-3 border rounded-lg focus:ring focus:ring-blue-300 transition duration-200'
              required
            />
          </div>

          {/* Availability */}
          <div>
            <label
              htmlFor='availability'
              className='block text-sm font-medium text-gray-700 mb-1'
            >
              Availability*
            </label>
            <select
              id='availability'
              value={menuItem.availability}
              onChange={(e) =>
                handleInputChange("availability", e.target.value)
              }
              className='w-full p-3 border rounded-lg focus:ring focus:ring-blue-300 transition duration-200'
              required
            >
              <option value='' disabled>
                Select Availability*
              </option>
              <option value='in stock'>In Stock</option>
              <option value='out of stock'>Out of Stock</option>
            </select>
          </div>

          {/* Estimated Preparation Time */}
          <div className='col-span-1 sm:col-span-2'>
            <label className='block font-semibold text-gray-700 mb-2'>
              Estimated Preparation Time
            </label>
            <div className='grid grid-cols-1 sm:grid-cols-3 gap-4'>
              <select
                value={menuItem.days || 0}
                onChange={(e) =>
                  handleInputChange("days", parseInt(e.target.value))
                }
                className='w-full p-3 border rounded-lg focus:ring focus:ring-blue-300 transition duration-200'
              >
                {Array.from({ length: 8 }, (_, i) => (
                  <option key={i} value={i}>
                    {i} {i === 1 ? "day" : "days"}
                  </option>
                ))}
              </select>

              <select
                value={menuItem.hours || 0}
                onChange={(e) =>
                  handleInputChange("hours", parseInt(e.target.value))
                }
                className='w-full p-3 border rounded-lg focus:ring focus:ring-blue-300 transition duration-200'
              >
                {Array.from({ length: 25 }, (_, i) => (
                  <option key={i} value={i}>
                    {i} {i === 1 ? "hour" : "hours"}
                  </option>
                ))}
              </select>

              <select
                value={menuItem.minutes || 0}
                onChange={(e) =>
                  handleInputChange("minutes", parseInt(e.target.value))
                }
                className='w-full p-3 border rounded-lg focus:ring focus:ring-blue-300 transition duration-200'
              >
                {[0, 15, 30, 45].map((minute) => (
                  <option key={minute} value={minute}>
                    {minute} {minute === 1 ? "minute" : "minutes"}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/* Description */}
          <div className='col-span-1 sm:col-span-2'>
            <label
              htmlFor='description'
              className='block text-sm font-medium text-gray-700 mb-1'
            >
              Description
            </label>
            <textarea
              id='description'
              placeholder='Description'
              value={menuItem.description}
              onChange={(e) => handleInputChange("description", e.target.value)}
              className='w-full p-3 border rounded-lg focus:ring focus:ring-blue-300 transition duration-200 h-24 resize-none'
            />
          </div>
        </div>

        {/* Dietary Options Section */}
        <div className='mb-6'>
          <h4 className='font-semibold text-gray-700 mb-4'>Dietary Options</h4>
          <div className='grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4'>
            {[
              "isHalal",
              "isVegetarian",
              "isKosher",
              "isGlutenFree",
              "isMSGFree",
              "isNutFree",
              "isOrganic",
              "isNonGMO",
              "isSpicy",
            ].map((option) => (
              <label key={option} className='inline-flex items-center'>
                <input
                  type='checkbox'
                  checked={menuItem[option]}
                  onChange={(e) => handleInputChange(option, e.target.checked)}
                  className='h-4 w-4 text-blue-600'
                />
                <span className='ml-2 text-gray-700'>
                  {option.replace("is", "").replace(/([A-Z])/g, " $1")}
                </span>
              </label>
            ))}
          </div>
        </div>

        {/* Image Upload Section */}
        <div className='mb-6'>
          <label
            htmlFor='image-upload'
            className='block font-semibold text-gray-700 mb-2'
          >
            Update New Image
          </label>
          <input
            type='file'
            accept='image/*'
            onChange={handleImageUploadChange}
            className='w-full p-2 border rounded-lg focus:ring focus:ring-blue-300 transition duration-200'
            id='image-upload'
          />
        </div>

        {/* Submit Button */}
        <button
          onClick={() => setShowConfirmation(true)}
          className={`w-full py-3 bg-gradient-to-r from-teal-500 to-teal-700 text-white rounded-lg shadow-md transition duration-200 ${
            isSubmitting
              ? "opacity-50 cursor-not-allowed"
              : "hover:shadow-lg focus:ring focus:ring-teal-300"
          }`}
          disabled={isSubmitting}
        >
          {isSubmitting ? "Updating Menu..." : "Update Menu"}
        </button>
      </div>

      {showConfirmation && (
        <div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50'>
          <div className='bg-white p-6 rounded shadow-lg'>
            <h2 className='text-lg font-bold mb-4'>Confirm Update</h2>
            <p className='mb-6'>
              Are you sure you want to update this menu item?
            </p>
            <div className='flex justify-end space-x-4'>
              <button
                className='bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400'
                onClick={handleCancel}
              >
                Cancel
              </button>
              <button
                className='bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600'
                onClick={handleConfirm}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditMenu;

