import React from "react";

const CustomerTermsV1 = () => {
  const lastModifiedDate = "12/03/2024";

  const terms = [
    {
      title: "Acceptance of Terms",
      content:
        "By using the Restoura platform, you agree to comply with and be legally bound by these Terms & Conditions and any other policies posted on the platform. If you do not agree to these terms, you should not use the platform.",
    },
    {
      title: "Ordering Process",
      content:
        "When you place an order through the Restoura platform, you are making an offer to purchase food from one of the vendors. Restoura acts as a facilitator between you and the vendor, and your order will only be confirmed once the vendor accepts it. Restoura cannot guarantee availability, pricing, or delivery times.",
    },
    {
      title: "Delivery and Pickup",
      content:
        "Delivery: If you select delivery for your order, Restoura will connect you with a delivery service (either through our network or a third-party provider) to bring the food to your address. Delivery times may vary based on location, vendor preparation time, and availability.\n\nPickup: If you choose the pickup option, you agree to pick up your order at the specified vendor location within the time window provided.",
    },
    {
      title: "Payment",
      content:
        "All payments are processed through the Restoura platform. You agree to pay the total amount of your order, including applicable taxes, delivery fees, tips, and any additional charges, using the payment methods available on the platform.\n\nPayment for orders is required in full at the time of order placement. Restoura reserves the right to charge your payment method for any applicable charges, and you authorize Restoura to process your payment accordingly.",
    },
    {
      title: "Cancellations and Refunds",
      content:
        "Cancellations: Orders can be canceled only within a limited time frame, typically before the vendor has started preparing the food. Cancellations after this period may not be eligible for a refund.\n\nRefunds: Refunds will only be issued under certain conditions, such as if the food is not delivered as ordered or if the food is spoiled or inedible. Refunds are processed at Restoura's discretion based on vendor confirmation and evaluation of the issue.",
    },
    {
      title: "Food Safety and Quality",
      content:
        "Restoura does not directly prepare or serve food; the vendors are solely responsible for the quality, safety, and accuracy of the food they prepare. You agree that Restoura is not liable for any foodborne illness, allergy-related incidents, or other health issues related to the consumption of food ordered through the platform. You are responsible for reviewing the menu and ingredient information provided by vendors to ensure your food order meets your dietary needs.",
    },
    {
      title: "Vendor Ratings and Reviews",
      content:
        "You are encouraged to leave feedback, ratings, and reviews based on your experience with the vendors and the service provided. You agree that your reviews will be accurate, fair, and respectful. Restoura reserves the right to remove any inappropriate or fraudulent reviews.",
    },
    {
      title: "Restrictions",
      content:
        "You agree not to:\n\n- Use the Restoura platform for any unlawful or unauthorized purpose.\n- Disrupt or interfere with the operation of the platform, including attempts to hack, misuse, or gain unauthorized access to the platform or user accounts.\n- Engage in any conduct that is harmful or abusive to the vendors, other customers, or Restoura's staff.",
    },
    {
      title: "Personal Data and Privacy",
      content:
        "By using the Restoura platform, you consent to the collection and processing of your personal information, including your name, contact information, delivery address, payment information, and order history, in accordance with our Privacy Policy. Restoura will only use your personal data for the purposes of processing orders and improving the platform's service.",
    },
    {
      title: "Dispute Resolution",
      content:
        "In the event of a dispute related to an order, you agree to first attempt to resolve the issue directly with the vendor through the platform's messaging system. If the issue cannot be resolved directly, you may contact Restoura's customer support for assistance. Any unresolved disputes may be subject to mediation or legal action as outlined in the vendor agreements.",
    },
    {
      title: "Limitation of Liability",
      content:
        "Restoura is not responsible for any damages, losses, or injuries resulting from your use of the platform, including any issues related to food quality, delivery services, or interactions with vendors. Restoura’s liability is limited to the maximum extent permitted by law.",
    },
    {
      title: "Termination",
      content:
        "Restoura reserves the right to suspend or terminate your account or access to the platform at any time if you violate these Terms & Conditions or engage in any activity that is harmful to the platform, vendors, or other customers.",
    },
    {
      title: "Modification of Terms",
      content:
        "Restoura reserves the right to update or modify these Terms & Conditions at any time. You will be notified of any changes via email or through notifications on the platform. Continued use of the platform after any modifications constitutes acceptance of the updated terms.",
    },
    {
      title: "Governing Law",
      content:
        "These Terms & Conditions shall be governed by and construed in accordance with the laws of the jurisdiction in which Restoura operates. Any disputes shall be subject to the exclusive jurisdiction of the courts in that jurisdiction.",
    },
  ];

  return (
    <div className='min-h-screen bg-gray-50 font-sans'>
      {/* Header */}
      <header className='bg-white shadow-md'>
        <div className='max-w-4xl mx-auto px-4 sm:px-6 py-2 text-center'>
          <h1 className='text-3xl md:text-4xl font-bold text-gray-800'>
            Restoura Customer Terms & Conditions
          </h1>
          <p className='text-gray-600 mt-2'>
            These Terms & Conditions govern your use of the Restoura platform
            and your interaction with our vendors to order food. By placing an
            order with Restoura, you agree to be bound by these terms. Please
            read them carefully.
          </p>
          <p className='text-sm text-gray-500 mt-2'>
            Last Modified: {lastModifiedDate}
          </p>
        </div>
      </header>

      {/* Terms Content */}
      <main className='max-w-4xl mx-auto px-4 sm:px-6 py-8'>
        {terms.map((term, index) => (
          <section key={index} className='mb-6'>
            <h2 className='text-xl font-bold text-gray-800 mb-2'>
              {term.title}
            </h2>
            <p className='text-gray-600 whitespace-pre-line'>{term.content}</p>
          </section>
        ))}
      </main>

      {/* Footer */}
      <footer className='bg-white py-4 mt-8 border-t'>
        <div className='max-w-4xl mx-auto px-4 text-center'>
          <p className='text-sm text-gray-500'>
            © {new Date().getFullYear()} Restoura. All rights reserved.
          </p>
        </div>
      </footer>
    </div>
  );
};

export default CustomerTermsV1;

