import React, { useState, useEffect } from "react";
import CartItem from "./CartItem";
import API_BASE_URL from "../../apiConfig";
import useUtilService from "../../services/useUtilService";

const CartPage = () => {
  const [cartItems, setCartItems] = useState([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { showSuccessMessage, showErrorMessage } = useUtilService();

  const userId = JSON.parse(localStorage.getItem("userId"));

  useEffect(() => {
    const savedCartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
    setCartItems(savedCartItems);
  }, []);

  const handleAddQuantity = (cuisineId) => {
    const updatedItems = cartItems.map((item) =>
      item.cuisineId === cuisineId
        ? { ...item, quantity: item.quantity + 1 }
        : item
    );
    setCartItems(updatedItems);
    updateLocalStorage(updatedItems);
  };

  const handleRemoveQuantity = (cuisineId) => {
    const updatedItems = cartItems
      .map((item) =>
        item.cuisineId === cuisineId
          ? { ...item, quantity: item.quantity - 1 }
          : item
      )
      .filter((item) => item.quantity > 0);

    setCartItems(updatedItems);
    updateLocalStorage(updatedItems);

    if (updatedItems.length !== cartItems.length) {
      window.location.reload();
    }
  };

  const updateLocalStorage = (updatedItems) => {
    if (updatedItems.length === 0) {
      localStorage.removeItem("cartItems");
    } else {
      localStorage.setItem("cartItems", JSON.stringify(updatedItems));
    }
  };

  const totalPrice = cartItems.reduce(
    (total, item) => total + item.cuisinePrice * item.quantity,
    0
  );

  const handleCheckout = () => {
    setShowConfirmationModal(true);
  };

  // calculate pickup time
  const calculatePickupTime = (cartItems) => {
    const parseTime = (time) => {
      let totalMinutes = 0;

      // Regular expressions to match "days", "hrs", and "mins"
      const dayMatch = time.match(/(\d+)\s*day[s]?/i); // Matches "1 day" or "2 days"
      const hrMatch = time.match(/(\d+)\s*hr[s]?/i); // Matches "1 hr" or "2 hrs"
      const minMatch = time.match(/(\d+)\s*min[s]?/i); // Matches "1 min" or "45 mins"

      // Convert each time unit to minutes and add to total
      if (dayMatch) totalMinutes += parseInt(dayMatch[1]) * 24 * 60; // Convert days to minutes
      if (hrMatch) totalMinutes += parseInt(hrMatch[1]) * 60; // Convert hours to minutes
      if (minMatch) totalMinutes += parseInt(minMatch[1]); // Add minutes directly

      return totalMinutes;
    };

    // Calculate preparation times based on quantity
    const preparationTimes = cartItems.map((item) => ({
      ...item,
      totalReadyTime: parseTime(item.readyTime) * item.quantity,
    }));

    // Find the maximum preparation time
    const maxReadyTime = Math.max(
      ...preparationTimes.map((item) => item.totalReadyTime)
    );

    // Calculate pickup time by adding maxReadyTime to current time
    const now = new Date();
    const pickupTime = new Date(now.getTime() + maxReadyTime * 60000); // Convert minutes to milliseconds

    return pickupTime.toISOString(); // Return as ISO string for consistency
  };

  // make the order
  const confirmCheckout = async () => {
    const token = localStorage.getItem("token");
    const email = localStorage.getItem("email");
    setIsLoading(true);

    try {
      // Get the current date and time
      const currentDate = new Date();

      // Format the date as YYYY-MM-DD
      const orderDate = currentDate.toISOString().split("T")[0]; // e.g., "2024-11-24"

      // Format the time as HH:MM AM/PM
      const timeOptions = { hour: "2-digit", minute: "2-digit", hour12: true };
      const timeOfOrder = currentDate.toLocaleTimeString("en-US", timeOptions); // e.g., "5:10 PM"
      // Prepare the order payload according to backend expectations
      const orderPayload = {
        customerId: parseInt(userId),
        restaurantId: cartItems[0]?.restaurantId,
        restaurantName: cartItems[0]?.restaurantName,
        vendorEmail: cartItems[0]?.restaurantEmail, // Added restaurant email
        customerEmail: email, // Added customer email
        orderDate, // Include the formatted date
        timeOfOrder, // Include the formatted time
        pickupTime: calculatePickupTime(cartItems), // Dynamically calculate pickup time
        paymentStatus: "Pending",
        orderStatus: "Pending",
        totalAmount: parseFloat(totalPrice),
        foodItemList: cartItems.map((item) => ({
          cuisineId: parseInt(item.cuisineId),
          cuisineName: item.cuisineName,
          cuisinePrice: parseFloat(item.cuisinePrice),
          quantity: Number(item.quantity),
        })),
        orderRating: null,
        orderReview: null,
      };

      // Log the payload
      // console.log("Order Payload:", JSON.stringify(orderPayload, null, 2));

      // Make the API call
      const response = await fetch(`${API_BASE_URL}/order/createOrder`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(orderPayload),
      });

      // Debugging response
      const responseText = await response.clone().text();
      console.log("Response Text:", responseText);

      if (!response.ok) {
        throw new Error("Failed to create order. Please try again.");
      }

      // Parse response
      const { orderId, payment_url } = JSON.parse(responseText);

      // Clear cart and redirect to payment URL
      // localStorage.removeItem("cartItems");
      // setCartItems([]);

      setShowConfirmationModal(false);

      // Redirect to the payment page
      window.location.href = payment_url;
    } catch (error) {
      console.error("Error creating order:", error);

      showErrorMessage("Failed to create the order. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className='p-6 bg-gradient-to-br from-gray-100 to-gray-300 min-h-screen mt-20'>
      {/* Banner Section */}
      <div className='max-w-6xl rounded-lg mx-auto bg-gradient-to-r from-blue-500 via-teal-500 to-cyan-400 text-white py-4 text-center shadow-lg mb-2'>
        <h1 className='text-4xl font-extrabold uppercase tracking-wide mb-4'>
          Order Now
        </h1>
        <p className='text-lg font-medium mb-6'>
          Delicious meals are just a few clicks away. Don’t wait, place your
          order now!
        </p>
      </div>
      {/* Main Container */}
      <div className='max-w-6xl mx-auto bg-white shadow-xl rounded-lg overflow-hidden'>
        <div className='p-8'>
          {cartItems.length === 0 ? (
            <div className='text-center py-16'>
              <h2 className='text-3xl font-bold text-gray-800 mb-4'>
                Your Cart is Empty!
              </h2>
              <p className='text-gray-600 mb-6'>
                Would you like to order something?
              </p>
              <a
                href='/restaurants'
                className='inline-block px-8 py-4 text-lg font-semibold bg-blue-500 text-white rounded-full shadow-md hover:bg-teal-400 transition duration-300'
              >
                Browse Restaurants
              </a>
            </div>
          ) : (
            <div className='flex flex-col md:flex-row gap-12'>
              <div className='w-full md:w-2/3'>
                <h2 className='text-2xl font-bold mb-8 text-gray-800 border-b pb-4'>
                  Your Orders
                </h2>
                <div className='space-y-6'>
                  {cartItems.map((item) => (
                    <CartItem
                      key={item.cuisineId}
                      item={item}
                      onAdd={() => handleAddQuantity(item.cuisineId)}
                      onRemove={() => handleRemoveQuantity(item.cuisineId)}
                    />
                  ))}
                </div>
              </div>
              <div className='w-full md:w-1/3 bg-gray-50 p-8 rounded-xl shadow-lg'>
                <h2 className='text-2xl font-semibold mb-6 text-center text-gray-800 border-b pb-4'>
                  Billing Details
                </h2>
                <div className='space-y-4 text-gray-700'>
                  {/* Subtotal Details */}
                  <div>
                    <h3 className='text-lg font-semibold mb-2'>
                      Order Summary:
                    </h3>
                    <ul className='space-y-2'>
                      {cartItems.map((item) => (
                        <li
                          key={item.cuisineId}
                          className='flex justify-between border-b pb-2'
                        >
                          <span>
                            {item.cuisineName} (x{item.quantity})
                          </span>
                          <span>
                            ${(item.cuisinePrice * item.quantity).toFixed(2)}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                  {/* Subtotal */}
                  <div className='flex justify-between text-lg mt-4'>
                    <span>Subtotal:</span>
                    <span className='font-medium'>
                      ${totalPrice.toFixed(2)}
                    </span>
                  </div>
                  {/* Platform Fee */}
                  <div className='flex justify-between text-lg'>
                    <span>Platform Fee (5%):</span>
                    <span className='font-medium'>
                      ${(totalPrice * 0.05).toFixed(2)}
                    </span>
                  </div>
                  {/* HST/VAT */}
                  <div className='flex justify-between text-lg'>
                    <span>HST (13%):</span>
                    <span className='font-medium'>
                      ${(totalPrice * 0.13).toFixed(2)}
                    </span>
                  </div>
                  {/* Total */}
                  <div className='flex justify-between text-lg font-semibold border-t pt-4'>
                    <span>Total:</span>
                    <span>
                      $
                      {(
                        totalPrice +
                        totalPrice * 0.005 +
                        totalPrice * 0.13
                      ).toFixed(2)}
                    </span>
                  </div>
                  {/* Checkout Button */}
                  <button
                    className={`w-full mt-8 py-3 rounded-full text-white font-medium shadow-lg ${
                      cartItems.length === 0
                        ? "bg-gray-300 cursor-not-allowed"
                        : "bg-gradient-to-r from-blue-500 via-teal-500 to-cyan-400 hover:from-blue-600 hover:via-teal-600 hover:to-cyan-500 transition duration-300"
                    }`}
                    onClick={handleCheckout}
                    disabled={cartItems.length === 0 || isLoading}
                  >
                    {isLoading ? "Processing..." : "Checkout"}
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {showConfirmationModal && (
        <div className='fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center'>
          <div className='bg-white p-10 rounded-lg shadow-2xl text-center'>
            <h2 className='text-2xl font-bold mb-4 text-gray-700'>
              Confirm Checkout
            </h2>
            <div className='flex justify-center space-x-4'>
              <button
                className='px-6 py-2 rounded-full bg-gray-300 text-gray-700 hover:bg-gray-400 transition'
                onClick={() => setShowConfirmationModal(false)}
              >
                Cancel
              </button>
              <button
                className={`px-6 py-2 rounded-full text-white bg-gradient-to-r 
                from-blue-500  to-purple-400 transition duration-300 ${
                  isLoading
                    ? "opacity-50 cursor-not-allowed"
                    : "hover:from-blue-600  hover:to-purple-500"
                }`}
                onClick={confirmCheckout}
                disabled={isLoading}
              >
                {isLoading ? (
                  <span className='flex items-center'>
                    <svg
                      className='animate-spin h-5 w-5 mr-2 text-white'
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                    >
                      <circle
                        className='opacity-25'
                        cx='12'
                        cy='12'
                        r='10'
                        stroke='currentColor'
                        strokeWidth='4'
                      ></circle>
                      <path
                        className='opacity-75'
                        fill='currentColor'
                        d='M4 12a8 8 0 018-8v8H4z'
                      ></path>
                    </svg>
                    Processing...
                  </span>
                ) : (
                  "Confirm and Pay"
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CartPage;

