import React from "react";
import CustomerTermsV1 from "./CustomerTermsV1";

function CustomerTermsPage() {
  return (
    <div className='mt-24'>
      <CustomerTermsV1 />
    </div>
  );
}

export default CustomerTermsPage;

