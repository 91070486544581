import './App.css';
import { BrowserRouter} from 'react-router-dom';
import Navbar from './Components/Navbar/Navbar';
import RoutesHandler from './Routes';
import ToastAlert from './common-components/toast-message/ToastAlert';
import { useAppReducer } from './hooks/useAppReducer';
import { ApplicationContext } from './context/ApplicationContext';




function App() {
  const { state, dispatch } = useAppReducer();
  return (
    <ApplicationContext.Provider value={{ state, dispatch }}>
      <BrowserRouter>
          <Navbar />
          <RoutesHandler />
          <ToastAlert />
          
      </BrowserRouter>
    </ApplicationContext.Provider>
    
  );
}

export default App;

