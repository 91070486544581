import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import API_BASE_URL from "../../apiConfig";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const [error, setError] = useState("");
  const [role, setRole] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    if (email === "" || password === "") {
      setError("Please fill in all fields.");
      return;
    }

    setIsLoading(true); // Start loading

    const requestBody = {
      email: email,
      password: password,
      role: role,
    };

    try {
      const response = await fetch(`${API_BASE_URL}/auth/signin`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        const errorMessage = await response.text();
        throw new Error(errorMessage || "Login failed. Please try again.");
      }

      const responseData = await response.json();
      const decodedToken = jwtDecode(responseData.refreshToken);
      const id = decodedToken.userId;
      const userEmail = decodedToken.sub;
      const userRole = role;

      localStorage.setItem("isLoggedIn", true);
      localStorage.setItem("token", responseData.refreshToken);
      localStorage.setItem("email", userEmail);
      localStorage.setItem("userId", id);
      localStorage.setItem("role", userRole);

      // Redirect based on selected role
      if (userRole === "customer") {
        navigate(`/customer-profile/my-profile/${id}`);
      } else {
        navigate(`/vendor-profile/my-profile/${id}`);
      }

      // Refresh the page after navigation
      window.location.reload();
    } catch (error) {
      console.error("Login error:", error.message);

      if (
        error.message === "Account not verified. Please verify your account."
      ) {
        setError(
          "Your account is not verified. Please check your email for the verification link."
        );
        navigate("/user-verification");
      } else if (
        error.message ===
        "Unauthorized: Role does not match any of the user's roles"
      ) {
        setError(
          "You are not authorized to log in with the selected role. Please choose a valid role."
        );
      } else if (error.message === "User not found") {
        setError("No account found with the provided email. Please register.");
        navigate("/register");
      } else {
        setError(error.message);
      }
    } finally {
      setIsLoading(false); // End loading
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className='flex items-center justify-center bg-gray-100 py-10 mt-20'>
      <div className='bg-white p-8 rounded-lg shadow-lg w-full max-w-md'>
        <h2 className='text-2xl font-semibold text-center mb-6'>
          Welcome back to Restoura!
        </h2>
        <form onSubmit={handleLogin} className='space-y-6'>
          {error && <p className='text-red-500'>{error}</p>}
          <div className='space-y-1'>
            <label className='block text-sm font-medium text-gray-700'>
              Email
            </label>
            <input
              type='email'
              className='w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          {/* Password Input with FontAwesome Icon */}
          <div className='space-y-1'>
            <label className='block text-sm font-medium text-gray-700'>
              Password
            </label>
            <div className='relative'>
              <input
                id='password'
                type={showPassword ? "text" : "password"}
                className='w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <span
                className='absolute inset-y-0 right-0 flex items-center pr-2 cursor-pointer'
                onClick={togglePasswordVisibility}
              >
                <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
              </span>
            </div>
          </div>

          <div className='space-y-1'>
            <label className='block text-sm font-medium text-gray-700'>
              Role
            </label>
            <select
              value={role}
              onChange={(e) => setRole(e.target.value)}
              className='w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500'
              required
            >
              <option value='' disabled>
                Select Role
              </option>
              <option value='customer'>Customer</option>
              <option value='vendor'>Vendor</option>
            </select>
          </div>

          <button
            type='submit'
            className={`w-full bg-indigo-600 text-white py-2 rounded-lg flex items-center justify-center gap-2 transition duration-300 ${
              isLoading
                ? "cursor-not-allowed opacity-50"
                : "hover:bg-indigo-700"
            }`}
            disabled={isLoading}
          >
            {isLoading && (
              <svg
                className='animate-spin h-5 w-5 text-white'
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
              >
                <circle
                  className='opacity-25'
                  cx='12'
                  cy='12'
                  r='10'
                  stroke='currentColor'
                  strokeWidth='4'
                ></circle>
                <path
                  className='opacity-75'
                  fill='currentColor'
                  d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.963 7.963 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                ></path>
              </svg>
            )}
            {isLoading ? "Logging in..." : "Login"}
          </button>

          <div className='text-center mt-4'>
            <p>
              Don't have an account?{" "}
              <a href='/register' className='text-indigo-600 hover:underline'>
                Register
              </a>
            </p>
            <p className='mt-2'>
              <a
                href='/forgot-password'
                className='text-indigo-600 hover:underline'
              >
                Forgot Password?
              </a>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;

