import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const RestaurantCardSkeleton = () => {
  return (
        <div className="p-4 bg-white rounded-lg shadow-md w-full sm:w-80">
          {/* Image Skeleton */}
          <div className="mb-4">
            <Skeleton height={150} borderRadius={8} />
          </div>
          {/* Info Skeleton */}
          <div className="space-y-2">
            <Skeleton height={20} width="100%" /> {/* Restaurant Name */}
            <Skeleton height={16} width="80%" /> {/* Cuisine Type */}
            <Skeleton height={16} width="70%" /> {/* Operating Hours */}
            <Skeleton height={16} width="90%" /> {/* Location */}
          </div>
        </div>

  );
};

export default RestaurantCardSkeleton;
