import React from "react";
// import CookieConsentBanner from "./CookieConsentBanner"; // Cookie Banner

const CookiePolicy = () => {
  return (
    <div className='max-w-6xl mx-auto px-4 py-8 mt-24'>
      {/* <CookieConsentBanner /> */}
      <h1 className='text-3xl font-bold text-center mb-6'>
        Cookie Policy for Restoura
      </h1>

      <section className='mb-8'>
        <h2 className='text-2xl font-semibold mb-2'>1. What Are Cookies?</h2>
        <p>
          Cookies are small text files that are placed on your device (such as
          your computer or smartphone) when you visit a website. Cookies allow
          the website to remember your actions and preferences (such as login
          details, language, and display settings) over a period of time, so you
          don't have to re-enter them each time you visit or browse different
          pages.
        </p>
      </section>

      {/*  "Types of Cookies We Use" section */}
      <section className='mb-8'>
        <h2 className='text-2xl font-semibold mb-2'>
          2. Types of Cookies We Use
        </h2>
        <p>
          We use different types of cookies on our Platform to improve your
          experience:
        </p>
        <ul className='list-disc pl-6'>
          <li>
            <strong>Essential Cookies</strong>: These cookies are necessary for
            the basic functionality of the Platform. They enable you to navigate
            the site and use its features, such as accessing secure areas of the
            site. Without these cookies, the Platform may not function properly.
            Example: Authentication cookies that help you stay logged in.
          </li>
          <li>
            <strong>Performance Cookies</strong>: These cookies collect
            information about how you use the Platform, such as which pages you
            visit most often and if you encounter any errors. These cookies help
            us improve the performance of the Platform and provide a better user
            experience. Example: Cookies that measure website traffic and usage
            statistics.
          </li>
          <li>
            <strong>Functionality Cookies</strong>: These cookies allow the
            Platform to remember your preferences, such as your language choice
            or region, and provide enhanced features. They may also be used to
            provide services you have requested, such as saving your login
            details. Example: Cookies that save your language selection or
            region.
          </li>
        </ul>
      </section>

      {/*  "How We Use Cookies" section */}
      <section className='mb-8'>
        <h2 className='text-2xl font-semibold mb-2'>3. How We Use Cookies</h2>
        <p>We use cookies for the following purposes:</p>
        <ul className='list-disc pl-6'>
          <li>
            To make the Platform easier to navigate and enhance your user
            experience.
          </li>
          <li>
            To remember your preferences and settings, such as language and
            location.
          </li>
          <li>
            To analyze how our users interact with the Platform to improve its
            performance and functionality.
          </li>
          <li>
            To provide personalized content and advertisements based on your
            preferences and interests.
          </li>
          <li>
            To maintain session security and prevent unauthorized access to your
            account.
          </li>
        </ul>
      </section>

      {/*  "Managing Cookies" section */}
      <section className='mb-8'>
        <h2 className='text-2xl font-semibold mb-2'>4. Managing Cookies</h2>
        <p>
          You have the option to control how cookies are used on your device.
          Most web browsers allow you to manage cookie settings by accepting,
          rejecting, or deleting cookies. However, please note that if you
          disable certain cookies, some features of the Platform may not work as
          intended.
        </p>
        <p>
          <strong>How to Manage Cookies:</strong>
        </p>
        <ul className='list-disc pl-6'>
          <li>
            <strong>Browser Settings</strong>: Most browsers allow you to block
            or delete cookies via their settings menu. Here’s how to manage
            cookies in popular browsers:
          </li>
          <ul className='list-inside'>
            <li>
              <strong>Chrome</strong>: Go to <em>Settings</em> {">"}{" "}
              <em>Privacy and security</em> {">"}{" "}
              <em>Cookies and other site data</em>.
            </li>
            <li>
              <strong>Firefox</strong>: Navigate to <em>Options</em> {">"}
              <em>Privacy & Security</em> {">"} <em>Cookies and Site Data</em>.
            </li>
            <li>
              <strong>Safari</strong>: Go to <em>Preferences</em> {">"}{" "}
              <em>Privacy</em> {">"}
              <em>Manage Website Data</em>.
            </li>
          </ul>
          <li>
            <strong>Cookie Consent Banner</strong>: You will be presented with a
            cookie consent banner when you first visit our Platform, allowing
            you to accept or reject non-essential cookies.
          </li>
        </ul>
      </section>

      {/*  "Changes to This Cookie Policy" section */}
      <section className='mb-8'>
        <h2 className='text-2xl font-semibold mb-2'>
          5. Changes to This Cookie Policy
        </h2>
        <p>
          We may update this Cookie Policy from time to time to reflect changes
          in our practices or for other operational, legal, or regulatory
          reasons. When we update this policy, we will post the new version on
          this page and update the effective date. We encourage you to review
          this policy periodically.
        </p>
      </section>

      {/* "Contact Us" section */}
      <section className='mb-8'>
        <h2 className='text-2xl font-semibold mb-2'>5. Contact Us</h2>
        <p>
          If you have any questions about our use of cookies or this Cookie
          Policy, please contact us at: <strong>Restoura</strong>
        </p>
        <p>
          Email:{" "}
          <a href='mailto:support@restoura.com' className='text-blue-600'>
            support@restoura.com
          </a>
        </p>
      </section>

      <section className='mb-8'>
        <h2 className='text-2xl font-semibold mb-2'>Conclusion</h2>
        <p>
          By using the Restoura Platform, you consent to the use of cookies as
          outlined in this Cookie Policy. If you do not agree with this policy,
          please adjust your cookie preferences through your browser settings or
          refrain from using the Platform.
        </p>
      </section>

      {/* Footer */}
      <footer className='bg-white py-4 mt-8 border-t'>
        <div className='max-w-6xl mx-auto px-4 text-center'>
          <p className='text-sm text-gray-500'>
            Last Updated: December 3, 2024 | © Restoura. All rights reserved.
          </p>
        </div>
      </footer>
    </div>
  );
};

export default CookiePolicy;

